import {
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BaseColor from "../../Config/colors";
import CHeader from "../../Components/Header";
import styles from "./styles";
import _ from "lodash";
import Carousel from "react-material-ui-carousel";
import theme, { FontFamily } from "../../Config/theme";
import CButton from "../../Components/CButton";
import { isMobile, isTablet, isIOS } from "react-device-detect";
import { FaChevronDown } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa6";
import CloseIcon from "@mui/icons-material/Close";
import CModal from "../../Components/CModal";
import CInput from "../../Components/CInput";
import CSelect from "../../CSelect";
import { FiDollarSign } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import CIcon from "../../Components/CIcon";
import Files from "../../Config/Files";
import moment from "moment";

const EventDetails = () => {
  //ref
  const widthRef = useRef(null);

  // media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const xl = useMediaQuery(theme.breakpoints.down("xl"));

  // navigate
  const navigate = useNavigate();
  const { id } = useParams();

  const [guestData, setGuestData] = useState([]);

  // page loader
  const [pageLoader, setPageLoader] = useState(true);

  // read more
  const [readMore, setReadMore] = useState(true);

  // time slot
  const [timeSlotVisible, setTimeSlotVisible] = useState(false);
  const [tabValue, setTabValue] = useState();

  // arrival modal
  const [openArrivalModal, setOpenArrivalModal] = useState(false);
  const [fee, setFee] = useState("");
  const [fees, setFees] = useState("");
  const [initialFees, setInitialFees] = useState();
  const [guest, setGuest] = useState({ id: 0, name: "0" });

  // events details data
  const [eventsDetailsData, setEventsDetailsData] = useState();

  // images list
  const [imageList, setImageList] = useState([]);

  // book mark
  const [bookmarkLoader, setBookMarkLoader] = useState(false);

  // fee calculation
  const feeCalculation = (guestNumber) => {
    const totalAmount = Number(initialFees) * (Number(guestNumber) + 1);
    setFee(Number(initialFees));
    setFees(totalAmount);
  };

  //image carousal
  const [activeImg, setActiveImg] = useState(0);

  // btn loader
  const [btnLoader, setBtnLoader] = useState(false);

  // booking confirmation modal
  const [bookingConfimModal, setBookingConfirmModal] = useState(false);
  const [confirmBtnLoader, setConfirmBtnLoader] = useState(false);

  // pay btn disable
  const [btnDisable, setBtnDisable] = useState(false);

  useEffect(() => {
    const onlyNumberRegex = /^[0-9]*$/;
    if (onlyNumberRegex?.test(id)) {
      getEventDetailsData(id, true, true);
    }
  }, []);

  const getFormattedDate = (date) => {
    const timestamp = date * 1000; // Multiply by 1000 to convert seconds to milliseconds
    const dateObj = new Date(timestamp);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = dateObj.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  const getFormattedDateWithMonth = (date) => {
    const dateObj = new Date(date);
    const day = dateObj.getDate();
    const monthIndex = dateObj.getMonth();

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const formattedDate = `${day} ${monthNames[monthIndex]}`;

    return formattedDate;
  };

  // event details data
  const getEventDetailsData = async (id, pageLoad, images) => {
    if (pageLoad) {
      setPageLoader(true);
    }
    let endPoints = Setting.endpoints.getEventDetails;

    try {
      const resp = await getAPIProgressData(
        endPoints,
        "POST",
        { id: id },
        true
      );
      if (resp?.success) {
        if (
          !_.isEmpty(resp?.data) &&
          !_.isUndefined(resp?.data) &&
          !_.isNull(resp?.data)
        ) {
          setEventsDetailsData(resp?.data);
          setTabValue(
            getFormattedDateWithMonth(
              resp?.data?.event_schedule_detail[0]?.schedule_date
            )
          );
          if (!_.isEmpty(resp?.data?.event_images) && images) {
            setImageList(resp?.data?.event_images);
          }
          if (resp?.data?.guest_allowed) {
            const dataArray = [{ id: 0, name: "0" }];

            for (let i = 0; i < resp?.data?.guest_allowed; i++) {
              dataArray.push({ id: i, name: (i + 1).toString() });
            }
            setGuestData(dataArray);
          }
          setFees(resp?.data?.event_fees);
          setInitialFees(resp?.data?.event_fees);
          if (pageLoad) {
            setPageLoader(false);
          }
        }

        if (pageLoad) {
          setPageLoader(false);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        if (pageLoad) {
          setPageLoader(false);
        }
      }
    } catch (error) {
      toast(error.message, { type: "error" });
      if (pageLoad) {
        setPageLoader(false);
      }
    }
  };

  // add and remove bookmarks
  const addAndRemoveBookmark = async (eventId, bookmark) => {
    setBookMarkLoader(true);
    let endPoints = Setting.endpoints.addAndRemoveBookmark;

    const data = {
      "EventManagement[event_id]": eventId,
      "EventManagement[is_event_bookmark]": bookmark ? 1 : 0,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        getEventDetailsData(id, false, false);
        setTimeout(() => {
          setBookMarkLoader(false);
        }, 1000);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setBookMarkLoader(false);
      }
    } catch (error) {
      toast(error.message, { type: "error" });
      setBookMarkLoader(false);
    }
  };

  // book events
  const bookEvent = async () => {
    setConfirmBtnLoader(true);
    let endPoints = Setting.endpoints.eventPay;

    const data = {
      event_id: eventsDetailsData?.id,
      guest_cnt: Number(guest?.name),
      event_price: fees,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (resp && resp.data && resp.data.url) {
          // window.location.href = resp.data.url;
          window.location.replace(resp.data.url);
          // openNewWindow(resp.data.url);
        }
        setGuest({ id: 0, name: "0" });
        setFees("");
        setConfirmBtnLoader(false);
        setOpenArrivalModal(false);
        setBookingConfirmModal(false);
        getEventDetailsData(id, false, false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setConfirmBtnLoader(false);
      }
    } catch (error) {
      toast(error.message, { type: "error" });
      setConfirmBtnLoader(false);
    }
  };

  // image list
  const PostImageList = () => {
    const data =
      !_.isEmpty(imageList) && _.isArray(imageList)
        ? imageList?.filter((data) => {
            return data;
          })
        : [];
    const postImagesList = data;

    return (
      <>
        {postImagesList?.map((item, index) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flex: postImagesList.length > 2 && 1,
              }}
              onClick={() => setActiveImg(index)}
            >
              <img
                alt="thumbnail"
                src={item}
                className={classes.smallImgs}
                draggable={false}
                style={{
                  border:
                    activeImg === index ? `3px solid ${BaseColor.primary}` : "",
                  flex: 1,
                }}
              />
            </div>
          );
        })}
      </>
    );
  };

  // image slider
  const CarouselContainer = () => {
    const data = imageList;

    const postList = _.isArray(data) ? data : [data];

    return (
      <Grid item xs={12} sm={12} md={12} lg={6} ref={widthRef}>
        <div>
          <Carousel
            navButtonsAlwaysInvisible={true}
            animation="slide"
            autoPlay={false}
            indicators={false}
            index={activeImg}
            sx={{
              borderRadius: "10px",
              minHeight: sm ? "300px" : md && "400px",
              height: !sm && !md && "calc(100vh - 300px)",
              minWidth: "42vw",
            }}
            onChange={(index) => {
              setActiveImg(index);
            }}
            className={classes.car}
          >
            {!_.isEmpty(postList) && _.isArray(postList) ? (
              postList.map((item, index) => {
                const imageUrl =
                  !_.isEmpty(item) && !_.isNull(item) && !_.isUndefined(item)
                    ? item
                    : "";

                return (
                  <div
                    key={item?.id}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: sm ? "30%" : "100%",
                    }}
                  >
                    {item?.type !== "video" && item?.type !== "thumbnail" ? (
                      !_.isEmpty(imageUrl) ? (
                        <>
                          <div
                            style={{
                              position: "absolute",
                              backgroundColor: "#EEFAFF",
                              right: "10px",
                              top: "10px",
                              borderRadius: "20px",
                              padding: "10px",
                              display: "flex",
                              alignItems: "center",
                              cursor: !isMobile && !isTablet && "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              addAndRemoveBookmark(
                                eventsDetailsData?.id,
                                eventsDetailsData?.is_event_bookmark
                                  ? false
                                  : true
                              );
                            }}
                          >
                            {bookmarkLoader ? (
                              <CircularProgress size={17} color="primary" />
                            ) : (
                              <CIcon
                                src={
                                  eventsDetailsData?.is_event_bookmark === 1
                                    ? Files.svgIcons.fillBookmark
                                    : Files.svgIcons.bookmark
                                }
                                size={20}
                                style={{
                                  cursor: !isMobile && !isTablet && "pointer",
                                }}
                              />
                            )}
                          </div>
                          <img
                            alt={"carousle_img"}
                            src={imageUrl}
                            draggable={false}
                            className={`${classes.carImg} ${classes.carImageZoom}`}
                          />
                        </>
                      ) : (
                        <div
                          style={{
                            backgroundColor: BaseColor.disablePrimary,
                            height: "80%",
                            width: "85%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {/* <img
                            src={imageFile?.watermarkSVG}
                            alt={"watermark"}
                            style={{
                              height: "80%",
                              width: "80%",
                              objectFit: "contain",
                            }}
                          /> */}
                        </div>
                      )
                    ) : (
                      <video controls className={classes.carImg}>
                        <source
                          src={item?.url + item?.image_name}
                          type="video/mp4"
                        />
                      </video>
                    )}
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: BaseColor.disablePrimary,
                  padding: "20px",
                  width: lg ? "100%" : "90%",
                  borderRadius: "10px",
                }}
              >
                {/* <img
                  alt={"carousle_img"}
                  src={imageFile.watermarkSVG}
                  className={classes.carImg}
                  style={{ width: "50%", objectFit: "contain" }}
                /> */}
              </div>
            )}
          </Carousel>
        </div>

        <Grid
          item
          style={{
            padding: "10px 0px 5px 0px",
            width: !lg ? "41.5vw" : md ? "91vw" : lg ? "83vw" : "80vw",
            overflowX: "auto",
          }}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            className={classes.scrollBarImages}
            id="scrollImg"
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              overflowX: "auto",
              paddingBottom: "10px",
              gap: "10px",
            }}
          >
            <PostImageList />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const guestCalculator = (count) => {
    if (count >= Number(eventsDetailsData?.total_remaining_player_allowed)) {
      toast(
        `Only ${eventsDetailsData?.total_remaining_player_allowed} spot left`,
        { type: "error" }
      );
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  };

  return (
    <Grid
      container
      style={{
        backgroundColor: BaseColor.white,
        // height: "100vh",
      }}
    >
      <CHeader
        title={"Details"}
        backButtonClick={() => {
          navigate(-1);
        }}
      />
      {pageLoader ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          style={{
            height:
              md || isMobile || isTablet
                ? "calc(100vh - 70px)"
                : "calc(100vh - 90px)",
          }}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
          <Grid item xs={11} sm={11} md={10} lg={10}>
            <Grid container>
              {!lg && (
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  alignItems={"center"}
                  mt={!md && !isMobile && !isTablet && "20px"}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <CIcon
                      src={Files.svgIcons.BackArrowIcon}
                      size={30}
                      style={{
                        marginLeft: "-4px",
                        cursor: !isMobile && !isTablet && "pointer",
                      }}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                  </div>
                  <Grid item xs={12} display={"flex"} alignItems={"center"}>
                    <Typography className={classes.title}>
                      {eventsDetailsData?.event_name}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid
                display={!lg && "flex"}
                sx={{
                  height:
                    (window.matchMedia("(display-mode: standalone)")?.matches ||
                      window?.navigator?.standalone) &&
                    (isMobile || isTablet)
                      ? "calc(100vh - 90px)"
                      : (isMobile || isTablet) && isIOS
                      ? "calc(100vh - 190px)"
                      : "calc(100vh - 150px)",
                  overflow: "auto",
                  paddingBottom: "20px",
                }}
              >
                <Grid item xs={12} sm={12} md={12} lg={6} mt={"10px"}>
                  <Grid container>
                    <Grid item xs={12}>
                      {CarouselContainer()}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  mt={!lg && "10px"}
                  pl={!lg && "30px"}
                  pr={!lg && "10px"}
                  style={{
                    height: !lg && "calc(100vh - 180px)",
                  }}
                >
                  {lg && (
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      alignItems={"center"}
                      mb={"5px"}
                    >
                      <Grid item xs={12} display={"flex"} alignItems={"center"}>
                        <Typography
                          sx={{
                            fontFamily: `${FontFamily.GilbertQualifiDemo} !important`,
                            fontSize: isMobile ? "20px" : "22px !important",
                          }}
                        >
                          {eventsDetailsData?.event_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  <Grid container height={!lg && "100%"}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        height: !lg && "calc(100% - 80px)",
                        overflowY: !lg && "auto",
                        paddingRight: !lg && "10px",
                      }}
                      className={classes.scrollBar}
                    >
                      <Grid container rowGap={2}>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.eventDescription}
                            style={{ whiteSpace: "pre-line" }}
                          >
                            {isMobile
                              ? readMore &&
                                eventsDetailsData?.event_description.length > 90
                                ? eventsDetailsData?.event_description.slice(
                                    0,
                                    90
                                  )
                                : eventsDetailsData?.event_description
                              : readMore &&
                                eventsDetailsData?.event_description.length >
                                  300
                              ? eventsDetailsData?.event_description.slice(
                                  0,
                                  300
                                )
                              : eventsDetailsData?.event_description}
                            <span
                              style={{
                                fontFamily: FontFamily.SemiBold,
                                cursor: !isMobile && !isTablet && "pointer",
                                fontSize: 14,
                                color: BaseColor.primary,
                              }}
                              onClick={() => setReadMore(!readMore)}
                            >
                              {isMobile
                                ? eventsDetailsData?.event_description.length >
                                  90
                                  ? readMore &&
                                    eventsDetailsData?.event_description
                                      .length > 90
                                    ? "...Read more"
                                    : !readMore
                                    ? " Read less"
                                    : ""
                                  : ""
                                : eventsDetailsData?.event_description.length >
                                  300
                                ? readMore &&
                                  eventsDetailsData?.event_description.length >
                                    300
                                  ? "...Read more"
                                  : !readMore
                                  ? " Read less"
                                  : ""
                                : ""}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.location}>
                            Location
                          </Typography>
                          <Typography className={classes.eventDescription}>
                            {eventsDetailsData?.location}
                          </Typography>
                          <hr
                            style={{
                              borderTop: "2px solid white",
                              marginBottom: 5,
                              marginTop: 10,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <div className={classes.timeAndDetails}>
                            <Typography className={classes.details}>
                              Date
                            </Typography>
                            <Typography
                              className={classes.time}
                              onClick={() => {
                                setTimeSlotVisible(!timeSlotVisible);
                              }}
                            >
                              {eventsDetailsData?.start_date ===
                              eventsDetailsData?.end_date
                                ? `${moment(
                                    eventsDetailsData?.start_date
                                  ).format("DD MMM")}`
                                : `${moment(
                                    eventsDetailsData?.start_date
                                  ).format("DD MMM")} - ${moment(
                                    eventsDetailsData?.end_date
                                  ).format("DD MMM")}`}{" "}
                              {""}
                              {timeSlotVisible ? (
                                <FaChevronUp
                                  style={{
                                    marginLeft: "5px",
                                    color: BaseColor.blackColor,
                                  }}
                                />
                              ) : (
                                <FaChevronDown
                                  style={{
                                    marginLeft: "5px",
                                    color: BaseColor.blackColor,
                                  }}
                                />
                              )}
                            </Typography>
                          </div>
                          {timeSlotVisible && (
                            <div
                              style={{
                                marginTop: "10px",
                                marginBottom: "20px",
                                width: md
                                  ? "90vw"
                                  : lg
                                  ? "95vw"
                                  : xl
                                  ? "35vw"
                                  : "37vw",
                                borderLeft: "1px solid gray",
                                borderRight: "1px solid gray",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              {!_.isEmpty(
                                eventsDetailsData?.event_schedule_detail
                              ) ? (
                                <>
                                  <Tabs
                                    variant="scrollable"
                                    scrollButtons="auto"
                                    aria-label="scrollable auto tabs example"
                                    sx={{
                                      backgroundColor:
                                        eventsDetailsData?.event_schedule_detail
                                          .length > 3 && "#042b48",
                                      color: "white",
                                    }}
                                  >
                                    {eventsDetailsData?.event_schedule_detail?.map(
                                      (data, index) => (
                                        <>
                                          {eventsDetailsData
                                            ?.event_schedule_detail.length >
                                          1 ? (
                                            <Tab
                                              sx={{
                                                "&.MuiTab-root": {
                                                  opacity: `${1} !important`,
                                                },
                                                ...(index === 0 &&
                                                  sm &&
                                                  eventsDetailsData
                                                    ?.event_schedule_detail
                                                    .length > 1 && {
                                                    borderLeft:
                                                      "0px !important",
                                                  }),
                                              }}
                                              style={{
                                                border: `1px solid grey`,
                                                flex: 1,
                                                marginRight: "-1px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "10px 0px",
                                                cursor:
                                                  !isMobile &&
                                                  !isTablet &&
                                                  "pointer",
                                                width: "100%",
                                                backgroundColor:
                                                  getFormattedDateWithMonth(
                                                    data?.schedule_date
                                                  ) === tabValue
                                                    ? BaseColor.primary
                                                    : BaseColor.white,
                                              }}
                                              key={eventsDetailsData?.id}
                                              label={
                                                <Typography
                                                  style={{
                                                    color:
                                                      getFormattedDateWithMonth(
                                                        data?.schedule_date
                                                      ) === tabValue
                                                        ? BaseColor.white
                                                        : BaseColor.blackColor,
                                                    fontFamily:
                                                      FontFamily.Medium,
                                                    fontSize: sm && "14px",
                                                  }}
                                                >
                                                  {getFormattedDateWithMonth(
                                                    data?.schedule_date
                                                  )}
                                                </Typography>
                                              }
                                              onClick={() => {
                                                setTabValue(
                                                  getFormattedDateWithMonth(
                                                    data?.schedule_date
                                                  )
                                                );
                                              }}
                                            />
                                          ) : (
                                            <div
                                              style={{
                                                border: `1px solid grey`,
                                                flex: 1,
                                                marginRight: "-1px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                padding: "10px 0px",
                                                cursor:
                                                  !isMobile &&
                                                  !isTablet &&
                                                  "pointer",
                                                width: "100%",
                                                backgroundColor:
                                                  getFormattedDateWithMonth(
                                                    data?.schedule_date
                                                  ) === tabValue
                                                    ? BaseColor.primary
                                                    : BaseColor.white,
                                              }}
                                              key={eventsDetailsData?.id}
                                              onClick={() => {
                                                setTabValue(
                                                  getFormattedDateWithMonth(
                                                    data?.schedule_date
                                                  )
                                                );
                                              }}
                                            >
                                              {" "}
                                              <Typography
                                                style={{
                                                  color:
                                                    getFormattedDateWithMonth(
                                                      data?.schedule_date
                                                    ) === tabValue
                                                      ? BaseColor.white
                                                      : BaseColor.blackColor,
                                                  fontFamily: FontFamily.Medium,
                                                  fontSize: sm && "14px",
                                                }}
                                              >
                                                {getFormattedDateWithMonth(
                                                  data?.schedule_date
                                                )}
                                              </Typography>
                                            </div>
                                          )}
                                        </>
                                      )
                                    )}
                                  </Tabs>
                                  <table
                                    style={{
                                      width: "100%",
                                      borderCollapse: "collapse",
                                    }}
                                  >
                                    {eventsDetailsData?.event_schedule_detail
                                      ?.filter(
                                        (item) =>
                                          getFormattedDateWithMonth(
                                            item.schedule_date
                                          ) === tabValue
                                      )
                                      .map((data) =>
                                        data?.schedule_detail?.length > 0 ? (
                                          data?.schedule_detail?.map((t) => (
                                            <tr key={t?.id}>
                                              <td
                                                style={{
                                                  borderBottom: `1px solid grey`,
                                                  padding: "10px 5px",
                                                  fontFamily: FontFamily.Medium,
                                                  fontSize: sm && "14px",
                                                }}
                                              >
                                                {t.start_time} - {t.end_time}{" "}
                                                {""}
                                                {t.schedule_description}
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr key="no-data">
                                            <td
                                              colSpan="1"
                                              style={{
                                                borderBottom: `1px solid grey`,
                                                padding: "10px 5px",
                                                fontFamily: FontFamily.Medium,
                                                fontSize: sm && "14px",
                                                textAlign: "center",
                                              }}
                                            >
                                              No Data
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </table>
                                </>
                              ) : (
                                <Typography
                                  style={{
                                    borderBottom: `1px solid grey`,
                                    padding: "10px 5px",
                                    fontFamily: FontFamily.Medium,
                                    fontSize: sm && "14px",
                                    textAlign: "center",
                                  }}
                                >
                                  No Data
                                </Typography>
                              )}
                            </div>
                          )}
                          <hr
                            style={{
                              borderTop: "2px solid white",
                              marginBottom: 16,
                              marginTop: 5,
                            }}
                          />
                          <div
                            className={classes.timeAndDetails}
                            style={{ marginBottom: lg && "10px" }}
                          >
                            <Typography className={classes.details}>
                              Event host
                            </Typography>
                            <Typography className={classes.timeDescription}>
                              {eventsDetailsData?.host_name}
                            </Typography>
                          </div>
                          <hr
                            style={{
                              borderTop: "2px solid white",
                              marginBottom: 16,
                              marginTop: 5,
                            }}
                          />
                          <div
                            className={classes.timeAndDetails}
                            style={{
                              marginBottom:
                                eventsDetailsData?.is_event_booked !== 1
                                  ? "50px"
                                  : isMobile && isIOS
                                  ? "50px"
                                  : "20px",
                            }}
                          >
                            <Typography className={classes.details}>
                              {eventsDetailsData?.is_event_booked === 1
                                ? "Guest details"
                                : "Guest allowed"}
                            </Typography>
                            <Typography className={classes.timeDescription}>
                              {eventsDetailsData?.is_event_booked === 1
                                ? eventsDetailsData?.total_guest_count
                                : eventsDetailsData?.guest_allowed}
                            </Typography>
                          </div>
                          {eventsDetailsData?.is_event_booked === 1 && (
                            <div
                              className={classes.timeAndDetails}
                              style={{ marginBottom: "40px" }}
                            >
                              <Typography className={classes.details}>
                                Payment confirmation
                              </Typography>
                              <Typography className={classes.timeDescription}>
                                ${eventsDetailsData?.total_event_fees}
                                {""} Paid
                              </Typography>
                            </div>
                          )}
                          {/* <hr
                            style={{
                              borderTop: "2px solid white",
                              marginBottom: 16,
                              // marginTop: 16,
                            }}
                          /> */}
                        </Grid>
                      </Grid>
                    </Grid>
                    <hr
                      style={{
                        borderTop: "2px solid white",
                        width: "100%",
                      }}
                    />
                    {!lg && (
                      <Grid
                        container
                        style={{
                          position: "sticky",
                          display: "flex",
                          alignItems: "flex-end",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          backgroundColor: BaseColor.white,
                          height: "80px",
                        }}
                      >
                        {eventsDetailsData?.is_event_booked === 1 ? (
                          <div style={{ width: "40vw" }}>
                            <CButton disabled btnStyle={{ width: "100%" }}>
                              {" "}
                              Booked
                            </CButton>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "nowrap",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography className={classes.price}>
                                ${eventsDetailsData?.event_fees}
                              </Typography>
                              <Typography>/</Typography>
                              <Typography
                                style={{
                                  fontFamily: FontFamily.Regular,
                                  color: BaseColor.blackColor,
                                  fontSize: "22px",
                                }}
                              >
                                Golfer
                              </Typography>
                            </div>
                            <CButton
                              disabled={
                                eventsDetailsData?.total_remaining_player_allowed ==
                                0
                              }
                              btnStyle={{
                                width: isMobile ? "40%" : "35%",
                                marginLeft: !lg && "200px",
                              }}
                              onClick={() => {
                                feeCalculation(0);
                                setOpenArrivalModal(true);
                              }}
                            >
                              {eventsDetailsData?.total_remaining_player_allowed ==
                              0
                                ? "Packed"
                                : " Book now"}
                            </CButton>
                          </div>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <CModal
                visible={openArrivalModal}
                onClose={() => {
                  setOpenArrivalModal(false);
                  setGuest({ id: 0, name: "0" });
                  setBtnDisable(false);
                }}
                children={
                  <Grid
                    container
                    padding={isMobile ? "20px" : "40px"}
                    style={{
                      position: "relative",
                      width: sm ? "90vw" : "420px",
                      maxHeight: "80vh",
                      overflowY: "auto",
                    }}
                    className={classes.scrollBar}
                  >
                    <CloseIcon
                      onClick={() => {
                        setOpenArrivalModal(false);
                        setGuest({ id: 0, name: "0" });
                        setBtnDisable(false);
                      }}
                      style={{
                        position: "absolute",
                        top: 10,
                        right: 15,
                        fontSize: 30,
                        cursor: !isMobile && !isTablet && "pointer",
                        color: BaseColor.primary,
                        zIndex: 1,
                      }}
                    />

                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      marginBottom={"10px"}
                    >
                      <Typography
                        style={{
                          fontFamily: FontFamily.GilbertQualifiDemo,
                          fontSize: isMobile ? "20px" : "24px",
                          color: BaseColor.blackColor,
                        }}
                      >
                        Confirm your arrival
                      </Typography>
                    </Grid>
                    <hr
                      style={{
                        borderTop: "2px solid white",
                        width: "100%",
                      }}
                    />
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          mt={3}
                          style={{
                            fontFamily: FontFamily.Medium,
                            color: BaseColor.blackColor,
                          }}
                        >
                          Your fees
                        </Typography>
                      </div>
                      <div style={{ width: "60%" }}>
                        <CInput
                          value={fee}
                          type="number"
                          readOnly
                          style={{ width: "100%" }}
                          onChange={(val) => {
                            setFees(val);
                          }}
                          startIcon={
                            <FiDollarSign
                              style={{
                                color: BaseColor.blackColor,
                                fontSize: "15px",
                              }}
                            />
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      style={{ marginTop: "10px" }}
                    >
                      <div
                        style={{
                          width: "40%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: FontFamily.Medium,
                            color: BaseColor.blackColor,
                          }}
                        >
                          Guest coming
                        </Typography>
                      </div>
                      <div style={{ width: "60%", marginTop: "10px" }}>
                        <CSelect
                          fullObj
                          placeholder={"Guest number"}
                          value={guest}
                          data={guestData}
                          style={{ width: "100%" }}
                          onSelect={(val) => {
                            setGuest(val);
                            feeCalculation(val?.name);
                            guestCalculator(val?.name);
                          }}
                        />
                        <Typography
                          style={{
                            color: BaseColor.grey,
                            fontSize: "14px",
                            marginTop: "5px",
                            marginLeft: "10px",
                          }}
                        >
                          ${initialFees} per guest
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} mt={"40px"}>
                      <CButton
                        disabled={btnDisable}
                        loading={btnLoader}
                        onClick={() => {
                          setBookingConfirmModal(true);
                        }}
                      >
                        Pay ${fees}
                      </CButton>
                    </Grid>
                  </Grid>
                }
              />
            </Grid>
            {(lg || isMobile || isTablet) && (
              <Grid
                container
                style={{
                  position: "fixed",
                  bottom: "0px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  backgroundColor: BaseColor.white,
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  zIndex: 100,
                  left: "0px",
                  borderTop: `1px solid ${BaseColor.greyBorder}`,
                }}
              >
                <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={10}
                  lg={10}
                  display={"flex"}
                  justifyContent={"space-between"}
                >
                  {eventsDetailsData?.is_event_booked === 1 ? (
                    <CButton disabled>Booked</CButton>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography className={classes.price}>
                          ${eventsDetailsData?.event_fees}
                        </Typography>
                        <Typography>/</Typography>
                        <Typography
                          style={{
                            fontFamily: FontFamily.Regular,
                            color: BaseColor.blackColor,
                          }}
                        >
                          Golfer
                        </Typography>
                      </div>
                      <CButton
                        disabled={
                          eventsDetailsData?.total_remaining_player_allowed == 0
                        }
                        btnStyle={{
                          width: isMobile ? "40%" : "30%",
                          marginLeft: !lg && "100px",
                        }}
                        onClick={() => {
                          feeCalculation(0);
                          setOpenArrivalModal(true);
                        }}
                      >
                        {eventsDetailsData?.total_remaining_player_allowed == 0
                          ? "Packed"
                          : " Book now"}
                      </CButton>
                    </>
                  )}
                </Grid>
                <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
              </Grid>
            )}

            <CModal
              visible={bookingConfimModal}
              onClose={() => {
                setBookingConfirmModal(false);
              }}
              children={
                <Grid
                  container
                  padding={isMobile ? "20px" : "40px"}
                  style={{
                    position: "relative",
                    width: md ? "90vw" : "600px",
                    maxHeight: "80vh",
                    overflowY: "auto",
                  }}
                  className={classes.scrollBar}
                >
                  <CloseIcon
                    onClick={() => {
                      setBookingConfirmModal(false);
                    }}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 15,
                      fontSize: 30,
                      cursor: !isMobile && !isTablet && "pointer",
                      color: BaseColor.primary,
                      zIndex: 1,
                    }}
                  />

                  <Grid
                    item
                    xs={12}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.GilbertQualifiDemo,
                        fontSize: isMobile ? "20px" : "24px",
                        color: BaseColor.blackColor,
                      }}
                    >
                      Booking Confirmation
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    mt={isMobile ? "20px" : "30px"}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      style={{
                        color: BaseColor.blackColor,
                        fontFamily: FontFamily.Medium,
                        fontSize: 18,
                        textAlign: "center",
                      }}
                    >
                      Are you sure you want to book this event?
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    mt={"40px"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <CButton
                      btnStyle={{ width: isMobile ? "45%" : "45%" }}
                      variant={"outlined"}
                      onClick={() => {
                        setBookingConfirmModal(false);
                      }}
                    >
                      Cancel
                    </CButton>
                    <CButton
                      btnStyle={{ width: isMobile ? "45%" : "45%" }}
                      loading={confirmBtnLoader}
                      onClick={() => {
                        bookEvent();
                      }}
                    >
                      Confirm
                    </CButton>
                  </Grid>
                </Grid>
              }
            />

            <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default EventDetails;
