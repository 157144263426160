import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CSelect from "../../CSelect";
import CInput from "../../Components/CInput";
import _ from "lodash";
import BaseColor from "../../Config/colors";
import theme, { FontFamily } from "../../Config/theme";
import { isMobile, isTablet } from "react-device-detect";
import CStepper from "../../Components/CStepper";
import styles from "./styles";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { Setting } from "../../Utils/Setting";
import Lottie from "lottie-react";
import Files from "../../Config/Files";
import CModal from "../../Components/CModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../Redux/reducers/auth/actions";

const GolfOtherDetails = forwardRef((props, ref) => {
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const { setUserData, setAccessToken } = authActions;
  const { email, setOtherDetailLoader } = props;
  const classes = styles();
  const [thankYouModal, setThankYouModal] = useState(false);
  const signUpData = useSelector((state) => state.auth.signUpData);

  const scrollRef = useRef(null);

  const steps = ["Personal Details", "Golf Clubs", "Golf Other Details"];
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [tellAboutYourself, setTellAboutYourself] = useState("");
  const [tellAboutYourselfErr, setTellAboutYourselfErr] = useState(false);
  const [tellAboutYourselfMsg, setTellAboutYourselfMsg] = useState("");

  const [fitForClubReason, setFitForClubReason] = useState("");
  const [fitForClubReasonErr, setFitForClubReasonErr] = useState(false);
  const [fitForClubReasonMsg, setFitForClubReasonMsg] = useState("");

  const [hearAboutOption, setHearAboutOption] = useState({});
  const [hearAboutOptionErr, setHearAboutOptionErr] = useState(false);
  const [hearAboutOptionMsg, setHearAboutOptionMsg] = useState("");

  const [handicapOption, setHandicapOption] = useState("");
  const [handicapOptionErr, setHandicapOptionErr] = useState(false);
  const [handicapOptionMsg, setHandicapOptionMsg] = useState("");

  const [beerAndCocktails, setBeerAndCocktails] = useState("");
  const [beerAndCocktailsErr, setBeerAndCocktailsErr] = useState(false);
  const [beerAndCocktailsMsg, setBeerAndCocktailsMsg] = useState("");

  const [listenToMusic, setListenToMusic] = useState("");
  const [listenToMusicErr, setListenToMusicErr] = useState(false);
  const [listenToMusicMsg, setListenToMusicMsg] = useState("");

  const [hostMoreGuests, setHostMoreGuests] = useState("");
  const [hostMoreGuestsErr, setHostMoreGuestsErr] = useState(false);
  const [hostMoreGuestsMsg, setHostMoreGuestsMsg] = useState("");

  const [hasGolfSimulator, setHasGolfSimulator] = useState("");
  const [hasGolfSimulatorErr, setHasGolfSimulatorErr] = useState(false);
  const [hasGolfSimulatorMsg, setHasGolfSimulatorMsg] = useState("");

  const [referralName, setReferralName] = useState("");
  const [referralNameErr, setReferralNameErr] = useState(false);
  const [referralNameMsg, setReferralNameMsg] = useState("");

  const [shirtSize, setShirtSize] = useState({});
  const [shirtSizeErr, setShirtSizeErr] = useState(false);
  const [shirtSizeMsg, setShirtSizeMsg] = useState("");

  useImperativeHandle(ref, () => ({
    handleNextClick() {
      let isValid = true;
      let tellAboutError = false;
      let goodFitError = false;
      let hearAboutError = false;
      let handicapError = false;
      let beerCocktailError = false;
      let listenMusicError = false;
      let oneMoreGuestError = false;

      if (tellAboutYourself.trim() === "") {
        setTellAboutYourselfErr(true);
        tellAboutError = true;
        setTellAboutYourselfMsg(
          "Please enter a brief description about yourself"
        );
        isValid = false;
        scrollRef?.current?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } else {
        setTellAboutYourselfErr(false);
        setTellAboutYourselfMsg("");
      }

      if (fitForClubReason.trim() === "") {
        setFitForClubReasonErr(true);
        goodFitError = true;
        isValid = false;
        setFitForClubReasonMsg(
          "Please enter a brief description of GOATT golf club"
        );
        if (!tellAboutError) {
          scrollRef?.current?.scrollTo({
            top: 250,
            behavior: "smooth",
          });
        }
      } else {
        setFitForClubReasonErr(false);
        setFitForClubReasonMsg("");
      }

      if (_.isEmpty(hearAboutOption)) {
        setHearAboutOptionErr(true);
        setHearAboutOptionMsg(
          "Please select an option for how you heard about GOATT Golf Club"
        );
        isValid = false;
        hearAboutError = true;
        if (!tellAboutError && !goodFitError) {
          scrollRef?.current?.scrollTo({
            top: 400,
            behavior: "smooth",
          });
        }
      } else {
        setHearAboutOptionErr(false);
        setHearAboutOptionMsg("");

        if (hearAboutOption.name === "Referral") {
          if (referralName.trim() === "") {
            setReferralNameErr(true);
            setReferralNameMsg("Please enter a reference name");
            isValid = false;
            if (!tellAboutYourselfErr && !fitForClubReasonErr) {
              scrollRef?.current?.scrollTo({
                top: 400,
                behavior: "smooth",
              });
            }
          } else {
            setReferralNameErr(false);
            setReferralNameMsg("");
          }
        }
      }

      if (handicapOption.trim() === "") {
        setHandicapOptionErr(true);
        setHandicapOptionMsg("Please enter your Handicap/GHIN");
        isValid = false;
        handicapError = true;
        if (!tellAboutYourselfErr && !fitForClubReasonErr && !hearAboutError) {
          scrollRef?.current?.scrollTo({
            top: 400,
            behavior: "smooth",
          });
        }
      } else {
        setHandicapOptionErr(false);
        setHandicapOptionMsg("");
      }

      if (beerAndCocktails === "") {
        setBeerAndCocktailsErr(true);
        beerCocktailError = true;
        setBeerAndCocktailsMsg(
          "Please select beer and cocktails while playing golf"
        );
        isValid = false;
        if (
          !tellAboutYourselfErr &&
          !fitForClubReasonErr &&
          !hearAboutError &&
          !handicapError
        ) {
          scrollRef?.current?.scrollTo({
            top: 600,
            behavior: "smooth",
          });
        }
      } else {
        setBeerAndCocktailsErr(false);
        setBeerAndCocktailsMsg("");
      }

      if (listenToMusic === "") {
        setListenToMusicErr(true);
        listenMusicError = true;
        setListenToMusicMsg(
          "Please select  listen to music while playing golf"
        );
        isValid = false;
        if (
          !tellAboutYourselfErr &&
          !fitForClubReasonErr &&
          !hearAboutError &&
          !handicapError &&
          !beerCocktailError
        ) {
          scrollRef?.current?.scrollTo({
            top: 600,
            behavior: "smooth",
          });
        }
      } else {
        setListenToMusicErr(false);
        setListenToMusicMsg("");
      }

      if (hostMoreGuests === "") {
        setHostMoreGuestsErr(true);
        oneMoreGuestError = true;
        setHostMoreGuestsMsg(
          "Please select host more than one guest at a time"
        );
        isValid = false;
        if (
          !tellAboutYourselfErr &&
          !fitForClubReasonErr &&
          !hearAboutError &&
          !handicapError &&
          !beerCocktailError &&
          !listenMusicError
        ) {
          scrollRef?.current?.scrollTo({
            top: 700,
            behavior: "smooth",
          });
        }
      } else {
        setHostMoreGuestsErr(false);
        setHostMoreGuestsMsg("");
      }

      if (hasGolfSimulator === "") {
        setHasGolfSimulatorErr(true);
        setHasGolfSimulatorMsg("Please select golf simulator in your home");
        isValid = false;
        if (
          !tellAboutYourselfErr &&
          !fitForClubReasonErr &&
          !hearAboutError &&
          !handicapError &&
          !beerCocktailError &&
          !listenMusicError &&
          oneMoreGuestError
        ) {
          scrollRef?.current?.scrollTo({
            top: 700,
            behavior: "smooth",
          });
        }
      } else {
        setHasGolfSimulatorErr(false);
        setHasGolfSimulatorMsg("");
      }

      if (shirtSize === "") {
        setShirtSizeErr(true);
        setShirtSizeMsg("Please select your shirt size");
        isValid = false;
        if (
          !tellAboutYourselfErr &&
          !fitForClubReasonErr &&
          !hearAboutError &&
          !handicapError &&
          !beerCocktailError &&
          !listenMusicError &&
          !oneMoreGuestError
        ) {
          scrollRef?.current?.scrollTo({
            top: 800,
            behavior: "smooth",
          });
        }
      } else {
        setShirtSizeErr(false);
        setShirtSizeMsg("");
      }

      if (isValid) {
        getOtherDetails();
      }
    },
  }));

  const getOtherDetails = async () => {
    setOtherDetailLoader(true);
    let endPoints = Setting.endpoints.golfOtherDetails;
    const params = {
      email: email || signUpData?.email || "",
      "MembersOtherDetails[about_yourself]": tellAboutYourself || "",
      "MembersOtherDetails[fit_reason]": fitForClubReason || "",
      "MembersOtherDetails[goatt_source]": hearAboutOption?.name || "",
      "MembersOtherDetails[reference_name]":
        hearAboutOption?.name === "Referral" ? referralName : "" || "",
      "MembersOtherDetails[handicap_or_ghin]": handicapOption || "",
      "MembersOtherDetails[beverages_available]":
        beerAndCocktails === "yes" ? 0 : 1 || "",
      "MembersOtherDetails[listen_to_music]":
        listenToMusic === "yes" ? 0 : 1 || "",
      "MembersOtherDetails[home_golf_simulator]":
        hasGolfSimulator === "yes" ? 0 : 1 || "",
      "MembersOtherDetails[multi_guest]": hostMoreGuests === "yes" ? 0 : 1,
      "MembersOtherDetails[shirt_size]": shirtSize?.name || "",
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        setThankYouModal(true);
        setTimeout(() => {
          setThankYouModal(false);
          dispatch(setAccessToken(""));
          dispatch(setUserData({}));
          navigate("/", { replace: true });
        }, [3000]);
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
      }
      setOtherDetailLoader(false);
    } catch (error) {
      toast.error(error?.message, {
        position: "top-right",
      });
      setOtherDetailLoader(false);
    }
  };

  return (
    <Grid
      style={{
        height: isTablet || isMobile ? "calc(100% - 130px)" : "85%",
        overflowY: "auto",
      }}
      className={classes.scrollBar}
      ref={scrollRef}
    >
      <Grid
        container
        style={{
          // paddingLeft: md ? "20px" : "30px",
          // paddingRight: md ? "20px" : "30px",
          paddingTop: !md && "20px",
          paddingBottom: md && "8vh",
        }}
      >
        <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
        <Grid item xs={11} sm={11} md={10} lg={10.5}>
          {md && (
            <Grid item xs={12} mb={"20px"}>
              <CStepper activeStep={2} steps={steps} />
            </Grid>
          )}

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography
              variant="body1"
              style={{
                color: BaseColor.blackColor,
                fontSize: isMobile ? 18 : 26,
                marginBottom: 8,
                fontFamily: FontFamily.GilbertQualifiDemo,
              }}
            >
              Golf Other Details{" "}
            </Typography>
            {!isMobile && (
              <hr
                style={{
                  width: "100%",
                  borderTop: "1px solid white",
                  marginBottom: sm ? 10 : 16,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CInput
              fullWidth
              label="Tell us about yourself"
              placeholder="Brief description"
              multiline
              rows={4}
              variant="outlined"
              value={tellAboutYourself}
              onChange={(e) => {
                setTellAboutYourself(e);
                setTellAboutYourselfErr(false);
                setTellAboutYourselfMsg("");
              }}
              endIcon
              maxLength={300}
              error={tellAboutYourselfErr}
              errorMsg={tellAboutYourselfErr ? tellAboutYourselfMsg : null}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <CInput
              fullWidth
              placeholder="Brief description"
              label="Why do you think you'd be a good fit for GOATT golf club"
              multiline
              rows={4}
              variant="outlined"
              value={fitForClubReason}
              onChange={(e) => {
                setFitForClubReason(e);
                setFitForClubReasonErr(false);
                setFitForClubReasonMsg("");
              }}
              endIcon
              maxLength={300}
              error={fitForClubReasonErr}
              errorMsg={fitForClubReasonErr ? fitForClubReasonMsg : null}
            />
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                <CSelect
                  fullObj
                  data={[
                    { id: 1, name: "Referral" },
                    { id: 2, name: "Social Media" },
                    { id: 3, name: "Internet" },
                    { id: 4, name: "Google search" },
                  ]}
                  value={hearAboutOption}
                  label="How did you hear about GOATT Golf Club"
                  placeholder="Select Option"
                  onSelect={(val) => {
                    setHearAboutOption(val);
                    setHearAboutOptionErr(false);
                    setHearAboutOptionMsg("");
                    setReferralName("");
                  }}
                  error={hearAboutOptionErr}
                  errorMsg={hearAboutOptionErr ? hearAboutOptionMsg : null}
                />
              </Grid>
              {sm && (
                <>
                  {!_.isEmpty(hearAboutOption) &&
                    hearAboutOption?.name === "Referral" && (
                      <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                        <CInput
                          fullWidth
                          label="Reference Name"
                          multiline
                          variant="outlined"
                          value={referralName}
                          onChange={(e) => {
                            setReferralName(e);
                            setReferralNameErr(false);
                            setReferralNameMsg("");
                          }}
                          error={referralNameErr}
                          errorMsg={referralNameErr ? referralNameMsg : null}
                        />
                      </Grid>
                    )}
                </>
              )}
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                <CInput
                  fullWidth
                  placeholder="Handicap/GHIN"
                  label="Handicap/GHIN"
                  variant="outlined"
                  value={handicapOption}
                  onChange={(e) => {
                    // Allow only numeric and decimal input
                    const numericValue = e.replace(/[^0-9.]/g, ""); // Replace non-numeric and non-decimal characters
                    const decimalCount = (numericValue.match(/\./g) || [])
                      .length; // Count decimal points

                    let formattedValue = numericValue;

                    // Check if there is more than one decimal point
                    if (decimalCount > 1) {
                      // If more than one decimal point, remove the extra ones
                      const parts = numericValue.split(".");
                      const integerPart = parts[0];
                      const decimalPart = parts.slice(1).join("");
                      formattedValue = `${integerPart}.${decimalPart}`;
                    }

                    // Check if the numeric value is valid and within the range 0 to 54
                    const numericValueFloat = parseFloat(formattedValue);
                    if (
                      numericValueFloat > 54 ||
                      numericValueFloat < 0 ||
                      isNaN(numericValueFloat)
                    ) {
                      formattedValue = ""; // If value exceeds 54, is less than 0, or is NaN, clear the input
                    }

                    setHandicapOption(formattedValue);
                    setHandicapOptionErr(false);
                    setHandicapOptionMsg("");
                  }}
                  error={handicapOptionErr}
                  errorMsg={handicapOptionErr ? handicapOptionMsg : null}
                />
              </Grid>

              {/* {!isMobile && (
            <>
              {!_.isEmpty(hearAboutOption) &&
                hearAboutOption?.name === "Referral" && (
                  <>
                    <Grid item xs={12} sm={5.8}>
                      <CInput
                        fullWidth
                        label="Reference Name"
                        multiline
                        variant="outlined"
                        value={referralName}
                        onChange={(e) => {
                          setReferralName(e);
                          setReferralNameErr(false);
                          setReferralNameMsg("");
                        }}
                        error={referralNameErr}
                        errorMsg={referralNameErr ? referralNameMsg : null}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={5.8}
                      md={6}
                      lg={6}
                      style={{ marginTop: 10 }}
                    >
                      <Typography
                        style={{
                          fontSize: isMobile ? 14 : 18,
                          fontFamily: FontFamily.SemiBold,
                          color: BaseColor.textColor,
                        }}
                      >
                        Do you listen to music while you play golf?
                      </Typography>
                      <RadioGroup
                        row
                        value={listenToMusic}
                        onChange={(e) => setListenToMusic(e.target.value)}
                      >
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      {listenToMusicErr && (
                        <Typography
                          style={{
                            fontSize: 14,
                            color: BaseColor.errorRed,
                            fontFamily: FontFamily.Medium,
                          }}
                        >
                          {listenToMusicMsg}
                        </Typography>
                      )}
                    </Grid>
                  </>
                )}
            </>
          )} */}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                {!sm && hearAboutOption?.name === "Referral" ? (
                  <CInput
                    fullWidth
                    label="Reference Name"
                    multiline
                    variant="outlined"
                    value={referralName}
                    onChange={(e) => {
                      setReferralName(e);
                      setReferralNameErr(false);
                      setReferralNameMsg("");
                    }}
                    error={referralNameErr}
                    errorMsg={referralNameErr ? referralNameMsg : null}
                  />
                ) : (
                  <>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily: FontFamily.SemiBold,
                        color: BaseColor.textColor,
                        marginTop: "15px",
                      }}
                    >
                      Beer and cocktails while you play?
                    </Typography>
                    <RadioGroup
                      row
                      value={beerAndCocktails}
                      onChange={(e) => {
                        setBeerAndCocktails(e.target.value);
                        setBeerAndCocktailsErr(false);
                        setBeerAndCocktailsMsg("");
                      }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.blackColor,
                        },
                        "MuiButtonBase-root MuiRadio-root": {
                          padding: "0px",
                        },
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio sx={{ paddingLeft: "0px" }} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {beerAndCocktailsErr && (
                      <Typography
                        style={{
                          fontSize: 14,
                          color: BaseColor.errorRed,
                          fontFamily: FontFamily.Medium,
                        }}
                      >
                        {beerAndCocktailsMsg}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: FontFamily.SemiBold,
                    color: BaseColor.textColor,
                    marginTop: "15px",
                  }}
                >
                  Do you listen to music while you play golf?
                </Typography>
                <RadioGroup
                  row
                  value={listenToMusic}
                  onChange={(e) => {
                    setListenToMusic(e.target.value);
                    setListenToMusicErr(false);
                    setListenToMusicMsg("");
                  }}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: FontFamily.Medium,
                      color: BaseColor.blackColor,
                    },
                    "MuiButtonBase-root MuiRadio-root": {
                      padding: "0px",
                    },
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio sx={{ paddingLeft: "0px" }} />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                {listenToMusicErr && (
                  <Typography
                    style={{
                      fontSize: 14,
                      color: BaseColor.errorRed,
                      fontFamily: FontFamily.Medium,
                    }}
                  >
                    {listenToMusicMsg}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                {hearAboutOption?.name === "Referral" && !sm ? (
                  <>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily: FontFamily.SemiBold,
                        color: BaseColor.textColor,
                        marginTop: "15px",
                      }}
                    >
                      Beer and cocktails while you play?
                    </Typography>
                    <RadioGroup
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.blackColor,
                        },
                        "MuiButtonBase-root MuiRadio-root": {
                          padding: "0px",
                        },
                      }}
                      row
                      value={beerAndCocktails}
                      onChange={(e) => {
                        setBeerAndCocktails(e.target.value);
                        setBeerAndCocktailsErr(false);
                        setBeerAndCocktailsMsg("");
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio sx={{ paddingLeft: "0px" }} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {beerAndCocktailsErr && (
                      <Typography
                        style={{
                          fontSize: 14,
                          color: BaseColor.errorRed,
                          fontFamily: FontFamily.Medium,
                        }}
                      >
                        {beerAndCocktailsMsg}
                      </Typography>
                    )}
                  </>
                ) : (
                  <>
                    <Typography
                      style={{
                        fontSize: 16,
                        fontFamily: FontFamily.SemiBold,
                        color: BaseColor.textColor,
                        marginTop: "15px",
                      }}
                    >
                      Will you host more than one guest at a time?
                    </Typography>
                    <RadioGroup
                      row
                      value={hostMoreGuests}
                      onChange={(e) => {
                        setHostMoreGuests(e.target.value);
                        setHostMoreGuestsErr(false);
                        setHostMoreGuestsMsg("");
                      }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.blackColor,
                        },
                        "MuiButtonBase-root MuiRadio-root": {
                          padding: "0px",
                        },
                      }}
                    >
                      <FormControlLabel
                        value="yes"
                        control={<Radio sx={{ paddingLeft: "0px" }} />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                    {hostMoreGuestsErr && (
                      <Typography
                        style={{
                          fontSize: 14,
                          color: BaseColor.errorRed,
                          fontFamily: FontFamily.Medium,
                        }}
                      >
                        {hostMoreGuestsMsg}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                <Typography
                  style={{
                    fontSize: 16,
                    fontFamily: FontFamily.SemiBold,
                    color: BaseColor.textColor,
                    marginTop: "15px",
                  }}
                >
                  Do you have a golf simulator in your home?
                </Typography>
                <RadioGroup
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: FontFamily.Medium,
                      color: BaseColor.blackColor,
                    },
                    "MuiButtonBase-root MuiRadio-root": {
                      padding: "0px",
                    },
                  }}
                  row
                  value={hasGolfSimulator}
                  onChange={(e) => {
                    setHasGolfSimulator(e.target.value);
                    setHasGolfSimulatorErr(false);
                    setHasGolfSimulatorMsg("");
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={<Radio sx={{ paddingLeft: "0px" }} />}
                    label="Yes"
                  />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                {hasGolfSimulatorErr && (
                  <Typography
                    style={{
                      fontSize: 14,
                      color: BaseColor.errorRed,
                      fontFamily: FontFamily.Medium,
                    }}
                  >
                    {hasGolfSimulatorMsg}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          {hearAboutOption?.name === "Referral" && !sm && (
            <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
              <Typography
                style={{
                  fontSize: 16,
                  fontFamily: FontFamily.SemiBold,
                  color: BaseColor.textColor,
                }}
              >
                Will you host more than one guest at a time?
              </Typography>
              <RadioGroup
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontFamily: FontFamily.Medium,
                    color: BaseColor.blackColor,
                  },
                  "MuiButtonBase-root MuiRadio-root": {
                    padding: "0px",
                  },
                }}
                row
                value={hostMoreGuests}
                onChange={(e) => {
                  setHostMoreGuests(e.target.value);
                  setHostMoreGuestsErr(false);
                  setHostMoreGuestsMsg("");
                }}
              >
                <FormControlLabel
                  value="yes"
                  control={<Radio sx={{ paddingLeft: "0px" }} />}
                  label="Yes"
                />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
              {hostMoreGuestsErr && (
                <Typography
                  style={{
                    fontSize: 14,
                    color: BaseColor.errorRed,
                    fontFamily: FontFamily.Medium,
                  }}
                >
                  {hostMoreGuestsMsg}
                </Typography>
              )}
            </Grid>
          )}
          <CSelect
            style={{ marginBottom: "20px" }}
            fullObj
            data={[
              { id: 1, name: "Extra Small" },
              { id: 2, name: "Small" },
              { id: 3, name: "Medium" },
              { id: 4, name: "Large" },
              { id: 5, name: "Extra Large" },
              { id: 6, name: "XXL" },
              { id: 7, name: "XXXL" },
            ]}
            value={shirtSize}
            label="What is your shirt size?"
            placeholder="Select Size"
            onSelect={(val) => {
              setShirtSize(val);
              setShirtSizeErr(false);
              setShirtSizeMsg("");
            }}
            error={shirtSizeErr}
            errorMsg={shirtSizeErr ? shirtSizeMsg : null}
          />
        </Grid>

        <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
      </Grid>

      {/* thank you modal */}
      <CModal
        visible={thankYouModal}
        onClose={() => {
          setThankYouModal(false);
        }}
        children={
          <Grid
            container
            padding={isMobile ? "20px" : "60px"}
            style={{
              position: "relative",
              width: isTablet ? "75vw" : md ? "90vw" : "600px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
            className={classes.scrollBar}
          >
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Lottie
                animationData={Files.lottie.verify}
                loop={true}
                height={50}
                width="100%"
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: isMobile ? "16px" : "22px",
                  color: BaseColor.blackColor,
                  textAlign: "center",
                }}
              >
                Thank You for your application.
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                style={{
                  fontFamily: FontFamily.Medium,
                  fontSize: isMobile ? "14px" : "16px",
                  color: BaseColor.grey,
                  marginTop: "15px",
                  textAlign: "center",
                  color: BaseColor.blackColor,
                }}
              >
                GOATT golf club will be reaching out to you shortly.
              </Typography>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
});

export default GolfOtherDetails;
