import {
  CircularProgress,
  Grid,
  ListItemButton,
  Switch,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import BaseColor from "../../../../Config/colors";
import theme, { FontFamily } from "../../../../Config/theme";
import Files from "../../../../Config/Files";
import {
  deleteAccountReasonsData,
  profileMenuData,
} from "../../../../Config/staticData";
import { MdNavigateNext } from "react-icons/md";
import styles from "./styles";
import CHeader from "../../../../Components/Header";
import ProfilePersonalInfo from "../PersonalInfo/index";
import GolfClubs from "../GolfClubs";
import GolfOtherDetails from "../GolfOtherDetails";
import CModal from "../../../../Components/CModal";
import { isMobile, isTablet } from "react-device-detect";
import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import CInput from "../../../../Components/CInput";
import _ from "lodash";
import CButton from "../../../../Components/CButton";
import CSelect from "../../../../CSelect";
import { useNavigate } from "react-router-dom";
import { Setting } from "../../../../Utils/Setting";
import { useSelector } from "react-redux";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { toast } from "react-toastify";
import { store } from "../../../../Redux/store/configureStore";
import authActions from "../../../../Redux/reducers/auth/actions";
import Reviews from "../Reviews";
import Subscription from "../Subscription";

const Profile = () => {
  const classes = styles();

  const navigate = useNavigate();

  const [activeIndex, setActiveIndex] = useState(1);

  const { userData, useruuid } = useSelector((state) => state.auth);

  // page loader
  const [pageLoader, setPageLoader] = useState(false);

  // image
  const [profileImage, setProfileImage] = useState("");

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  //change password modal
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);

  // password form state
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  //Error state
  const [currentPasswordErrorText, setCurrentPasswordErrorText] = useState("");
  const [newPasswordErrorText, setNewPasswordErrorText] = useState("");
  const [confirmNewPasswordErrorText, setConfirmNewPasswordErrorText] =
    useState("");

  // show password
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  // btn loader
  const [saveBtnLoader, setSaveBtnLoader] = useState(false);

  // logout modal
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [logoutBtnLoader, setLogoutBtnLoader] = useState(false);

  // delete account modal
  const [deleteAccountModal, setDeleteAccountModal] = useState(false);
  const [deleteBtnLoader, setDeleteBtnLoader] = useState(false);

  // delete account state
  const [deleteAccountReason, setDeleteAccountReason] = useState({});
  const [deleteAccountReasonErrorText, setDeleteAccountReasonErrorText] =
    useState("");
  const [description, setDescription] = useState("");
  const [descriptionErrorText, setDescriptionErrorText] = useState("");

  // switch value
  const [switchValue, setSwitchValue] = useState(userData?.is_do_not_disturb);

  // validation
  const createNewPasswordValidation = (event) => {
    event.preventDefault();

    if (currentPassword.trim() === "") {
      setCurrentPasswordErrorText("Current password is required");
      return;
    }

    if (newPassword.trim() === "") {
      setNewPasswordErrorText("New password is required");
      return;
    }

    if (newPassword.length < 8) {
      setNewPasswordErrorText("Password must be at least 8 characters long");
      return;
    }

    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!numberRegex.test(newPassword)) {
      setNewPasswordErrorText("Password must contain at least one number");
      return;
    }

    if (!uppercaseRegex.test(newPassword)) {
      setNewPasswordErrorText(
        "Password must contain at least one uppercase letter"
      );
      return;
    }

    if (!specialCharRegex.test(newPassword)) {
      setNewPasswordErrorText(
        "Password must contain at least one special character"
      );
      return;
    }

    if (confirmNewPassword?.trim() === "") {
      setConfirmNewPasswordErrorText("Confirm password is required");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setConfirmNewPasswordErrorText(
        "New password and Confirm new password should be the same"
      );
      return;
    } else {
      getChangePassword();
    }
  };

  // delete account validation
  const deleteAccountValidation = () => {
    if (_.isEmpty(deleteAccountReason)) {
      setDeleteAccountReasonErrorText("Reason selection is required");
    } else if (
      deleteAccountReason?.name === "Other" &&
      description?.trim === ""
    ) {
      setDescriptionErrorText("Description is required");
    } else {
      allErrorFalseDeleteAccount();
      deleteAccountApiCall();
    }
  };

  const allErrorFalseDeleteAccount = () => {
    setDeleteAccountReasonErrorText("");
    setDescriptionErrorText("");
  };

  const deleteAccountApiCall = async () => {
    setDeleteBtnLoader(true);
    let endPoints = Setting.endpoints.deleteAccount;

    const params = {
      "Users[reason]":
        deleteAccountReason?.name === "Other"
          ? description
          : deleteAccountReason?.name,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        setDeleteAccountModal(false);
        setDeleteAccountReason({});
        setDescription("");
        setActiveIndex(1);
        setDeleteBtnLoader(false);
        store.dispatch(authActions.setAccessToken(""));
        store.dispatch(authActions.setUserData({}));
        store.dispatch(authActions.setLatLng({}));
        store.dispatch(authActions.setSelectedChatData({}));
        navigate("/");
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setDeleteBtnLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setDeleteBtnLoader(false);
    }
  };

  // change password api call
  const getChangePassword = async () => {
    setSaveBtnLoader(true);
    let endPoints = Setting.endpoints.changePassword;

    const params = {
      "ChangePasswordForm[current_password]": currentPassword,
      "ChangePasswordForm[password]": newPassword,
      "ChangePasswordForm[confirm_password]": confirmNewPassword,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        setOpenChangePasswordModal(false);
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setActiveIndex(1);
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setSaveBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setSaveBtnLoader(false);
      setOpenChangePasswordModal(false);
    }
  };

  useEffect(() => {
    getCommonData(true);
  }, []);

  const isLengthValid = newPassword.length >= 8;
  const hasUpperCase = /[A-Z]/.test(newPassword);
  const hasSpecialChar = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
    newPassword
  );
  const hasNumber = /[0-9]/.test(newPassword);
  const passwordsMatch =
    newPassword === confirmNewPassword && newPassword !== "";

  const getCommonData = async (pageLoad = false) => {
    if (pageLoad) {
      setPageLoader(true);
    }
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: userData?.email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setProfileImage(resp?.data?.profile_pic);
          store.dispatch(authActions.setUserData(resp?.data));
        }
        if (pageLoad) {
          setPageLoader(false);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        if (pageLoad) {
          setPageLoader(false);
        }
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      if (pageLoad) {
        setPageLoader(false);
      }
    }
  };

  const logoutApiCall = async () => {
    setLogoutBtnLoader(true);
    let endPoints = Setting.endpoints.logOutApi;
    const data = {
      notification_token: useruuid,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        store.dispatch(authActions.setAccessToken(""));
        store.dispatch(authActions.setUserData({}));
        store.dispatch(authActions.setLatLng({}));
        store.dispatch(authActions.setSelectedChatData({}));
        store.dispatch(authActions.setIsTokenSent(false));
        store.dispatch(authActions.setUserUUID(""));
        store.dispatch(authActions.setBackLatLng({}));
        store.dispatch(authActions.setBackSearch(""));
        store.dispatch(authActions.setBackState({}));
        store.dispatch(authActions.setBackLocationLatLng({}));
        store.dispatch(authActions.setHomeBackLatLng({}));
        store.dispatch(authActions.setSignupData({}));
        setLogoutBtnLoader(false);
        if (isMobile) {
          navigate("/login");
        } else {
          navigate("/");
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setLogoutBtnLoader(false);
      }
      setLogoutBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLogoutBtnLoader(false);
    }
  };

  const changeDoNotDisturbStatus = async (type) => {
    let endPoints = Setting.endpoints.changeDoNotDisturb;
    const data = {
      status: type ? 1 : 0,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        getCommonData();
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
    }
  };

  return (
    <Grid container height={"100%"}>
      <CHeader />
      {pageLoader ? (
        <Grid
          item
          xs={12}
          style={{
            height: md ? "calc(100vh - 150px)" : "calc(100vh - 110px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
          <Grid
            item
            xs={4}
            style={{
              backgroundColor: BaseColor.offWhiteBg,
              overflowY: "auto",
              height: "calc(100vh - 90px)",
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                mt={"20px"}
                paddingLeft={"10px"}
                paddingRight={"10px"}
              >
                <IoIosArrowRoundBack
                  style={{
                    fontSize: 40,
                    color: BaseColor.blackColor,
                    cursor: !isMobile && !isTablet && "pointer",
                  }}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <div
                  style={{
                    width: "85%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      fontSize: 22,
                      color: BaseColor.blackColor,
                    }}
                  >
                    Profile
                  </Typography>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                mt={"50px"}
              >
                <div
                  style={{
                    backgroundColor: BaseColor.white,
                    width: "100%",
                    background: `linear-gradient(to right, white , ${BaseColor.primary}, white)`,
                    boxShadow: "0 0 20px 1px rgba(0, 0, 0, 0.1x)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "80px",
                    position: "relative",
                  }}
                >
                  <img
                    src={profileImage || Files.Images.roundLogo}
                    alt="profile"
                    draggable={false}
                    style={{
                      objectFit: "cover",
                      width: "100px",
                      height: "100px",
                      borderRadius: "50%",
                      position: "absolute",
                      zIndex: 10,
                      border: `3px solid ${BaseColor.primary}`,
                    }}
                  />
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                mt={"20px"}
                paddingLeft={"15px"}
                paddingRight={"10px"}
              >
                {profileMenuData
                  .filter(
                    (data) => !(data.id === 4 && userData?.is_social_login)
                  )
                  .map((data) => (
                    <ListItemButton
                      key={data.id}
                      style={{
                        fontSize: 16,
                        marginBottom: "10px",
                        fontFamily: FontFamily.SemiBold,
                        color:
                          activeIndex === data?.id
                            ? BaseColor.white
                            : BaseColor.primary,
                        display: "flex",
                        justifyContent: "space-between",
                        borderRadius: "5px",
                        backgroundColor:
                          activeIndex === data?.id && BaseColor.primary,
                      }}
                      onClick={() => {
                        if (data?.id === 4) {
                          setOpenChangePasswordModal(true);
                        }
                        if (data?.id === 6) {
                          setOpenLogoutModal(true);
                        }
                        if (data?.id === 5) {
                          setDeleteAccountModal(true);
                        }
                        setActiveIndex(data?.id);
                      }}
                    >
                      {data?.title}
                      {data?.id !== 4 && data?.id !== 5 && data?.id !== 6 && (
                        <MdNavigateNext style={{ fontSize: 25 }} />
                      )}
                    </ListItemButton>
                  ))}
              </Grid>
              <Grid item xs={12} paddingLeft={"30px"} paddingRight={"10px"}>
                <div
                  style={{
                    fontSize: 16,
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "5px",
                    width: "100%",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      color: BaseColor.primary,
                    }}
                  >
                    {" "}
                    Do not disturb
                  </Typography>

                  <Switch
                    checked={switchValue}
                    onChange={(e) => {
                      changeDoNotDisturbStatus(e.target.checked);
                      setSwitchValue(e.target.checked);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            sm={7}
            md={6.5}
            style={{
              height: "calc(100vh - 90px)",
              overflowY: "auto",
            }}
            className={classes.scrollBar}
          >
            <Grid container height={"100%"}>
              <Grid
                item
                xs={11}
                style={{
                  height: "100%",
                }}
              >
                {activeIndex === 2 ? (
                  <GolfClubs />
                ) : activeIndex === 3 ? (
                  <GolfOtherDetails />
                ) : activeIndex === 7 ? (
                  <Reviews />
                ) : activeIndex === 8 ? (
                  <Subscription />
                ) : (
                  <ProfilePersonalInfo
                    getUserData={() => {
                      getCommonData();
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
          <CModal
            visible={openChangePasswordModal}
            onClose={() => {
              setOpenChangePasswordModal(false);
              setCurrentPassword("");
              setCurrentPasswordErrorText("");
              setNewPassword("");
              setNewPasswordErrorText("");
              setConfirmNewPassword("");
              setConfirmNewPasswordErrorText("");
              setShowCurrentPassword(false);
              setShowNewPassword(false);
              setShowConfirmNewPassword(false);
              setActiveIndex(1);
            }}
            children={
              <Grid
                container
                padding={isMobile ? "20px" : "40px"}
                style={{
                  position: "relative",
                  width: sm ? "90vw" : "580px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
                className={classes.scrollBar}
              >
                {!isMobile && (
                  <CloseIcon
                    onClick={() => {
                      setOpenChangePasswordModal(false);
                      setOpenChangePasswordModal(false);
                      setCurrentPassword("");
                      setCurrentPasswordErrorText("");
                      setNewPassword("");
                      setNewPasswordErrorText("");
                      setConfirmNewPassword("");
                      setConfirmNewPasswordErrorText("");
                      setShowCurrentPassword(false);
                      setShowNewPassword(false);
                      setShowConfirmNewPassword(false);
                      setActiveIndex(1);
                    }}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 15,
                      fontSize: 30,
                      cursor: !isMobile && !isTablet && "pointer",
                      color: BaseColor.primary,
                      zIndex: 1,
                    }}
                  />
                )}

                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      fontSize: isMobile ? "20px" : "24px",
                      color: BaseColor.blackColor,
                    }}
                  >
                    Change Password
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={isMobile ? "20px" : "30px"}>
                  <CInput
                    placeholder={"Enter current password"}
                    label={"Current Password"}
                    value={currentPassword}
                    image={Files.Images.infoIcon}
                    onChange={(val) => {
                      const trimmedValue = val.trim();
                      setCurrentPassword(trimmedValue);
                      setCurrentPasswordErrorText("");
                    }}
                    type={showCurrentPassword ? "text" : "password"}
                    error={!_.isEmpty(currentPasswordErrorText)}
                    errorMsg={currentPasswordErrorText}
                    endIcon={
                      showCurrentPassword ? (
                        <AiOutlineEye
                          className="CInputStartIcon"
                          onClick={() => setShowCurrentPassword(false)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="CInputStartIcon"
                          onClick={() => setShowCurrentPassword(true)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} mt={"10px"}>
                  <CInput
                    placeholder={"Enter new password"}
                    label={"New Password"}
                    value={newPassword}
                    onChange={(val) => {
                      const trimmedValue = val.trim();
                      setNewPassword(trimmedValue);
                      setNewPasswordErrorText("");
                    }}
                    type={showNewPassword ? "text" : "password"}
                    error={!_.isEmpty(newPasswordErrorText)}
                    errorMsg={newPasswordErrorText}
                    endIcon={
                      showNewPassword ? (
                        <AiOutlineEye
                          className="CInputStartIcon"
                          onClick={() => setShowNewPassword(false)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="CInputStartIcon"
                          onClick={() => setShowNewPassword(true)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} mt={"10px"}>
                  <CInput
                    placeholder={"Enter confirm new password"}
                    label={"Confirm new Password"}
                    value={confirmNewPassword}
                    onChange={(val) => {
                      const trimmedValue = val.trim();
                      setConfirmNewPassword(trimmedValue);
                      setConfirmNewPasswordErrorText("");
                    }}
                    type={showConfirmNewPassword ? "text" : "password"}
                    error={!_.isEmpty(confirmNewPasswordErrorText)}
                    errorMsg={confirmNewPasswordErrorText}
                    endIcon={
                      showConfirmNewPassword ? (
                        <AiOutlineEye
                          className="CInputStartIcon"
                          onClick={() => setShowConfirmNewPassword(false)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      ) : (
                        <AiOutlineEyeInvisible
                          className="CInputStartIcon"
                          onClick={() => setShowConfirmNewPassword(true)}
                          style={{
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                        />
                      )
                    }
                  />
                </Grid>
                {!_.isEmpty(newPassword) && (
                  <Grid item xs={12} mt={"20px"}>
                    <Typography
                      style={{
                        color: _.isEmpty(newPassword)
                          ? BaseColor.blackColor
                          : isLengthValid
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: "12px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Password must be at least 8 characters long.
                    </Typography>
                    <Typography
                      style={{
                        color: _.isEmpty(newPassword)
                          ? BaseColor.blackColor
                          : hasUpperCase
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: "12px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Password must contain at least one uppercase letter.
                    </Typography>
                    <Typography
                      style={{
                        color: _.isEmpty(newPassword)
                          ? BaseColor.blackColor
                          : hasSpecialChar
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: "12px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Password must contain at least one special character.
                    </Typography>
                    <Typography
                      style={{
                        color: _.isEmpty(newPassword)
                          ? BaseColor.blackColor
                          : hasNumber
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: "12px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Password must contain at least one number.
                    </Typography>
                    <Typography
                      style={{
                        color: _.isEmpty(newPassword)
                          ? BaseColor.blackColor
                          : passwordsMatch
                          ? BaseColor.green
                          : BaseColor.errorRed,
                        fontSize: "12px",
                        fontFamily: FontFamily.Medium,
                      }}
                    >
                      Passwords match.
                    </Typography>
                  </Grid>
                )}

                <Grid
                  item
                  xs={12}
                  mt={"40px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <CButton
                    btnStyle={{ width: isMobile ? "100%" : "40%" }}
                    loading={saveBtnLoader}
                    onClick={(e) => {
                      createNewPasswordValidation(e);
                    }}
                  >
                    Change Password
                  </CButton>
                </Grid>
              </Grid>
            }
          />
          <CModal
            visible={openLogoutModal}
            onClose={() => {
              setOpenLogoutModal(false);
              setActiveIndex(1);
            }}
            children={
              <Grid
                container
                padding={isMobile ? "20px" : "40px"}
                style={{
                  position: "relative",
                  width: sm ? "90vw" : "390px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
                className={classes.scrollBar}
              >
                <CloseIcon
                  onClick={() => {
                    setOpenLogoutModal(false);
                    setActiveIndex(1);
                  }}
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    fontSize: 30,
                    cursor: !isMobile && !isTablet && "pointer",
                    color: BaseColor.primary,
                    zIndex: 1,
                  }}
                />

                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      fontSize: isMobile ? "20px" : "24px",
                      color: BaseColor.blackColor,
                    }}
                  >
                    Logout
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  mt={isMobile ? "20px" : "30px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      color: BaseColor.blackColor,
                      fontFamily: FontFamily.Medium,
                      fontSize: 18,
                    }}
                  >
                    Are you sure you want to logout?
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={12}
                  mt={"40px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <CButton
                    btnStyle={{ width: isMobile ? "100%" : "45%" }}
                    variant={"outlined"}
                    onClick={() => {
                      setOpenLogoutModal(false);
                      setActiveIndex(1);
                    }}
                  >
                    Cancel
                  </CButton>
                  <CButton
                    btnStyle={{ width: isMobile ? "100%" : "45%" }}
                    loading={logoutBtnLoader}
                    onClick={() => {
                      logoutApiCall();
                    }}
                  >
                    Logout
                  </CButton>
                </Grid>
              </Grid>
            }
          />
          <CModal
            visible={deleteAccountModal}
            onClose={() => {
              setDeleteAccountModal(false);
              setDeleteAccountReason("");
              setDeleteAccountReasonErrorText("");
              setActiveIndex(1);
            }}
            children={
              <Grid
                container
                padding={isMobile ? "20px" : "40px"}
                style={{
                  position: "relative",
                  width: sm ? "90vw" : "512px",
                  maxHeight: "80vh",
                  overflowY: "auto",
                }}
                className={classes.scrollBar}
              >
                {!isMobile && (
                  <CloseIcon
                    onClick={() => {
                      setDeleteAccountModal(false);
                      setDeleteAccountReason("");
                      setDeleteAccountReasonErrorText("");
                      setActiveIndex(1);
                    }}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 15,
                      fontSize: 30,
                      cursor: !isMobile && !isTablet && "pointer",
                      color: BaseColor.primary,
                      zIndex: 1,
                    }}
                  />
                )}

                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      fontSize: isMobile ? "20px" : "24px",
                      color: BaseColor.blackColor,
                    }}
                  >
                    Delete Account
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={"20px"}>
                  <Typography
                    style={{
                      fontFamily: FontFamily.Medium,
                      fontSize: 18,
                      color: BaseColor.blackColor,
                    }}
                  >
                    Are you sure you want to delete your account?
                  </Typography>
                  <Typography
                    style={{
                      fontFamily: FontFamily.Regular,
                      fontSize: 14,
                      color: BaseColor.grey,
                    }}
                  >
                    Your information will be permanently deleted through
                    irreversible deletion.
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={"10px"}>
                  <CSelect
                    fullObj
                    data={deleteAccountReasonsData}
                    placeholder={"Enter your reason"}
                    label={"Select Reason"}
                    value={deleteAccountReason}
                    onSelect={(val) => {
                      setDeleteAccountReason(val);
                      setDeleteAccountReasonErrorText("");
                    }}
                    error={!_.isEmpty(deleteAccountReasonErrorText)}
                    errorMsg={deleteAccountReasonErrorText}
                  />
                </Grid>
                {deleteAccountReason?.name === "Other" && (
                  <CInput
                    value={description}
                    placeholder={"Describe here"}
                    onChange={(val) => {
                      setDescription(val);
                      setDescriptionErrorText("");
                    }}
                    multiline={true}
                    rows={6}
                    error={!_.isEmpty(descriptionErrorText)}
                    errorMsg={descriptionErrorText}
                  />
                )}

                <Grid
                  item
                  xs={12}
                  mt={"40px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <CButton
                    btnStyle={{ width: isMobile ? "100%" : "45%" }}
                    variant={"outlined"}
                    onClick={() => {
                      setDeleteAccountModal(false);
                      setDeleteAccountReason("");
                      setDeleteAccountReasonErrorText("");
                      setActiveIndex(1);
                    }}
                  >
                    Cancel
                  </CButton>
                  <CButton
                    btnStyle={{ width: isMobile ? "100%" : "45%" }}
                    loading={deleteBtnLoader}
                    onClick={() => {
                      deleteAccountValidation();
                    }}
                  >
                    Delete
                  </CButton>
                </Grid>
              </Grid>
            }
          />
        </>
      )}
    </Grid>
  );
};

export default Profile;
