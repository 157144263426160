import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import theme, { FontFamily } from "../../../../Config/theme";
import BaseColor from "../../../../Config/colors";
import Files from "../../../../Config/Files";
import CIcon from "../../../../Components/CIcon";
import _, { first } from "lodash";
import CButton from "../../../../Components/CButton";
import CInput from "../../../../Components/CInput";
import styles from "./styles";
import { Settings } from "@mui/icons-material";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { toast } from "react-toastify";
import { Setting } from "../../../../Utils/Setting";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../../../Redux/reducers/auth/actions";
import { isMobile, isTablet } from "react-device-detect";
import PlacesAutocomplete from "../../../../Components/PlaceAutoComplete";
import { motion } from "framer-motion";

const ProfilePersonalInfo = (props) => {
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const classes = styles();
  const [calendarOpen, setCalendarOpen] = useState(false);

  const dispatch = useDispatch();
  const { setUserData } = authActions;
  const { getUserData = () => {} } = props;

  const { userData } = useSelector((state) => state.auth);

  // page loader
  const [pageLoader, setPageLoader] = useState(true);

  const [personalInfoData, setPersonalInfoData] = useState({});

  // edit
  const [edit, setEdit] = useState(false);

  //profile image
  const [profileImage, setProfileImage] = useState("");
  const [profileImageErr, setProfileImageErr] = useState("");

  //form state
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [showBirthDate, setshowBirthDate] = useState("");
  const [address, setAddress] = useState("");
  const [imageObject, setImageObject] = useState({});

  //form error state
  const [firstNameErrorText, setFirstNameErrorText] = useState("");
  const [lastNameErrorText, setLastNameErrorText] = useState("");
  const [nickNameErrorText, setNickNameErrorText] = useState("");
  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneNumberErrorText, setPhoneNumberErrorText] = useState("");
  const [birthDateErr, setBirthDateErr] = useState(false);
  const [birthDateErrorText, setBirthDateErrorText] = useState("");
  const [AddressErrorText, setAddressErrorText] = useState("");

  // btn loader
  const [updateBtnLoader, setUpdateBtnLoader] = useState(false);

  useEffect(() => {
    getCommonData();
  }, []);

  // validation
  const validation = () => {
    const phoneRegex = /^\d{10,14}$/;
    let isValid = true;
    // Utility function to calculate age
    const calculateAge = (birthDate) => {
      const today = dayjs();
      const birthDateDayjs = dayjs(birthDate);
      return today.diff(birthDateDayjs, "year");
    };

    if (firstName?.trim() === "") {
      setFirstNameErrorText("First name is required");
      isValid = false;
    }
    if (
      firstName?.trim() !== "" &&
      (!/^[a-zA-Z]+$/.test(firstName) || firstName.length < 3)
    ) {
      setFirstNameErrorText("Enter valid first name");
      isValid = false;
    }
    if (lastName?.trim() === "") {
      setLastNameErrorText("Last name is required");
      isValid = false;
    }
    if (
      lastName?.trim() !== "" &&
      (!/^[a-zA-Z]+$/.test(lastName) || lastName.length < 3)
    ) {
      setLastNameErrorText("Enter valid last name");
      isValid = false;
    }
    if (nickName?.trim() === "") {
      setNickNameErrorText("Username is required");
      isValid = false;
    }
    if (phoneNumber?.trim() === "") {
      setPhoneNumberErrorText("Phone number is required");
      isValid = false;
    }
    if (phoneNumber?.trim() !== "" && !phoneRegex?.test(phoneNumber)) {
      setPhoneNumberErrorText("Enter valid phone number");
      isValid = false;
    }

    if (!birthDate) {
      setBirthDateErr(true);
      setBirthDateErrorText("Birth Date is required.");
      isValid = false;
    } else {
      const age = calculateAge(birthDate);
      if (age < 18) {
        setBirthDateErr(true);
        setBirthDateErrorText("You must be at least 18 years old.");
        isValid = false;
      } else {
        setBirthDateErr(false);
      }
    }

    if (_.isEmpty(address)) {
      setAddressErrorText("Address is required");
      isValid = false;
    }

    if (isValid) {
      updatePersonalProfileData();
    }
  };

  const formattedBirthDate = (data) => {
    const birthDateStr = data?.birth_date;

    if (!birthDateStr) return "";

    const dateObj = new Date(birthDateStr);

    if (isNaN(dateObj.getTime())) {
      return "";
    }

    const day = dateObj.getDate().toString().padStart(2, "0");
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObj.getFullYear();

    return `${month}/${day}/${year}`;
  };
  // set data
  const setData = (data) => {
    const firstName =
      !_.isEmpty(data?.firstname) &&
      !_.isNull(data?.firstname) &&
      !_.isUndefined(data?.firstname)
        ? data?.firstname
        : "";
    const lastName =
      !_.isEmpty(data?.lastname) &&
      !_.isNull(data?.lastname) &&
      !_.isUndefined(data?.lastname)
        ? data?.lastname
        : "";
    const nickName =
      !_.isEmpty(data?.nick_name) &&
      !_.isNull(data?.nick_name) &&
      !_.isUndefined(data?.nick_name)
        ? data?.nick_name
        : "";
    const email =
      !_.isEmpty(data?.email) &&
      !_.isNull(data?.email) &&
      !_.isUndefined(data?.email)
        ? data?.email
        : "";
    const phoneNumber =
      !_.isEmpty(String(data?.phone)) &&
      !_.isNull(data?.phone) &&
      !_.isUndefined(data?.phone)
        ? data?.phone
        : "";
    const birthDate =
      !_.isEmpty(String(data?.birth_date)) &&
      !_.isNull(data?.birth_date) &&
      !_.isUndefined(data?.birth_date)
        ? dayjs(data?.birth_date)
        : "";
    const showBirthDate =
      !_.isEmpty(String(data?.birth_date)) &&
      !_.isNull(data?.birth_date) &&
      !_.isUndefined(data?.birth_date)
        ? formattedBirthDate(data)
        : "";

    const address =
      !_.isEmpty(String(data?.address)) &&
      !_.isNull(data?.address) &&
      !_.isUndefined(data?.address)
        ? data?.address
        : "";

    const profilePic =
      !_.isEmpty(String(data?.profile_pic)) &&
      !_.isNull(data?.profile_pic) &&
      !_.isUndefined(data?.profile_pic)
        ? data?.profile_pic
        : "";

    setFirstName(firstName);
    setLastName(lastName);
    setNickName(nickName);
    setEmail(email);
    setPhoneNumber(phoneNumber);
    setBirthDate(birthDate);
    setshowBirthDate(showBirthDate);
    setAddress(address);
    setPageLoader(false);
    setProfileImage(profilePic);
  };

  // upload image
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      setProfileImage(file);
      setImageObject(file);
    } else {
      toast("Only files with these extensions are allowed: png, jpg, jpeg.", {
        type: "error",
      });
    }
  };

  // get user data
  const getCommonData = async () => {
    setPageLoader(true);
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: userData?.email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setPersonalInfoData(resp?.data);
          setData(resp?.data);
          dispatch(setUserData(resp?.data));
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setPageLoader(false);
      }
      setUpdateBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPageLoader(false);
      setUpdateBtnLoader(false);
    }
  };

  const updatePersonalProfileData = async () => {
    setUpdateBtnLoader(true);
    let endPoints = Setting.endpoints.userProfile;
    const toDate = !_.isNull(birthDate)
      ? dayjs(birthDate).format("YYYY-MM-DD")
      : "";
    const params = {
      "SignupForm[firstname]": firstName,
      "SignupForm[lastname]": lastName,
      "SignupForm[email]": userData?.email,
      "SignupForm[nick_name]": nickName,
      "SignupForm[phone_code]": "+1",
      "SignupForm[phone]": phoneNumber,
      "SignupForm[profile_pic]": imageObject,
      "SignupForm[birthdate]": toDate,
      "SignupForm[address]": address,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        getCommonData();
        getUserData();
        toast(resp?.message, {
          type: "success",
        });
        setEdit(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setUpdateBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setUpdateBtnLoader(false);
    }
  };

  return (
    <Grid container style={{ height: "100%" }}>
      {pageLoader ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <Grid item xs={12} style={{ height: "100%" }}>
          <Grid container height={"100%"}>
            <Grid container height={"100%"}>
              <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
              <Grid item xs={11} sm={11.5} md={11} lg={11}>
                <Grid container height={"100%"}>
                  <Grid container height={"90%"}>
                    <Grid item xs={12}>
                      <Grid item xs={12} mt={"20px"}>
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography
                            style={{
                              color: BaseColor.blackColor,
                              fontFamily: FontFamily.GilbertQualifiDemo,
                              fontSize: 22,
                              marginBottom: "10px",
                            }}
                          >
                            Personal
                          </Typography>
                          <CIcon
                            src={Files.svgIcons.editGreen}
                            size={25}
                            onClick={() => {
                              setEdit(!edit);
                            }}
                            style={{
                              cursor: !isMobile && !isTablet && "pointer",
                            }}
                          />
                        </Grid>
                        <hr
                          style={{
                            // width: "100%",
                            borderTop: "1px solid white",
                            marginBottom: 16,
                          }}
                        />
                      </Grid>
                      <motion.div
                        initial={{ opacity: 0, y: 50 }} // Start with the element lower and transparent
                        animate={{ opacity: 1, y: 0 }} // Animate to fully opaque and original position
                        exit={{ opacity: 0, y: 50 }} // Fade out and move down
                        transition={{ duration: 0.3 }} // Duration in seconds
                      >
                        <Grid container display="flex" justifyContent="center">
                          <div className={classes.createProfileContainer}>
                            <div
                              className={classes.createProfileImageContainer}
                            >
                              {profileImage ? (
                                <img
                                  src={
                                    _.isObject(profileImage)
                                      ? URL.createObjectURL(profileImage)
                                      : profileImage
                                  }
                                  alt="Profile"
                                  className={classes.createProfileImage}
                                  draggable={false}
                                />
                              ) : (
                                <img
                                  src={Files.svgIcons.userIcon}
                                  alt="uploadImage"
                                  className={
                                    classes.createProfilePlaceholderImg
                                  }
                                  draggable={false}
                                />
                              )}
                            </div>

                            {edit && (
                              <label style={{ position: "relative" }}>
                                <CButton cameraIcon onClick={() => {}} />
                                <input
                                  className={classes.createProfileContainer}
                                  type="file"
                                  accept="image/*"
                                  id="inputTag"
                                  onChange={(e) => {
                                    handleImageUpload(e);
                                  }}
                                  style={{
                                    display: "none",
                                  }}
                                />
                              </label>
                            )}
                          </div>
                          {profileImageErr && (
                            <Typography
                              variant="body2"
                              color="error"
                              align="center"
                            >
                              Please upload a profile image.
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12} mt={"20px"}>
                          <Grid container>
                            <Grid item xs={12}>
                              <Grid
                                container
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Grid
                                  item
                                  xs={5.8}
                                  sm={5.8}
                                  md={5.8}
                                  lg={5.8}
                                  mb={!edit && "10px"}
                                >
                                  {edit ? (
                                    <CInput
                                      value={firstName}
                                      placeholder={"Enter first name"}
                                      label={"First Name"}
                                      onChange={(val) => {
                                        setFirstName(val);
                                        setFirstNameErrorText("");
                                      }}
                                      error={!_.isEmpty(firstNameErrorText)}
                                      errorMsg={firstNameErrorText}
                                    />
                                  ) : (
                                    <>
                                      <Typography className={classes.inputTile}>
                                        First Name
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {firstName}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={5.8}
                                  sm={5.8}
                                  md={5.8}
                                  lg={5.8}
                                  mb={!edit && "10px"}
                                >
                                  {edit ? (
                                    <CInput
                                      value={lastName}
                                      placeholder={"Enter last name"}
                                      label={"Last Name"}
                                      onChange={(val) => {
                                        setLastName(val);
                                        setLastNameErrorText("");
                                      }}
                                      error={!_.isEmpty(lastNameErrorText)}
                                      errorMsg={lastNameErrorText}
                                    />
                                  ) : (
                                    <>
                                      <Typography className={classes.inputTile}>
                                        Last Name
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {lastName}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Grid
                                  item
                                  xs={5.8}
                                  sm={5.8}
                                  md={5.8}
                                  lg={5.8}
                                  mb={!edit && "10px"}
                                >
                                  {edit ? (
                                    <CInput
                                      value={nickName}
                                      label={"Username"}
                                      placeholder={"Enter username"}
                                      onChange={(val) => {
                                        setNickName(val);
                                        setNickNameErrorText("");
                                      }}
                                      error={!_.isEmpty(nickNameErrorText)}
                                      errorMsg={nickNameErrorText}
                                    />
                                  ) : (
                                    <>
                                      <Typography className={classes.inputTile}>
                                        Username
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {nickName}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={5.8}
                                  sm={5.8}
                                  md={5.8}
                                  lg={5.8}
                                  mb={!edit && "10px"}
                                >
                                  {edit ? (
                                    <CInput
                                      value={email}
                                      placeholder={"Enter email"}
                                      label={"Email ID"}
                                      onChange={(val) => {
                                        setEmail(val);
                                        setEmailErrorText("");
                                      }}
                                      error={!_.isEmpty(emailErrorText)}
                                      errorMsg={emailErrorText}
                                      disabled={true}
                                      readOnly={true}
                                    />
                                  ) : (
                                    <>
                                      <Typography className={classes.inputTile}>
                                        Email ID
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {email}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                display={"flex"}
                                justifyContent={"space-between"}
                              >
                                <Grid
                                  item
                                  xs={5.8}
                                  sm={5.8}
                                  md={5.8}
                                  lg={5.8}
                                  mb={!edit && "10px"}
                                >
                                  {edit ? (
                                    <CInput
                                      value={phoneNumber}
                                      label={"Phone Number"}
                                      placeholder={"Enter phone number"}
                                      onChange={(val) => {
                                        setPhoneNumber(val);
                                        setPhoneNumberErrorText("");
                                      }}
                                      error={!_.isEmpty(phoneNumberErrorText)}
                                      errorMsg={phoneNumberErrorText}
                                    />
                                  ) : (
                                    <>
                                      <Typography className={classes.inputTile}>
                                        Phone Number
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {phoneNumber}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                                <Grid
                                  item
                                  xs={5.8}
                                  sm={5.8}
                                  md={5.8}
                                  lg={5.8}
                                  mb={!edit && "10px"}
                                >
                                  {edit ? (
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <Typography
                                        sx={{
                                          color: "black",
                                          fontFamily: FontFamily.SemiBold,
                                          fontSize: 16,
                                          marginTop: 2,
                                          marginBottom: "10px",
                                        }}
                                      >
                                        {"Birth Date"}
                                      </Typography>
                                      <DesktopDatePicker
                                        sx={{
                                          width: "100%",
                                        }}
                                        disableFuture
                                        views={["year", "month", "day"]}
                                        value={birthDate}
                                        onChange={(val) => {
                                          setBirthDate(val);
                                          setBirthDateErr(false);
                                          setBirthDateErrorText("");
                                        }}
                                        open={calendarOpen} // Use the state to control the calendar open/close
                                        onClose={() => setCalendarOpen(false)} // Close the calendar
                                        slotProps={{
                                          textField: {
                                            // readOnly: true,
                                            inputProps: {
                                              style: {
                                                color: birthDateErr
                                                  ? "red"
                                                  : "inherit",
                                              },
                                            },
                                            // onClick: () =>
                                            //   setCalendarOpen(true), // Open the calendar when input is clicked
                                            sx: {
                                              width: "100%",
                                              "& .MuiOutlinedInput-root": {
                                                "& fieldset": {
                                                  borderColor: birthDateErr
                                                    ? "red"
                                                    : "default",
                                                },
                                                "&:hover fieldset": {
                                                  borderColor: birthDateErr
                                                    ? "red"
                                                    : "default",
                                                },
                                                "&.Mui-focused fieldset": {
                                                  borderColor: birthDateErr
                                                    ? "red"
                                                    : "default",
                                                },
                                              },
                                            },
                                          },
                                        }}
                                        onOpen={() => setCalendarOpen(true)}
                                      />

                                      {birthDateErr && (
                                        <div
                                          style={{
                                            marginTop: 6,
                                            marginLeft: 3,
                                            fontSize: 14,
                                            color: BaseColor.errorRed,
                                            fontFamily: FontFamily.Medium,
                                          }}
                                        >
                                          {birthDateErrorText}
                                        </div>
                                      )}
                                    </LocalizationProvider>
                                  ) : (
                                    <>
                                      <Typography className={classes.inputTile}>
                                        Birth Date
                                      </Typography>
                                      <Typography
                                        className={classes.inputValue}
                                      >
                                        {showBirthDate}
                                      </Typography>
                                    </>
                                  )}
                                </Grid>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} mb={"20px"}>
                                {edit ? (
                                  <>
                                    {/* <CInput
                                  label={"Address"}
                                  placeholder={"Enter address"}
                                  value={address}
                                  onChange={(val) => {
                                    setAddress(val);
                                    setAddressErrorText("");
                                  }}
                                  error={!_.isEmpty(AddressErrorText)}
                                  errorMsg={AddressErrorText}
                                /> */}
                                    <PlacesAutocomplete
                                      onChange={(val) => {
                                        setAddress(val?.location);
                                        setAddressErrorText("");
                                      }}
                                      value={address}
                                      error={!_.isEmpty(AddressErrorText)}
                                      errorMsg={AddressErrorText}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <Typography className={classes.inputTile}>
                                      Address
                                    </Typography>
                                    <Typography className={classes.inputValue}>
                                      {address}
                                    </Typography>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </motion.div>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    height={"10%"}
                    style={{
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: BaseColor.white,
                      zIndex: 100,
                    }}
                  >
                    {edit && (
                      <Grid item xs={12} display={"flex"} alignItems={"center"}>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: md ? "space-between" : "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <CButton
                            variant={md ? "outlined" : "textPrimary"}
                            btnStyle={{ width: "25%" }}
                            onClick={() => {
                              setEdit(false);
                              setData(personalInfoData);
                            }}
                          >
                            Cancel
                          </CButton>
                          <CButton
                            variant="contained"
                            btnStyle={{ width: "25%", marginLeft: "10px" }}
                            onClick={() => {
                              validation();
                            }}
                            loading={updateBtnLoader}
                          >
                            Update
                          </CButton>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ProfilePersonalInfo;
