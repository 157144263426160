import {
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import styles from "./styles";
import Files from "../../Config/Files";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import { isMobile, isTablet } from "react-device-detect";
import { motion } from "framer-motion";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import EditNoteIcon from "@mui/icons-material/EditNote";

import { useSelector } from "react-redux";

const CReservationCard = (props) => {
  // style and media queries
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  // data from redux
  const { userData } = useSelector((state) => state.auth);
  const {
    item,
    name,
    time,
    host,
    style,
    onClick = () => {},
    editResertvation = () => {},
    image,
    hostTitle,
    onChatClick,
    onCardClick = () => {},
  } = props;
  const checkPaymentStatus = item?.reservation_member_data?.every(
    (obj) => obj.payment_original_status === "Completed"
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      transition={{ duration: 0.4 }}
      onClick={() => {
        onCardClick();
      }}
      style={{ cursor: !isMobile && !isTablet && "pointer" }}
    >
      <Grid container>
        <Card
          className={classes.card}
          style={{ ...style, padding: md && "5px" }}
        >
          <div style={{ position: "relative" }}>
            <img
              src={image}
              width={md ? 73 : 100}
              height={md ? 86 : 114}
              alt="reservation"
              className={classes.image}
              draggable={false}
            />
            {Number(userData?.id) === Number(item?.user_id) &&
              !checkPaymentStatus && (
                <div
                  onClick={(e) => {
                    editResertvation(item);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    width: 25,
                    height: 25,
                    borderRadius: 15,
                    zIndex: 11,
                    backgroundColor: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: !isMobile && !isTablet && "pointer",
                  }}
                >
                  <EditNoteIcon style={{ fontSize: 18 }} />
                </div>
              )}
          </div>
          <CardContent
            className={classes.content}
            style={{ padding: md && "10px" }}
          >
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Typography className={classes.title}>{name}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.time}>{time}</Typography>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.host}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.Medium,
                        fontSize: md ? "13px" : "16px",
                        color: BaseColor.textColor,
                      }}
                    >
                      {hostTitle}
                    </Typography>
                    <Typography
                      style={{
                        fontFamily: FontFamily.Medium,
                        fontSize: md ? "13px" : "16px",
                        color: BaseColor.grey,
                        marginLeft: 5,
                        textOverflow: "ellipsis",
                        WebkitLineClamp: 1,
                        overflow: "hidden",
                        WebkitBoxOrient: "vertical",
                        display: "-webkit-box",
                        userSelect: "none",
                      }}
                    >
                      {host}
                    </Typography>
                  </div>
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      paddingLeft: "2px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#EDF1F2",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: isMobile ? "25px" : "35px",
                        width: isMobile ? "25px" : "35px",

                        marginRight: 15,
                        cursor: !isMobile && !isTablet && "pointer",
                      }}
                      onClick={onClick}
                    >
                      <img
                        alt="calendar"
                        src={Files.Images.openEye}
                        style={{
                          height: isMobile ? "15px" : "18.64px",
                          width: isMobile ? "15px" : "18.64px",
                          cursor: !isMobile && !isTablet && "pointer",
                        }}
                        draggable={false}
                      />
                    </div>
                    <div
                      style={{
                        backgroundColor: "#EDF1F2",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: isMobile ? "25px" : "35px",
                        width: isMobile ? "25px" : "35px",
                        cursor: !isMobile && !isTablet && "pointer",
                      }}
                      onClick={onChatClick}
                    >
                      <IoChatbubbleEllipsesOutline
                        color={BaseColor.primary}
                        style={{
                          height: "18.64px",
                          width: "18.64px",
                          cursor: !isMobile && !isTablet && "pointer",
                        }}
                        draggable={false}
                      />
                    </div>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </motion.div>
  );
};

export default CReservationCard;
