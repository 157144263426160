import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BaseColor from "../../Config/colors";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import theme, { FontFamily } from "../../Config/theme";
import CHeader from "../../Components/Header";
import styles from "./styles";
import Files from "../../Config/Files";
import _ from "lodash";
import GolfOtherDetails from "../GolfOtherDetails";
import { isMobile, isTablet, isIOS } from "react-device-detect";
import CStepper from "../../Components/CStepper";
import ClubMembership from "../ClubMembership";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import dayjs from "dayjs";
import PlacesAutocomplete from "../../Components/PlaceAutoComplete";
import useViewportHeight from "./useViewportHeight";

// export default NewMemberForm;
const NewMember = (props) => {
  const classes = styles();
  const clubMembershipRef = useRef(null);
  const golfOtherDetailsRef = useRef(null);
  const location = useLocation();
  const data = location?.state?.data;
  const signUpData = useSelector((state) => state.auth.signUpData);
  const userData = useSelector((state) => state.auth.userData);

  // stepper
  const [activeStep, setActiveStep] = useState(1);

  const NextBtnRef = useRef(null);
  const scrollRef = useRef(null);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const steps = ["Personal Details", "Golf Clubs", "Golf Other Details"];

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [nickName, setNickName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [birthDate, setBirthDate] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [address, setAddress] = useState("");
  const [profileImage, setProfileImage] = useState(null);
  const [imageObject, setImageObject] = useState(null);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [firstNameErr2, setFirstNameErr2] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [lastNameErr2, setLastNameErr2] = useState(false);
  const [nickNameErr, setNickNameErr] = useState(false);
  const [nickNameCharErr, setNickNameCharErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);
  const [phoneErrMsg, setPhoneErrMsg] = useState("");
  const [birthDateErr, setBirthDateErr] = useState(false);
  const [birthDateErrMsg, setBirthDateErrMsg] = useState("");
  const [addressErr, setAddressErr] = useState(false);
  //loader
  const [loader, setLoader] = useState(false);
  const [clubLoader, setClubLoader] = useState(false);
  const [otherDetailLoader, setOtherDetailLoader] = useState(false);
  const [goLoader, setGoLoader] = useState(false);

  useViewportHeight();

  useEffect(() => {
    if (!_.isEmpty(data)) {
      if (data?.signup_pending) {
        setActiveStep(1);
      } else if (_.isEmpty(data?.club_data) && _.isArray(data?.club_data)) {
        setActiveStep(2);
      } else if (
        _.isEmpty(data?.other_details) &&
        _.isArray(data?.other_details)
      ) {
        setActiveStep(3);
      } else {
        setActiveStep(1);
      }
    }
    getCommonData();
    setTimeout(() => {
      handleStep2Goback();
    }, 200);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const validation = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10,14}$/;
    let isValid = true;

    let firstNameError = false;
    let lastNameError = false;
    let userNameError = false;
    let emailError = false;
    let phoneNumberError = false;
    let birthDateError = false;
    let addressError = false;

    // Utility function to calculate age
    const calculateAge = (birthDate) => {
      const today = dayjs();
      const birthDateDayjs = dayjs(birthDate);
      return today.diff(birthDateDayjs, "year");
    };

    if (!firstName || firstName.trim() === "") {
      setFirstNameErr(true);
      firstNameError = true;
      scrollRef?.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      isValid = false;
    } else if (!/^[a-zA-Z]+$/.test(firstName) || firstName.length < 3) {
      setFirstNameErr2(true);
      firstNameError = true;
      scrollRef?.current?.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      isValid = false;
    } else {
      setFirstNameErr(false);
      setFirstNameErr2(false);
    }
    if (!lastName || lastName.trim() === "") {
      setLastNameErr(true);
      lastNameError = true;
      if (!firstNameError)
        scrollRef?.current?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      isValid = false;
    } else if (!/^[a-zA-Z]+$/.test(lastName) || lastName.length < 3) {
      setLastNameErr2(true);
      lastNameError = true;
      if (!firstNameError) {
        scrollRef?.current?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      isValid = false;
    } else {
      setLastNameErr(false);
      setLastNameErr2(false);
    }

    if (!nickName || nickName.trim() === "") {
      setNickNameErr(true);
      isValid = false;
      userNameError = true;
      if (!firstNameError && !lastNameError) {
        scrollRef?.current?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else if (nickName.length < 1) {
      setNickNameErr(true);
      isValid = false;
      userNameError = true;
      if (!firstNameError && !lastNameError) {
        scrollRef?.current?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else if (nickName.length < 2) {
      setNickNameCharErr(true);
      setNickNameErr(false);
      isValid = false;
      userNameError = true;
      if (!firstNameError && !lastNameError) {
        scrollRef?.current?.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } else {
      setNickNameErr(false);
      setNickNameCharErr(false);
    }

    if (email?.trim() === "") {
      setEmailErr(true);
      isValid = false;
      emailError = true;
      if (!firstNameError && !lastNameError && !userNameError) {
        scrollRef?.current?.scrollTo({
          top: 100,
          behavior: "smooth",
        });
      }
    } else if (!emailRegex.test(email)) {
      setEmailErr(true);
      isValid = false;
      emailError = true;
      if (!firstNameError && !lastNameError && !userNameError) {
        scrollRef?.current?.scrollTo({
          top: 100,
          behavior: "smooth",
        });
      }
    } else {
      setEmailErr(false);
    }

    if (!phone || phone.trim() === "") {
      setPhoneErr(true);
      setPhoneErrMsg("Please enter phone number.");
      isValid = false;
      phoneNumberError = true;
      if (!firstNameError && !lastNameError && !userNameError && !emailError) {
        scrollRef?.current?.scrollTo({
          top: 400,
          behavior: "smooth",
        });
      }
    } else if (!phoneRegex.test(phone)) {
      setPhoneErr(true);
      setPhoneErrMsg("Please enter a valid phone number.");
      isValid = false;
      phoneNumberError = true;
      if (!firstNameError && !lastNameError && !userNameError && !emailError) {
        scrollRef?.current?.scrollTo({
          top: 400,
          behavior: "smooth",
        });
      }
    } else {
      setPhoneErr(false);
      setPhoneErrMsg("");
    }

    if (!birthDate) {
      setBirthDateErr(true);
      setBirthDateErrMsg("Birth Date is required.");
      isValid = false;
      birthDateError = true;
      if (
        !firstNameError &&
        !lastNameError &&
        !userNameError &&
        !emailError &&
        !phoneNumberError
      ) {
        scrollRef?.current?.scrollTo({
          top: 500,
          behavior: "smooth",
        });
      }
    } else {
      const age = calculateAge(birthDate);
      if (age < 18) {
        setBirthDateErr(true);
        setBirthDateErrMsg("You must be at least 18 years old.");
        isValid = false;
        birthDateError = true;
        if (
          !firstNameError &&
          !lastNameError &&
          !userNameError &&
          !emailError &&
          !phoneNumberError
        ) {
          scrollRef?.current?.scrollTo({
            top: 500,
            behavior: "smooth", // This makes the scroll smooth
          });
        }
      } else {
        setBirthDateErr(false);
      }
    }

    if (!address) {
      setAddressErr(true);
      isValid = false;
      addressError = true;
      if (
        !firstNameError &&
        !lastNameError &&
        !userNameError &&
        !emailError &&
        !phoneNumberError &&
        !birthDateError
      ) {
        scrollRef?.current?.scrollTo({
          top: 500,
          behavior: "smooth", // This makes the scroll smooth
        });
      }
    } else {
      setAddressErr(false);
    }

    return isValid;
  };

  const getPersonalDetails = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.signup;

    const toDate = !_.isNull(birthDate)
      ? dayjs(birthDate).format("YYYY-MM-DD")
      : "";

    const params = {
      "SignupForm[firstname]": firstName,
      "SignupForm[lastname]": lastName,
      "SignupForm[email]": email || signUpData?.email,
      "SignupForm[nick_name]": nickName,
      "SignupForm[phone_code]": "+1",
      "SignupForm[phone]": phone,
      "SignupForm[profile_pic]": imageObject === null ? "" : imageObject,
      "SignupForm[birthdate]": toDate,
      "SignupForm[address]": address,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.success) {
        setActiveStep(activeStep + 1);
        setEmail("");
        setFirstName("");
        setLastName("");
        setPhone("");
        setBirthDate(null);
        setAddress("");
        setNickName("");
        setProfileImage(null);
        setTimeout(() => {
          clubMembershipRef.current.commonData();
        }, 200);
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      setLoader(false);
    }
  };

  const getCommonData = async () => {
    setGoLoader(true);
    let endPoints = Setting.endpoints.commonData;

    const params = {
      email: !_.isEmpty(data)
        ? data.user_data.email
        : signUpData.email || userData?.user_data?.email,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setEmail(resp?.data?.email);
          setFirstName(resp?.data?.firstname);
          setLastName(resp?.data?.lastname);
          setPhone(resp?.data?.phone);
          setNickName(resp?.data?.nick_name);
          if (resp?.data?.birth_date) {
            const birthDateObj = dayjs(resp?.data?.birth_date);
            setBirthDate(birthDateObj);
          }
          setAddress(resp?.data?.address);
          setProfileImage(resp?.data?.profile_pic);
        }
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
      }
      setGoLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      setGoLoader(false);
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      // Create object URL for the selected file
      const imageUrl = URL.createObjectURL(file);
      // Set the profile image using the object URL
      setProfileImage(imageUrl);
      setImageObject(file);
    } else {
      toast("Only files with these extensions are allowed: png, jpg, jpeg.", {
        type: "error",
      });
    }
  };

  const handleNicknameChange = (value) => {
    setNickName(value);
    setNickNameErr(false);
    setNickNameCharErr(false);
  };

  const handleSubmit = () => {
    const isFormValid = validation();

    if (isFormValid) {
      // Proceed with form submission
      getPersonalDetails();
    }
  };

  const Sidebar = () => {
    const sm = useMediaQuery(theme.breakpoints.down("sm"));
    const lg = useMediaQuery(theme.breakpoints.down("lg"));
    return (
      <Grid
        container
        style={{
          backgroundColor: "#002147",
          height: "100%",
          padding: sm ? 2 : 4,
          overflowY: "auto",
          position: "relative",
        }}
        className={classes.scrollBar}
      >
        {!isMobile && (
          <img
            src={Files.Images.rectangle}
            draggable={false}
            alt="Group1"
            style={{
              position: "absolute",
              right: 0,
              width: "85%",
              height: "70%",
              bottom: "0px",
              zIndex: 1,
            }}
          />
        )}
        <Grid item xs={12} mb={"10px"}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: 20,
            }}
          >
            <img
              src={Files.Images.roundLogo}
              alt="Golf"
              style={{ width: 100, borderRadius: 100 }}
              draggable={false}
            />
            <Typography
              sx={{
                fontSize: lg ? 18 : 22,
                fontFamily: FontFamily.GilbertQualifiDemo,
                color: BaseColor.white,
                marginTop: "20px",
              }}
            >
              New member application
            </Typography>
            <div
              style={{
                backgroundColor: BaseColor.white,
                width: "90%",
                height: "1px",
                marginTop: "15px",
                background:
                  "linear-gradient(to right, rgba(0, 0, 0, 0.1), white, rgba(0, 0, 0, 0.1))",
                boxShadow: "0 0 20px 1px rgba(0, 0, 0, 0.1x)",
              }}
            ></div>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "40px",
              position: "relative",
              zIndex: 1,
            }}
          >
            <Grid item xs={2} md={1} lg={1.5} xl={2}></Grid>
            <Grid item xs={8} md={10} lg={9} xl={8}>
              <Grid
                container
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      backgroundColor:
                        activeStep === 1 || activeStep === 2 || activeStep === 3
                          ? BaseColor.white
                          : BaseColor.primary,
                      border: "1px solid white",
                      color:
                        activeStep === 1 || activeStep === 2 || activeStep === 3
                          ? BaseColor.primary
                          : BaseColor.white,
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: FontFamily.Medium,
                    }}
                  >
                    1
                  </div>
                  <Typography
                    style={{
                      color:
                        activeStep === 1 || activeStep === 2 || activeStep === 3
                          ? BaseColor.white
                          : BaseColor.disableText,
                      marginLeft: "15px",
                      fontFamily: FontFamily.Regular,
                      fontSize: lg ? 15 : 18,
                    }}
                  >
                    Personal Details
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      height: "50px",
                      borderLeft: "1.5px dashed white",
                      marginLeft: "20px",
                    }}
                  ></div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      backgroundColor:
                        activeStep === 2 || activeStep === 3
                          ? BaseColor.white
                          : BaseColor.primary,
                      border: "1px solid white",
                      height: "40px",
                      width: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: FontFamily.Medium,
                      color:
                        activeStep === 2 || activeStep === 3
                          ? BaseColor.primary
                          : BaseColor.white,
                    }}
                  >
                    2
                  </div>
                  <Typography
                    style={{
                      marginLeft: "15px",
                      fontFamily: FontFamily.Regular,
                      color:
                        activeStep === 2 || activeStep === 3
                          ? BaseColor.white
                          : BaseColor.disableText,
                      fontSize: lg ? 15 : 18,
                    }}
                  >
                    Golf Clubs
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <div
                    style={{
                      height: "50px",
                      borderLeft: "1.5px dashed white",
                      marginLeft: "20px",
                    }}
                  ></div>
                </Grid>

                <Grid
                  item
                  xs={12}
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                >
                  <div
                    style={{
                      backgroundColor:
                        activeStep === 3 ? BaseColor.white : BaseColor.primary,
                      color:
                        activeStep === 3 ? BaseColor.primary : BaseColor.white,
                      height: "40px",
                      border: "1px solid white",
                      width: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: FontFamily.Medium,
                    }}
                  >
                    3
                  </div>
                  <Typography
                    style={{
                      marginLeft: "15px",
                      fontFamily: FontFamily.Regular,
                      color:
                        activeStep === 3
                          ? BaseColor.white
                          : BaseColor.disableText,
                      fontSize: lg ? 15 : 18,
                    }}
                  >
                    Golf Other Details
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={2} md={1} lg={1.5} xl={2}></Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const handleStep2Validation = async () => {
    const isValid = await clubMembershipRef.current.validateForm();
    if (isValid) {
      clubMembershipRef.current.getAddClub();
    }
  };

  const handleStep2Goback = () => {
    if (clubMembershipRef.current) {
      clubMembershipRef.current.commonData();
    } else {
      console.error("clubMembershipRef is not yet available");
    }
  };

  const handleStep3Validation = () => {
    if (golfOtherDetailsRef.current) {
      golfOtherDetailsRef.current.handleNextClick(true);
    }
  };

  useEffect(() => {
    if (data?.user_data?.email) {
      setEmail(data.user_data.email);
    } else if (signUpData?.email) {
      setEmail(signUpData.email);
    }
  }, []);

  useEffect(() => {
    if (isMobile && isIOS) {
      const handleFocus = (e) => {
        if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
          document.body.style.position = "fixed";
        }
      };

      const handleBlur = (e) => {
        if (e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") {
          document.body.style.position = "relative";
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      };

      window.addEventListener("focusin", handleFocus);
      window.addEventListener("focusout", handleBlur);

      return () => {
        window.removeEventListener("focusin", handleFocus);
        window.removeEventListener("focusout", handleBlur);
      };
    }
  }, []);

  return (
    <Grid
      container
      // height={"100vh"}
      style={{
        position: "relative",
        height:
          isIOS && (isMobile || isTablet)
            ? "calc(var(--vh, 1vh) * 100)"
            : "100vh",
      }}
    >
      <Grid item xs={12}>
        <CHeader />
      </Grid>
      <Grid
        item
        xs={12}
        sm={3}
        className={classes.sidebar}
        height={md ? "calc(100% - 70px)" : "calc(100% - 90px)"}
        style={{ overflowY: "auto", display: (isMobile || isTablet) && "none" }}
      >
        <Sidebar />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={isTablet ? 12 : 9}
        lg={9}
        height={md ? "calc(100% - 70px)" : "calc(100% - 90px)"}
        style={{ position: "relative" }}
      >
        <div style={{ height: "calc(100% + 20px)" }}>
          {activeStep === 1 ? (
            <div style={{ height: sm ? "86vh" : "100%" }}>
              {goLoader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "88%",
                  }}
                >
                  <CircularProgress
                    style={{ color: BaseColor.primary }}
                    size={24}
                  />
                </div>
              ) : (
                <Grid
                  container
                  component="form"
                  style={{
                    overflowY: "auto",
                    height: isMobile || isTablet ? `100%` : "88%",
                    paddingBottom: isMobile && "140px",
                  }}
                  ref={scrollRef}
                  className={classes.scrollBar}
                >
                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
                  <Grid item xs={11} sm={11} md={10.5} lg={10.5}>
                    {(md || isMobile || isTablet) && (
                      <CStepper activeStep={activeStep - 1} steps={steps} />
                    )}
                    <Grid item xs={12} mt={"20px"}>
                      <Typography
                        style={{
                          color: BaseColor.blackColor,
                          fontFamily: FontFamily.GilbertQualifiDemo,
                          fontSize: 22,
                        }}
                      >
                        Personal Details
                      </Typography>
                      <hr
                        style={{
                          // width: "100%",
                          borderTop: "1px solid white",
                          marginBottom: 16,
                        }}
                      />
                      <Grid item xs={12}>
                        <Grid container display="flex" justifyContent="center">
                          <div className={classes.createProfileContainer}>
                            <div
                              className={classes.createProfileImageContainer}
                            >
                              {profileImage ? (
                                <img
                                  src={
                                    profileImage ||
                                    "https://i.ibb.co/D4kB907/Goat-01-1.png"
                                  }
                                  alt="Profile"
                                  className={classes.createProfileImage}
                                  draggable={false}
                                />
                              ) : (
                                <img
                                  src={Files.svgIcons.userIcon}
                                  alt="uploadImage"
                                  className={
                                    classes.createProfilePlaceholderImg
                                  }
                                  draggable={false}
                                />
                              )}
                            </div>

                            <label style={{ position: "relative" }}>
                              <CButton cameraIcon onClick={() => {}} />
                              <input
                                className={classes.createProfileContainer}
                                type="file"
                                accept="image/*"
                                id="inputTag"
                                onChange={(e) => {
                                  handleImageUpload(e);
                                }}
                                style={{
                                  display: "none",
                                }}
                              />
                            </label>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} mt={"30px"}>
                      <Grid container>
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8}>
                            <CInput
                              label="First Name"
                              maxLength={30}
                              value={firstName}
                              onChange={(val) => {
                                setFirstName(val);
                                setFirstNameErr(false);
                                setFirstNameErr2(false);
                              }}
                              placeholder="Enter your first name"
                              error={
                                firstNameErr ? firstNameErr : firstNameErr2
                              }
                              errorMsg={
                                firstNameErr
                                  ? "First Name is required."
                                  : firstNameErr2
                                  ? "Please enter a valid name"
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8}>
                            <CInput
                              label="Last Name"
                              value={lastName}
                              type={"text"}
                              maxLength={30}
                              onChange={(val) => {
                                setLastName(val);
                                setLastNameErr(false);
                                setLastNameErr2(false);
                              }}
                              placeholder="Enter your last name"
                              fullWidth
                              error={lastNameErr ? lastNameErr : lastNameErr2}
                              errorMsg={
                                lastNameErr
                                  ? "Last Name is required."
                                  : lastNameErr2
                                  ? "Please enter a valid last name"
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"space-between"}
                        >
                          <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                            <CInput
                              label="Username"
                              value={nickName}
                              onChange={handleNicknameChange}
                              maxLength={15}
                              placeholder="Enter your username"
                              fullWidth
                              error={nickNameErr || nickNameCharErr}
                              errorMsg={
                                nickNameErr
                                  ? "Username is required."
                                  : nickNameCharErr
                                  ? "Username must be atleast 2 characters"
                                  : ""
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                            <CInput
                              label="Email"
                              value={email}
                              onChange={(val) => {
                                setEmail(val);
                                setEmailErr(false);
                              }}
                              disabled={
                                !_.isEmpty(data?.user_data?.email) ||
                                !_.isEmpty(signUpData.email)
                                  ? true
                                  : false
                              }
                              placeholder="Enter your email"
                              fullWidth
                              error={emailErr}
                              errorMsg={
                                emailErr
                                  ? "Please enter a valid email address."
                                  : ""
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          display={"flex"}
                          justifyContent={"space-between"}
                          // mb={"20px"}
                        >
                          <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                            <CInput
                              label="Phone Number"
                              value={phone}
                              maxLength={10}
                              startIcon={"+1"}
                              onChange={(val) => {
                                // Allow only numeric input
                                const numericValue = val.replace(/[^0-9]/g, "");
                                setPhone(numericValue);
                                setPhoneErr(false);
                                setPhoneErrMsg("");
                              }}
                              placeholder="Enter your phone number"
                              fullWidth
                              error={phoneErr}
                              errorMsg={phoneErrMsg}
                            />
                          </Grid>
                          <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                            {/* <CInput
                              label="Birth Date"
                              value={birthDate}
                              onChange={(val) => {
                                setBirthDate(val);
                                setBirthDateErr(false);
                              }}
                              placeholder={"Select your birth date"}
                              type="date"
                              fullWidth
                              error={birthDateErr}
                              errorMsg={
                                birthDateErr ? "Birth Date is required." : ""
                              }
                            /> */}
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <Typography
                                sx={{
                                  fontFamily: FontFamily.SemiBold,
                                  marginTop: 2,
                                  marginBottom: "10px",
                                  color: BaseColor.textColor,
                                  fontSize: isMobile ? 14 : 16,
                                }}
                              >
                                {"Birth Date"}
                              </Typography>

                              <DesktopDatePicker
                                sx={{
                                  width: "100%",
                                }}
                                disableFuture
                                views={["year", "month", "day"]}
                                value={birthDate}
                                onChange={(val) => {
                                  setBirthDate(val);
                                  setBirthDateErr(false);
                                }}
                                open={calendarOpen} // Use the state to control the calendar open/close
                                onClose={() => setCalendarOpen(false)} // Close the calendar
                                slotProps={{
                                  textField: {
                                    // readOnly: true,
                                    inputProps: {
                                      style: {
                                        color: birthDateErr
                                          ? BaseColor.errorRed
                                          : "inherit",
                                      },
                                    },
                                    // onClick: () => setCalendarOpen(true), // Open the calendar when input is clicked
                                    sx: {
                                      width: "100%",
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                          borderColor: birthDateErr
                                            ? BaseColor.errorRed
                                            : "default",
                                        },
                                        "&:hover fieldset": {
                                          borderColor: birthDateErr
                                            ? BaseColor.errorRed
                                            : "default",
                                        },
                                        "&.Mui-focused fieldset": {
                                          borderColor: birthDateErr
                                            ? BaseColor.errorRed
                                            : "default",
                                        },
                                      },
                                    },
                                  },
                                }}
                                onOpen={() => setCalendarOpen(true)}
                              />

                              {birthDateErr && (
                                <div
                                  style={{
                                    marginTop: 6,
                                    marginLeft: 3,
                                    fontSize: 14,
                                    color: BaseColor.errorRed,
                                    fontFamily: FontFamily.Medium,
                                  }}
                                >
                                  {birthDateErrMsg}
                                </div>
                              )}
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} mb={"20px"}>
                          {/* <CInput
                            label="Address"
                            value={address}
                            maxLength={155}
                            onChange={(val) => {
                              setAddress(val);
                              setAddressErr(false);
                            }}
                            placeholder="Enter your address"
                            fullWidth
                            error={addressErr}
                            errorMsg={addressErr ? "Address is required." : ""}
                          /> */}
                          <PlacesAutocomplete
                            onChange={(val) => {
                              setAddress(val?.location);
                              setAddressErr(false);
                            }}
                            value={address}
                            error={addressErr}
                            errorMsg={addressErr ? "Address is required." : ""}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
                </Grid>
              )}
            </div>
          ) : activeStep === 2 ? (
            <ClubMembership
              ref={clubMembershipRef}
              email={!_.isEmpty(data) && data?.user_data.email}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setClubLoader={setClubLoader}
            />
          ) : (
            <GolfOtherDetails
              ref={golfOtherDetailsRef}
              email={!_.isEmpty(data) && data?.user_data.email}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              setOtherDetailLoader={setOtherDetailLoader}
            />
          )}
        </div>

        <Grid
          ref={NextBtnRef}
          item
          xs={12}
          md={12}
          style={{
            position: "sticky",
            bottom: "0px",
            zIndex: 10,
            backgroundColor: BaseColor.white,
            display: "flex",
            justifyContent: "flex-end",
            height: isMobile || isTablet ? "80px" : "12%",
            boxShadow: "0 0 8px 4px rgb(0 0 0 / 10%)",
            width: "100%",
          }}
        >
          <Grid container>
            {activeStep === 1 ? (
              <>
                <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={10.5}
                  lg={10.5}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <CButton
                    variant="contained"
                    btnStyle={{
                      width: sm ? "100%" : md ? "45%" : "250px",
                    }}
                    onClick={() => {
                      handleSubmit();
                    }}
                    loading={loader}
                  >
                    Next
                  </CButton>
                </Grid>
                <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
              </>
            ) : (
              <>
                <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={10}
                  lg={10.5}
                  display={"flex"}
                  justifyContent={"flex-end"}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: md ? "space-between" : "flex-end",
                    }}
                  >
                    <CButton
                      variant={md ? "outlined" : "textPrimary"}
                      btnStyle={{
                        width: md ? "47%" : "25%",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        if (goLoader) {
                          return null;
                        } else {
                          if (activeStep === 2) {
                            setActiveStep(activeStep - 1);
                            getCommonData();
                          } else if (activeStep === 3) {
                            setActiveStep(activeStep - 1);
                            setTimeout(() => {
                              handleStep2Goback();
                            }, 200);
                          }
                        }
                      }}
                    >
                      Go Back
                    </CButton>
                    <CButton
                      variant="contained"
                      btnStyle={{ width: md ? "47%" : "250px" }}
                      loading={
                        activeStep === 2 ? clubLoader : otherDetailLoader
                      }
                      onClick={() => {
                        if (activeStep === 2) {
                          handleStep2Validation();
                        } else {
                          handleStep3Validation();
                        }
                      }}
                    >
                      {activeStep === 3 ? "Submit" : "Next"}
                    </CButton>
                  </div>
                </Grid>
                <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* )} */}
    </Grid>
  );
};

export default NewMember;
