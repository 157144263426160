import {
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { toast } from "react-toastify";
import theme, { FontFamily } from "../../../Config/theme";
import { Setting } from "../../../Utils/Setting";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import BaseColor from "../../../Config/colors";
import CEventCard from "../../../Components/CEventsCard";
import CIcon from "../../../Components/CIcon";
import Files from "../../../Config/Files";

const EventsComponent = (props) => {
  const {
    getEventList = () => {},
    pageLoader = true,
    eventList = [],
    bottomLoader = false,
    page,
    handlePageLoader = () => {},
  } = props;

  useEffect(() => {
    getReservedEventList();
  }, []);

  // responsive design and styles
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isLaptopSize = useMediaQuery(
    "(min-width: 1024px) and (max-width: 1262px)"
  );
  const classes = styles();

  // navigation
  const navigate = useNavigate();

  //get data from location params
  const location = useLocation();
  const { event_data } = location?.state || "";

  // reserved event list
  const [reservedEvents, setReservedEvents] = useState([]);

  // add and remove bookmarks
  const addAndRemoveBookmark = async (eventId, bookmark, type) => {
    let endPoints = Setting.endpoints.addAndRemoveBookmark;

    const data = {
      "EventManagement[event_id]": eventId,
      "EventManagement[is_event_bookmark]": bookmark ? 1 : 0,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        if (type === "reserved") {
          getReservedEventList();
        } else {
          getEventList(false, page);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      toast(error.message, { type: "error" });
    }
  };

  const getReservedEventList = async () => {
    let endPoints = Setting.endpoints.eventList;

    let data = {};

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          setReservedEvents(resp?.data);
        } else {
          setReservedEvents([]);
        }
        handlePageLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setReservedEvents([]);
        handlePageLoader(false);
      }
    } catch (error) {
      toast(error.message, { type: "error" });
      handlePageLoader(false);
    }
  };

  return (
    <Grid container>
      {pageLoader ? (
        <Grid
          container
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          style={{
            height:
              md || isMobile || isTablet
                ? "calc(100vh - 70px)"
                : "calc(100vh - 110px)",
          }}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <>
          <Grid item xs={12} display={"flex"} alignItems={"center"}>
            {event_data === "event_data" && (
              <CIcon
                src={Files.svgIcons.BackArrowIcon}
                size={30}
                style={{
                  marginLeft: "-4px",
                  cursor: !isMobile && !isTablet && "pointer",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            )}

            <Grid item xs={12} display={"flex"} alignItems={"center"}>
              <Typography className={classes.title}>
                My Reserved Events
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              flexDirection: isMobile || isTablet ? "column" : "row",
              flexWrap: "nowrap",
              paddingLeft: "2px",
              paddingRight: "5px",
              overflow: isLaptopSize ? "auto" : "hidden",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {!_.isEmpty(reservedEvents) ? (
                <Grid
                  container
                  spacing={xs || sm ? 0 : 1}
                  justifyContent={"flex-start"}
                  display={"flex"}
                  rowGap={2}
                  marginTop={xs || sm ? "20px" : "10px"}
                  paddingBottom={"20px"}
                >
                  {reservedEvents?.map((item) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        paddingRight={!sm && "10px"}
                      >
                        <CEventCard
                          name={item.event_name}
                          address={item.location}
                          distance={item.distance}
                          showBookmark={false}
                          image={
                            !_.isArray(item?.event_images)
                              ? item.event_images
                              : item?.event_images[0]
                          }
                          bookMarkClick={() => {
                            addAndRemoveBookmark(
                              item?.event_id,
                              item?.is_event_bookmark == "1" ? false : true,
                              "reserved"
                            );
                          }}
                          isBookMark={
                            item?.is_event_bookmark == "1" ? true : false
                          }
                          onClick={() => {
                            navigate(`/event-details/${item?.event_id}`);
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Grid item xs={12} className={classes.noReservationContainer}>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="https://i.ibb.co/Fwxc4mT/img-5250-720.jpg"
                      alt="No Events"
                      style={{ width: 55, height: 55 }}
                    />
                    <Typography
                      style={{
                        color: BaseColor.grey,
                        fontFamily: FontFamily.Regular,
                      }}
                    >
                      No Reserved Events
                    </Typography>
                  </div>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} display={"flex"} alignItems={"center"}>
            {event_data === "event_data" && (
              <CIcon
                src={Files.svgIcons.BackArrowIcon}
                size={30}
                style={{
                  marginLeft: "-4px",
                  cursor: !isMobile && !isTablet && "pointer",
                }}
                onClick={() => {
                  navigate(-1);
                }}
              />
            )}

            <Grid
              item
              xs={12}
              display={"flex"}
              alignItems={"center"}
              mt={"10px"}
            >
              <Typography className={classes.title}>Upcoming Events</Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              flexDirection: isMobile || isTablet ? "column" : "row",
              flexWrap: "nowrap",
              paddingLeft: "2px",
              paddingRight: "5px",
              overflow: isLaptopSize ? "auto" : "hidden",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {!_.isEmpty(eventList) ? (
                <Grid
                  container
                  spacing={xs || sm ? 0 : 1}
                  justifyContent={"flex-start"}
                  display={"flex"}
                  rowGap={2}
                  marginTop={xs || sm ? "20px" : "10px"}
                  paddingBottom={"20px"}
                >
                  {eventList?.map((item) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        paddingRight={!sm && "10px"}
                      >
                        <CEventCard
                          name={item.event_name}
                          address={item.location}
                          distance={item.distance}
                          image={
                            !_.isArray(item?.event_images)
                              ? item.event_images
                              : item?.event_images[0]
                          }
                          bookMarkClick={() => {
                            addAndRemoveBookmark(
                              item?.id,
                              item?.is_event_bookmark ? false : true
                            );
                          }}
                          isBookMark={item?.is_event_bookmark}
                          onClick={() => {
                            navigate(`/event-details/${item?.id}`);
                          }}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                <Grid
                  container
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      color: BaseColor.blackColor,
                      fontSize: 16,
                    }}
                  >
                    No Data
                  </Typography>
                </Grid>
              )}
              {bottomLoader && (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <CircularProgress size={30} color="primary" />
                </div>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default EventsComponent;
