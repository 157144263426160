import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import useStyles from "./styles.js";
import Files from "../../Config/Files.js";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import authActions from "../../Redux/reducers/auth/actions.js";

export default function SplashScreen() {
  const classes = useStyles();

  const dispatch = useDispatch();

  const { setLoginModal, setSignupModal } = authActions;

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.has("login")) {
      const login = searchParams.get("login");
      if (login == "true") {
        dispatch(setLoginModal(true));
      }
      setSearchParams({}, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (searchParams.has("signup")) {
      const signup = searchParams.get("signup");
      if (signup == "true") {
        dispatch(setSignupModal(true));
      }
      setSearchParams({}, { replace: true });
    }
  }, []);

  return (
    <Grid container className={classes.splashScreen}>
      <img
        src={Files.Images.splashTopBg}
        alt="Group1"
        style={{
          position: "absolute",
          top: "-6px",
          right: 0,
          width: "100%",
          height: "56%",
        }}
        draggable={false}
      />
      <img
        src={Files.Images.splashBottomBg}
        alt="Group2"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          width: "100%",
          height: "30%",
        }}
        draggable={false}
      />
      <Grid item xs={12}>
        <div className={`${classes.loadingContainer} ${classes.logoAnimation}`}>
          <img
            src={Files.svgIcons.appLogo}
            alt="logo"
            className={classes.image}
            draggable={false}
          />
        </div>
      </Grid>
    </Grid>
  );
}
