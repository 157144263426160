import React, { useEffect, useState } from "react";
import styles from "./styles";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BaseColor from "../../../../Config/colors";
import { getAPIProgressData } from "../../../../Utils/APIHelper";
import { toast } from "react-toastify";
import _ from "lodash";
import { Setting } from "../../../../Utils/Setting";
import { motion } from "framer-motion";
import CButton from "../../../../Components/CButton";
import theme, { FontFamily } from "../../../../Config/theme";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";

const Subscription = () => {
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  // loader
  const [pageLoader, setPageLoader] = useState(false);

  // set data of active plan in state
  const [activePlan, setActivePlan] = useState({});

  useEffect(() => {
    getSubscriptionData();
  }, []);

  // get user data
  const getSubscriptionData = async () => {
    setPageLoader(true);
    let endPoints = Setting.endpoints.subscriptionList;

    try {
      const resp = await getAPIProgressData(endPoints, "POST", {}, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setActivePlan(resp?.data);
        }
        setPageLoader(false);
      } else {
        toast(resp?.message, {
          type: "error",
        });
        setPageLoader(false);
      }
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setPageLoader(false);
    }
  };

  // Function to convert Unix timestamp to a readable date format
  const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  // Function to calculate time left
  const calculateTimeLeft = (endDateTimestamp) => {
    const currentDate = new Date();
    const endDate = new Date(endDateTimestamp * 1000); // Convert seconds to milliseconds
    const differenceInMs = endDate - currentDate;

    const daysLeft = Math.floor(differenceInMs / (1000 * 60 * 60 * 24));
    if (daysLeft > 30) {
      const monthsLeft = Math.floor(daysLeft / 30);
      return `${monthsLeft} month${monthsLeft > 1 ? "s" : ""}`;
    } else {
      return `${daysLeft} day${daysLeft > 1 ? "s" : ""}`;
    }
  };

  // Convert Unix timestamps to readable date format
  const formattedStartDate = convertTimestampToDate(activePlan?.start_date);
  const formattedEndDate = convertTimestampToDate(activePlan?.end_date);
  const timeLeft = calculateTimeLeft(activePlan?.end_date);

  return (
    <>
      {pageLoader ? (
        <Grid
          container
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"calc(100vh - 90px)"}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : (
        <>
          {isMobile || isTablet || md ? (
            <div className={classes.headerContainer}>
              <Grid
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ArrowBack className={classes.backButton} />
              </Grid>
              <Typography className={classes.headerTitle}>
                Subscription
              </Typography>
              <Grid xs={0.5} sm={0.5}></Grid>
            </div>
          ) : null}
          {!_.isEmpty(activePlan) ? (
            <Grid
              container
              className={classes.container}
              justifyContent={isMobile || isTablet ? "center" : ""}
            >
              <Grid item xs={12} md={8}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card
                    className={classes.card}
                    style={{
                      userSelect: "none",
                      backgroundColor: "#1e1e2d", // Dark background similar to the design
                      borderRadius: "16px", // Rounded corners
                      boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)", // Shadow for depth
                      width: isTablet ? "90%" : isMobile ? "80%" : "60%",
                    }}
                  >
                    <CardContent>
                      <Typography style={{ fontSize: "25px" }} color={"#fff"}>
                        Active Plan
                      </Typography>
                      <Typography className={classes.subscriptionInfo}>
                        Plan Name: {activePlan?.plan_name}
                      </Typography>
                      {/* <Typography className={classes.subscriptionInfo}>
                        Start Date: {formattedStartDate}
                      </Typography> */}
                      <Typography className={classes.subscriptionInfo}>
                        Valid Till: {formattedEndDate}
                      </Typography>
                      <Typography className={classes.subscriptionInfo}>
                        Time Left: {timeLeft}
                      </Typography>
                      <Box className={classes.priceContainer}>
                        <Typography
                          style={{ fontSize: "25px" }}
                          color={"#fff"}
                          className={classes.price}
                        >
                          ${activePlan?.price}
                        </Typography>
                        {activePlan?.plan_type !== "initial" && (
                          <Typography
                            style={{
                              color: "#fff",
                              fontFamily: FontFamily.Medium,
                              fontSize: "15px",
                              marginLeft: "5px",
                            }}
                          >
                            per {activePlan?.plan_duration}
                          </Typography>
                        )}
                      </Box>
                      <Typography className={classes.subscriptionInfo}>
                        Status:{" "}
                        <span
                          className={classes.subscriptionStatus}
                          style={{
                            color:
                              activePlan?.status === "active"
                                ? "greenyellow"
                                : BaseColor.red,
                          }}
                        >
                          {activePlan?.status === "active"
                            ? "Active"
                            : "Inactive"}
                        </span>
                      </Typography>
                      <Typography className={classes.subscriptionWarning}>
                        {activePlan?.subscription_expire_days === "Expired" ? (
                          <span className={classes.expireText}>
                            Your plan has expired.
                          </span>
                        ) : null}
                      </Typography>
                      {activePlan?.subscription_expire_days === "Expired" && (
                        <Button
                          style={{
                            height: 40,
                            marginTop: 10,
                            fontFamily: FontFamily.Bold,
                          }}
                          onClick={() => {
                            navigate("/subscription-plans");
                          }}
                          variant="outlinedPrimary"
                          sx={{
                            fontSize: "14px",
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          Renew Now
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              className={classes.container}
              justifyContent={isMobile || isTablet ? "center" : ""}
            >
              <Grid item xs={12} md={8}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Card
                    className={classes.card}
                    style={{
                      width: isTablet ? "90%" : isMobile ? "80%" : "60%",
                    }}
                  >
                    <CardContent>
                      <Typography variant="h4" color={"#fff"}>
                        No Active Plan
                      </Typography>
                      <Typography className={classes.subscriptionInfo}>
                        You currently do not have any active subscription plans.
                      </Typography>
                      <CButton
                        className={classes.subscribeButton}
                        onClick={() => {
                          navigate("/subscription-plans");
                        }}
                        variant="outlinedPrimary"
                        sx={{
                          fontSize: "14px",
                          fontFamily: FontFamily.Medium,
                          textAlign: "center",
                          width: "100%",
                        }}
                      >
                        Browse Plans
                      </CButton>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default Subscription;
