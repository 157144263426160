import React, { useEffect, useState } from "react";
import {
  Typography,
  IconButton,
  Grid,
  TextField,
  CircularProgress,
  Badge,
} from "@mui/material";
import BaseColor from "../../Config/colors";
import { useMediaQuery } from "@mui/system";
import CReservationCard from "../../Components/CReservationCard";
import { revSortArray } from "../../Config/staticData";
import theme, { FontFamily } from "../../Config/theme";
import { IoIosArrowRoundBack } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import "react-calendar/dist/Calendar.css"; // Make sure to include the CSS file for styling
import CModal from "../../Components/CModal";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import "./CustomCalendar.css";
import CHeader from "../../Components/Header";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import _ from "lodash";
import { Setting } from "../../Utils/Setting";
import CSelect from "../../CSelect";
import {
  IoChatbubbleEllipsesOutline,
  IoCheckmarkOutline,
  IoCloseOutline,
} from "react-icons/io5";
import { isMobile, isTablet } from "react-device-detect";
import { Clear } from "@mui/icons-material";
import ReservationModel from "../../Components/ReservationModel/ReservationModel";
import authActions from "../../Redux/reducers/auth/actions";
import { useDispatch } from "react-redux";

const ReservationsList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { notificationData } = location.state || {};
  // const searchParams = new URLSearchParams(location.search);
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedMonth, setSelectedMonth] = useState(null); // Initialize with the current date
  const [isCalendarOpen, setIsCalendarOpen] = useState(false); // State to manage calendar visibility
  const [calendarModal, setCalendarModal] = useState(false);
  const [selectedCalendarData, setSelectedCalendarData] = useState({});
  const mobileScreen = useMediaQuery("(max-width:600px)");
  const [showInput, setShowInput] = useState(false);
  const [searchText, setSearchText] = useState("");

  const { setCTab } = authActions;

  const [pagination, setPagination] = useState({});
  const [pageNo, setPageNo] = useState(1);
  const [bottomLoader, setBottomLoader] = useState(false);
  const [showReservationModel, setShowReservationModel] = useState({
    vis: false,
    data: [],
  });
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const reservationDetailString = searchParams.get("reservationDetail");
  const reservationDetail = JSON.parse(reservationDetailString);

  // set params to url
  const selectedFilter = searchParams.has("selected_tab")
    ? searchParams.get("selected_tab")
    : "my_reservation";

  const dispatch = useDispatch();

  const setHeaderData = (tab = "my_reservation") => {
    let params = {};
    if (!_.isEmpty(tab)) {
      params.selected_tab = tab;
    }
    setSearchParams(createSearchParams(params), { replace: true });
  };

  const [selectedTab, setSelectedTab] = useState(selectedFilter);

  const handleSearchClick = () => {
    setShowInput(true);
  };
  const handleSearchClose = () => {
    setShowInput(false);
  };
  const parseDateString = (dateString) => {
    // Define regular expressions to match different parts of the date string
    const dayRegex =
      /(Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday)/;
    const monthRegex = /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/;
    const dayOfMonthRegex = /(\d{1,2})/;

    // Extract day of the week, month, and day of the month
    const [, dayOfWeek] = dateString.match(dayRegex) || [];
    const [, monthStr] = dateString.match(monthRegex) || [];
    const [, dayOfMonthStr] = dateString.match(dayOfMonthRegex) || [];
    if (!dayOfWeek || !monthStr || !dayOfMonthStr) {
      throw new Error("Invalid date format");
    }

    // Map month abbreviations to month numbers
    const monthMap = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    // Convert extracted strings to numbers
    const month = monthMap[monthStr];
    const dayOfMonth = parseInt(dayOfMonthStr);

    // Create a Date object
    const date = new Date();
    date.setDate(dayOfMonth);
    date.setMonth(month);

    // Set the day of the week
    switch (dayOfWeek) {
      case "Sunday":
        date.setDate(date.getDate() + (0 - date.getDay()));
        break;
      case "Monday":
        date.setDate(date.getDate() + (1 - date.getDay()));
        break;
      case "Tuesday":
        date.setDate(date.getDate() + (2 - date.getDay()));
        break;
      case "Wednesday":
        date.setDate(date.getDate() + (3 - date.getDay()));
        break;
      case "Thursday":
        date.setDate(date.getDate() + (4 - date.getDay()));
        break;
      case "Friday":
        date.setDate(date.getDate() + (5 - date.getDay()));
        break;
      case "Saturday":
        date.setDate(date.getDate() + (6 - date.getDay()));
        break;
      default:
        throw new Error("Invalid day of the week");
    }

    return date;
  };

  let dateObject;
  const reservationDayString =
    notificationData?.reservation_data?.reservation_day ||
    reservationDetail?.reservation_day;
  try {
    dateObject = parseDateString(reservationDayString);
  } catch (error) {
    console.error("Error parsing date:", error.message);
  }

  const formattedMonth = notificationData?.reservation_data?.start_date
    ? notificationData?.reservation_data.start_date.slice(0, 7)
    : reservationDetail?.start_date
    ? reservationDetail?.start_date.slice(0, 7)
    : "";

  useEffect(() => {
    if (!_.isEmpty(notificationData) || !_.isEmpty(reservationDetail)) {
      setSelectedCalendarData(
        notificationData?.reservation_data || reservationDetail
      );
      handleCardClick(dateObject, formattedMonth);
    }
  }, []);

  const handleChange = (event) => {
    const inputvalue = event.target.value.trim();
    setSearchText(event.target.value);
    if (inputvalue !== "" || event.target.value.length < 1) {
      getReservationList(
        event.target.value,
        revSort?.name,
        !_.isNull(selectedMonth) ? selectedMonth.toISOString().slice(0, 7) : "",
        false,
        1,
        false,
        selectedFilter
      );
      setPageNo(1);
    } else {
      setReservationArray([]);
    }
  };

  const handleClearClick = () => {
    setSearchText("");
    getReservationList(
      "",
      revSort?.name,
      !_.isNull(selectedMonth) ? selectedMonth.toISOString().slice(0, 7) : "",
      false,
      1,
      false,
      selectedFilter
    );
    setPageNo(1);
  };

  // array to store data
  const [reservationArray, setReservationArray] = useState([]);
  const [revSort, setRevSort] = useState({});

  const handleCardClick = (month, formattedMonth) => {
    setSelectedMonth(month);
    setIsCalendarOpen(true); // Open the calendar when a card is clicked
    getReservationList(
      search,
      revSort?.name,
      formattedMonth,
      true,
      "",
      false,
      selectedFilter
    );
  };

  //page loader
  const [loader, setLoader] = useState(false);
  // search
  const [search, setSearch] = useState("");

  useEffect(() => {
    getReservationList(
      search,
      revSort?.name,
      !_.isNull(selectedMonth) ? selectedMonth.toISOString().slice(0, 7) : "",
      false,
      1,
      false,
      selectedFilter
    );
  }, []);

  const selectedCalendarTime = new Date(
    selectedCalendarData?.start_date + "T" + selectedCalendarData?.start_time
  );
  const formatedselectedCalendarTime = selectedCalendarTime.toLocaleString(
    "en-US",
    {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }
  );

  // Parse the original date string into a Date object
  const originalDate = new Date(selectedCalendarData?.start_date);

  // Define arrays for month names and day names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayNames = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Extract day, month, and year from the Date object
  const day = originalDate.getDate();
  const monthIndex = originalDate.getMonth();
  const year = originalDate.getFullYear();
  const dayIndex = originalDate.getDay();

  // Construct the desired output string
  const outputString = `${dayNames[dayIndex]} ${monthNames[monthIndex]}  ${day} ${year}`;

  // reservation list api call
  const getReservationList = async (
    val,
    filter,
    selectedMonth,
    calBool,
    page = 1,
    bottomLoad = false,
    type = "my_reservation"
  ) => {
    if (!calBool && !bottomLoad) {
      setLoader(true);
    }

    if (bottomLoad) {
      setBottomLoader(true);
    }

    let sortValue = ""; // Initialize sortValue variable

    if (filter === "Sort A to Z") {
      sortValue = "club_name";
    } else if (filter === "Sort Z to A") {
      sortValue = "-club_name";
    } else if (filter === "Newest First") {
      sortValue = "created_at";
    } else if (filter === "Oldest First") {
      sortValue = "-created_at";
    }

    const data = {
      club_name: val ? val : "",
      sort: sortValue,
      month: selectedMonth,
      page: page,
    };

    if (type === "past_reservation") {
      data.pastReservation = 1;
    }

    try {
      const resp = await getAPIProgressData(
        `${Setting.endpoints.reservationList}`,
        "POST",
        data,
        true
      );

      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          if (calBool) {
            setCalendarModal(true);
          } else {
            if (page !== 1) {
              setReservationArray([...reservationArray, ...resp?.data]);
              setBottomLoader(false);
            } else {
              setReservationArray(resp?.data);
              setBottomLoader(false);
            }
            setPagination(resp?.pagination);
            setBottomLoader(false);
          }
        } else {
          setReservationArray([]);
        }
        setBottomLoader(false);
      } else {
        setReservationArray([]);
        setBottomLoader(false);
        // toast.error(resp?.message, {
        //   position: "top-right",
        // });
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
      setLoader(false);
      setBottomLoader(false);
    }
  };

  const handleDayClick = (date) => {
    const dateStr = date;
    const newDate = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    // Find the item in reservationArray that matches the new date
    const selectedItem = reservationArray?.find((item) => {
      // Check if item and start_date exist before accessing them
      return item.start_date === formattedDate;
    });

    // Set the selected data
    setSelectedCalendarData(selectedItem);
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const isDateDisabled = ({ date, view }) => {
    if (view === "month") {
      const isReservedDate =
        reservationArray &&
        reservationArray.find((item) => {
          const reservationDate = new Date(item.start_date);
          return reservationDate.toDateString() === date.toDateString();
        });
      return !isReservedDate;
    } else {
      return false;
    }
  };

  const getTileClassName = ({ date, view }) => {
    // Check if the current date is included in any of the reservation dates
    const isReservedDate =
      reservationArray &&
      reservationArray.find((item) => {
        // Extract YYYY-MM-DD formatted date from the reservation start_date
        const reservationDate = new Date(item.start_date);
        // Compare the date objects
        return reservationDate.toDateString() === date.toDateString();
      });

    if (isReservedDate && view === "month") {
      return `${classes.customTile}`;
    }

    if (
      view === "month" &&
      date.getDate() === selectedMonth.getDate() &&
      date.getMonth() === selectedMonth.getMonth() &&
      date.getFullYear() === selectedMonth.getFullYear()
    ) {
      return `${classes.customSelectedDate} `;
    }
    if (date.getDay() === 0 || date.getDay() === 6) {
      return `${classes.customWeekendDay}`;
    }
    if (isToday(date)) {
      return `${classes.todayDate}`;
    }

    return null;
  };

  const handleScroll = (e) => {
    const container = e?.target;
    const isReachBottom = container?.scrollHeight - container?.scrollTop;
    if (
      !_.isEmpty(reservationArray) &&
      pagination?.isMore &&
      isReachBottom === container.clientHeight &&
      !bottomLoader
    ) {
      const page = pageNo + 1;
      getReservationList(
        search,
        revSort?.name,
        !_.isNull(selectedMonth) ? selectedMonth.toISOString().slice(0, 7) : "",
        false,
        page,
        true,
        selectedFilter
      );
      setPageNo(page);
    }
  };

  return (
    <>
      {md ? (
        <Grid container>
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
          <Grid item xs={11} sm={11} md={10} lg={10} height={"100%"}>
            <Grid marginTop={"20px"}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Grid
                  xs={12}
                  style={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <IoIosArrowRoundBack
                    onClick={() => {
                      navigate("/home");
                    }}
                    style={{
                      fontSize: 40,
                      color: BaseColor.blackColor,
                      cursor: !isMobile && !isTablet && "pointer",
                    }}
                  />

                  <Typography
                    style={{
                      marginLeft: "10px",
                      fontSize: !md ? 25 : 24,
                      color: BaseColor.textColor,
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      textAlign: "center",
                    }}
                  >
                    My Tee Times
                  </Typography>

                  <div>
                    {!_.isEmpty(reservationArray) && (
                      <IconButton>
                        {!showInput ? (
                          <CiSearch
                            style={{
                              color: BaseColor.blackColor,
                              cursor: !isMobile && !isTablet && "pointer",
                              fontSize: 30,
                            }}
                            onClick={handleSearchClick}
                          />
                        ) : (
                          <CloseIcon
                            style={{
                              color: BaseColor.blackColor,
                              cursor: !isMobile && !isTablet && "pointer",
                              fontSize: 30,
                            }}
                            onClick={handleSearchClose}
                          />
                        )}
                      </IconButton>
                    )}
                  </div>
                </Grid>{" "}
                {showInput && (
                  <div>
                    <TextField
                      inputRef={(input) => {
                        if (input != null) {
                          input.focus();
                        }
                      }}
                      fullWidth
                      placeholder="Search"
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                      value={searchText}
                      onChange={handleChange}
                      InputProps={{
                        endAdornment: searchText && ( // Only show clear icon if there's text in the input field
                          <IconButton
                            onClick={handleClearClick}
                            style={{
                              position: "absolute",
                              right: 0,
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            <Clear />
                          </IconButton>
                        ),
                      }}
                    />
                  </div>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={5}
                  display={"fex"}
                  alignItems={"center"}
                  mt={"15px"}
                >
                  <div className={classes.switchDiv}>
                    <div
                      style={{
                        backgroundColor:
                          selectedTab === "my_reservation"
                            ? BaseColor.primary
                            : BaseColor.offWhiteBg,
                        color:
                          selectedTab === "my_reservation"
                            ? BaseColor.white
                            : BaseColor.blackColor,
                        width: !md && "200px",
                        fontFamily: FontFamily.Medium,
                        fontSize: isMobile && "14px",
                      }}
                      onClick={() => {
                        setSelectedTab("my_reservation");
                        setHeaderData("my_reservation");
                        setPageNo(1);
                        getReservationList(
                          search,
                          revSort?.name,
                          !_.isNull(selectedMonth)
                            ? selectedMonth.toISOString().slice(0, 7)
                            : "",
                          false,
                          1,
                          false,
                          "my_reservation"
                        );
                      }}
                      className={classes.switchTab}
                    >
                      My Tee Times
                    </div>
                    <div
                      style={{
                        backgroundColor:
                          selectedTab === "past_reservation"
                            ? BaseColor.primary
                            : BaseColor.offWhiteBg,
                        color:
                          selectedTab === "past_reservation"
                            ? BaseColor.white
                            : BaseColor.blackColor,
                        width: !md && "200px",
                        fontFamily: FontFamily.Medium,
                        fontSize: isMobile && "14px",
                      }}
                      onClick={() => {
                        setSelectedTab("past_reservation");
                        setHeaderData("past_reservation");
                        setPageNo(1);
                        getReservationList(
                          search,
                          revSort?.name,
                          !_.isNull(selectedMonth)
                            ? selectedMonth.toISOString().slice(0, 7)
                            : "",
                          false,
                          1,
                          false,
                          "past_reservation"
                        );
                      }}
                      className={classes.switchTab}
                    >
                      Past Tee Times
                    </div>
                  </div>
                </Grid>
                <Grid
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: md && "2vh",
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.GilbertQualifiDemo,
                      fontSize: !md ? 25 : 20,
                      color: BaseColor.primary,
                    }}
                  >
                    {selectedTab === "past_reservation"
                      ? "Past Tee Times"
                      : "My Tee Times"}
                  </Typography>

                  {!_.isEmpty(reservationArray) && (
                    <CSelect
                      data={revSortArray}
                      value={revSort}
                      onSelect={(val) => {
                        setRevSort(val);
                        getReservationList(
                          search,
                          val?.name,
                          !_.isNull(selectedMonth)
                            ? selectedMonth.toISOString().slice(0, 7)
                            : "",
                          false,
                          1,
                          false,
                          selectedFilter
                        );
                        setPageNo(1);
                      }}
                      placeholder="Sort By"
                      fullObj
                      sortSelect
                      style={{
                        borderRadius: 50,
                        height: "25px",
                        overflow: "hidden",
                        padding: "5px",
                        width: "96px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>

            {loader ? (
              <Grid
                item
                xs={12}
                style={{
                  height: md ? "calc(100vh - 150px)" : "calc(100vh - 110px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={40} color="primary" />
              </Grid>
            ) : (
              <Grid
                container
                gap={2}
                style={{
                  paddingLeft: "2px",
                  paddingRight: "2px",
                  marginTop: 10,
                  marginBottom: "30px",
                  overflowY: "auto",
                  maxHeight: showInput
                    ? "calc(100vh - 240px)"
                    : "calc(100vh - 180px)",
                  paddingBottom: showInput ? "30px" : "20px",
                }}
                onScroll={(e) => {
                  handleScroll(e);
                }}
              >
                {!_.isEmpty(reservationArray) ? (
                  <>
                    {reservationArray?.map((item, index) => {
                      const parseDateString = (dateString) => {
                        // Define regular expressions to match different parts of the date string
                        const dayRegex =
                          /(Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday)/;
                        const monthRegex =
                          /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/;
                        const dayOfMonthRegex = /(\d{1,2})/;

                        // Extract day of the week, month, and day of the month
                        const [, dayOfWeek] = dateString.match(dayRegex) || [];
                        const [, monthStr] = dateString.match(monthRegex) || [];
                        const [, dayOfMonthStr] =
                          dateString.match(dayOfMonthRegex) || [];
                        if (!dayOfWeek || !monthStr || !dayOfMonthStr) {
                          throw new Error("Invalid date format");
                        }

                        // Map month abbreviations to month numbers
                        const monthMap = {
                          Jan: 0,
                          Feb: 1,
                          Mar: 2,
                          Apr: 3,
                          May: 4,
                          Jun: 5,
                          Jul: 6,
                          Aug: 7,
                          Sep: 8,
                          Oct: 9,
                          Nov: 10,
                          Dec: 11,
                        };

                        // Convert extracted strings to numbers
                        const month = monthMap[monthStr];
                        const dayOfMonth = parseInt(dayOfMonthStr);

                        // Create a Date object
                        const date = new Date();
                        date.setDate(dayOfMonth);
                        date.setMonth(month);

                        // Set the day of the week
                        switch (dayOfWeek) {
                          case "Sunday":
                            date.setDate(date.getDate() + (0 - date.getDay()));
                            break;
                          case "Monday":
                            date.setDate(date.getDate() + (1 - date.getDay()));
                            break;
                          case "Tuesday":
                            date.setDate(date.getDate() + (2 - date.getDay()));
                            break;
                          case "Wednesday":
                            date.setDate(date.getDate() + (3 - date.getDay()));
                            break;
                          case "Thursday":
                            date.setDate(date.getDate() + (4 - date.getDay()));
                            break;
                          case "Friday":
                            date.setDate(date.getDate() + (5 - date.getDay()));
                            break;
                          case "Saturday":
                            date.setDate(date.getDate() + (6 - date.getDay()));
                            break;
                          default:
                            throw new Error("Invalid day of the week");
                        }

                        return date;
                      };

                      let dateObject;
                      const reservationDayString = item?.reservation_day; // Replace this with your actual reservation day string
                      try {
                        dateObject = parseDateString(reservationDayString);
                      } catch (error) {
                        console.error("Error parsing date:", error.message);
                      }

                      const startTime = new Date(
                        item.start_date + "T" + item.start_time
                      );
                      const formattedTime = startTime.toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      });
                      // Format the item's start_date to get the year and month
                      const formattedMonth = item?.start_date
                        ? item.start_date.slice(0, 7)
                        : "";
                      return (
                        <Grid item xs={12} sm={5.8} md={5.8} lg={3.8} xl={3.9}>
                          <CReservationCard
                            style={{
                              width: "100%",
                              height: md && "90px",
                              marginBottom: md && !sm && "10px",
                            }}
                            image={item?.club_image}
                            name={item.club_name}
                            time={`${formattedTime}- ${item?.reservation_day} `}
                            host={
                              item?.self_reservation === 1
                                ? "You"
                                : item.host_name
                            }
                            hostTitle={
                              item?.self_reservation === 1
                                ? "Reserved by : "
                                : "Hosting : "
                            }
                            item={item}
                            onCardClick={() => {
                              setSelectedCalendarData(item);
                              handleCardClick(dateObject, formattedMonth);
                            }}
                            onChatClick={() => {
                              const convType =
                                item?.reservation_member_data?.length >= 2
                                  ? "groups"
                                  : "personal";
                              if (convType === "personal") {
                                dispatch(setCTab("personal"));
                              } else {
                                dispatch(setCTab("groups"));
                              }
                              setTimeout(() => {
                                navigate(
                                  `/chat?c_id=${item?.conversation_id}&conv_type=${convType}`
                                );
                              }, 500);
                            }}
                            editResertvation={(data) => {
                              console.log("data11", data);
                              setShowReservationModel({
                                vis: true,
                                data: data,
                              });
                              const newArr = data?.reservation_member_data;

                              let memberArr = [];
                              newArr.map((data) => {
                                memberArr.push({
                                  default_quantity: Number(data?.guest_cnt),
                                  id: data?.member_id,
                                  isSelected: true,
                                  profile_pic: null,
                                  profile_pic_url:
                                    data?.profile_pic_url ||
                                    "https://admin.goatt.golf/img_assets/boy.jpeg",
                                  username: data?.username,
                                  nick_name: data?.username,
                                });
                              });

                              setSelectedMembers(memberArr);
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: md
                        ? "calc(100vh - 220px)"
                        : "calc(100vh - 110px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: BaseColor.grey,
                        fontFamily: FontFamily.Medium,
                        fontSize: "20px",
                      }}
                    >
                      No Tee Times Scheduled
                    </Typography>
                  </Grid>
                )}
                {bottomLoader && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={30} color="primary" />
                  </div>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
        </Grid>
      ) : (
        <Grid container>
          <CHeader />
          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
          <Grid item xs={11} sm={11} md={10} lg={10} height={"100%"}>
            <Grid marginTop={"20px"}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <Grid
                  xs={12}
                  style={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                      display: "flex",
                    }}
                  >
                    <IoIosArrowRoundBack
                      onClick={() => {
                        !_.isEmpty(reservationDetail)
                          ? navigate("/home")
                          : navigate(-1);
                      }}
                      style={{
                        fontSize: 40,
                        color: BaseColor.blackColor,
                        cursor: !isMobile && !isTablet && "pointer",
                      }}
                    />

                    <Typography
                      style={{
                        marginLeft: "10px",
                        fontSize: !md ? 25 : 20,
                        color: BaseColor.primary,
                        fontFamily: FontFamily.GilbertQualifiDemo,
                        textAlign: "center",
                      }}
                    >
                      My Tee Times
                    </Typography>
                  </div>
                </Grid>

                {!_.isEmpty(reservationArray) && (
                  <Grid
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <CSelect
                        data={revSortArray}
                        value={revSort}
                        onSelect={(val) => {
                          setRevSort(val);
                          getReservationList(
                            search,
                            val?.name,
                            !_.isNull(selectedMonth)
                              ? selectedMonth.toISOString().slice(0, 7)
                              : "",
                            false,
                            1,
                            false,
                            selectedFilter
                          );
                          setPageNo(1);
                        }}
                        placeholder="Sort by"
                        fullObj
                        sortSelect
                        style={{
                          width: "50%",
                          borderRadius: 50,
                        }}
                      />
                    </div>

                    {!md && (
                      <TextField
                        variant="outlined"
                        placeholder="Search"
                        value={search}
                        onChange={(e) => {
                          const inputvalue = e.target.value.trim();
                          setSearch(e.target.value);
                          if (inputvalue !== "" || e.target.value.length < 1) {
                            getReservationList(
                              e.target.value,
                              revSort?.name,
                              !_.isNull(selectedMonth)
                                ? selectedMonth.toISOString().slice(0, 7)
                                : "",
                              false,
                              1,
                              false,
                              selectedFilter
                            );
                            setPageNo(1);
                          } else {
                            setReservationArray([]);
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <IconButton>
                              <CiSearch
                                style={{
                                  color: BaseColor.grey,
                                  cursor: !isMobile && !isTablet && "pointer",
                                  fontSize: 20,
                                }}
                              />
                            </IconButton>
                          ),
                          endAdornment: !_.isEmpty(search) && (
                            <IoCloseOutline
                              onClick={() => {
                                setSearch("");
                                getReservationList(
                                  "",
                                  revSort?.name,
                                  !_.isNull(selectedMonth)
                                    ? selectedMonth.toISOString().slice(0, 7)
                                    : "",
                                  false,
                                  1,
                                  false,
                                  selectedFilter
                                );
                                setPageNo(1);
                              }}
                              style={{ cursor: "pointer", fontSize: "40px" }}
                            />
                          ),
                        }}
                        style={{
                          "MuiInputBase-root-MuiOutlinedInput-root ": {},
                          width: "250px",
                          marginLeft: 10,
                          height: "40px",
                        }}
                      />
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                display={"flex"}
                alignItems={"center"}
                mt={"10px"}
                mb={"20px"}
              >
                <Grid
                  container
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={5}
                    display={"fex"}
                    alignItems={"center"}
                  >
                    <div className={classes.switchDiv}>
                      <div
                        style={{
                          backgroundColor:
                            selectedTab === "my_reservation"
                              ? BaseColor.primary
                              : BaseColor.offWhiteBg,
                          color:
                            selectedTab === "my_reservation"
                              ? BaseColor.white
                              : BaseColor.blackColor,
                          width: !md && "200px",
                          fontFamily: FontFamily.Medium,
                        }}
                        onClick={() => {
                          setSelectedTab("my_reservation");
                          setHeaderData("my_reservation");
                          setPageNo(1);
                          getReservationList(
                            search,
                            revSort?.name,
                            !_.isNull(selectedMonth)
                              ? selectedMonth.toISOString().slice(0, 7)
                              : "",
                            false,
                            1,
                            false,
                            "my_reservation"
                          );
                        }}
                        className={classes.switchTab}
                      >
                        My Tee Times
                      </div>
                      <div
                        style={{
                          backgroundColor:
                            selectedTab === "past_reservation"
                              ? BaseColor.primary
                              : BaseColor.offWhiteBg,
                          color:
                            selectedTab === "past_reservation"
                              ? BaseColor.white
                              : BaseColor.blackColor,
                          width: !md && "200px",
                          fontFamily: FontFamily.Medium,
                        }}
                        onClick={() => {
                          setSelectedTab("past_reservation");
                          setHeaderData("past_reservation");
                          setPageNo(1);
                          getReservationList(
                            search,
                            revSort?.name,
                            !_.isNull(selectedMonth)
                              ? selectedMonth.toISOString().slice(0, 7)
                              : "",
                            false,
                            1,
                            false,
                            "past_reservation"
                          );
                        }}
                        className={classes.switchTab}
                      >
                        Past Tee Times
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {loader ? (
              <Grid
                item
                xs={12}
                style={{
                  height: md ? "calc(100vh - 150px)" : "calc(100vh - 110px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={40} color="primary" />
              </Grid>
            ) : (
              <Grid
                container
                gap={2}
                style={{
                  // paddingLeft: "2px",
                  // paddingRight: "2px",
                  marginTop: 10,
                  marginBottom: "30px",
                  overflowY: "auto",
                  maxHeight: "calc(100vh - 230px)",
                  paddingBottom: "20px",
                }}
                className={
                  isMobile || isTablet
                    ? classes.mainScrollBar
                    : classes.scrollBar
                }
                onScroll={(e) => {
                  handleScroll(e);
                }}
              >
                {!_.isEmpty(reservationArray) ? (
                  <>
                    {reservationArray?.map((item, index) => {
                      const parseDateString = (dateString) => {
                        // Define regular expressions to match different parts of the date string
                        const dayRegex =
                          /(Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday)/;
                        const monthRegex =
                          /(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/;
                        const dayOfMonthRegex = /(\d{1,2})/;

                        // Extract day of the week, month, and day of the month
                        const [, dayOfWeek] = dateString.match(dayRegex) || [];
                        const [, monthStr] = dateString.match(monthRegex) || [];
                        const [, dayOfMonthStr] =
                          dateString.match(dayOfMonthRegex) || [];
                        if (!dayOfWeek || !monthStr || !dayOfMonthStr) {
                          throw new Error("Invalid date format");
                        }

                        // Map month abbreviations to month numbers
                        const monthMap = {
                          Jan: 0,
                          Feb: 1,
                          Mar: 2,
                          Apr: 3,
                          May: 4,
                          Jun: 5,
                          Jul: 6,
                          Aug: 7,
                          Sep: 8,
                          Oct: 9,
                          Nov: 10,
                          Dec: 11,
                        };

                        // Convert extracted strings to numbers
                        const month = monthMap[monthStr];
                        const dayOfMonth = parseInt(dayOfMonthStr);

                        // Create a Date object
                        const date = new Date();
                        date.setDate(dayOfMonth);
                        date.setMonth(month);

                        // Set the day of the week
                        switch (dayOfWeek) {
                          case "Sunday":
                            date.setDate(date.getDate() + (0 - date.getDay()));
                            break;
                          case "Monday":
                            date.setDate(date.getDate() + (1 - date.getDay()));
                            break;
                          case "Tuesday":
                            date.setDate(date.getDate() + (2 - date.getDay()));
                            break;
                          case "Wednesday":
                            date.setDate(date.getDate() + (3 - date.getDay()));
                            break;
                          case "Thursday":
                            date.setDate(date.getDate() + (4 - date.getDay()));
                            break;
                          case "Friday":
                            date.setDate(date.getDate() + (5 - date.getDay()));
                            break;
                          case "Saturday":
                            date.setDate(date.getDate() + (6 - date.getDay()));
                            break;
                          default:
                            throw new Error("Invalid day of the week");
                        }

                        return date;
                      };

                      let dateObject;
                      const reservationDayString = item?.reservation_day; // Replace this with your actual reservation day string
                      try {
                        dateObject = parseDateString(reservationDayString);
                      } catch (error) {
                        console.error("Error parsing date:", error.message);
                      }

                      const startTime = new Date(
                        item.start_date + "T" + item.start_time
                      );
                      const formattedTime = startTime.toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      });
                      // Format the item's start_date to get the year and month
                      const formattedMonth = item?.start_date
                        ? item.start_date.slice(0, 7)
                        : "";
                      return (
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={5.8}
                          lg={3.8}
                          xl={3.9}
                          marginBottom={"10px"}
                        >
                          <CReservationCard
                            style={{
                              width: "100%",
                              height: md && "90px",
                            }}
                            item={item}
                            image={item?.club_image}
                            name={item.club_name}
                            time={`${formattedTime}- ${item?.reservation_day} `}
                            host={
                              item?.self_reservation === 1
                                ? "You"
                                : item.host_name
                            }
                            onChatClick={() => {
                              const convType =
                                item?.reservation_member_data?.length >= 2
                                  ? "groups"
                                  : "personal";
                              if (convType === "personal") {
                                dispatch(setCTab("personal"));
                              } else {
                                dispatch(setCTab("groups"));
                              }
                              setTimeout(() => {
                                navigate(
                                  `/chat?c_id=${item?.conversation_id}&conv_type=${convType}`
                                );
                              }, 500);
                            }}
                            hostTitle={
                              item?.self_reservation === 1
                                ? "Reserved by : "
                                : "Hosting : "
                            }
                            onCardClick={() => {
                              setSelectedCalendarData(item);
                              handleCardClick(dateObject, formattedMonth);
                            }}
                            editResertvation={(data) => {
                              setShowReservationModel({
                                vis: true,
                                data: data,
                              });
                              const newArr = data?.reservation_member_data;

                              let memberArr = [];
                              newArr.map((data) => {
                                memberArr.push({
                                  default_quantity: Number(data?.guest_cnt),
                                  id: data?.member_id,
                                  isSelected: true,
                                  profile_pic: null,
                                  profile_pic_url:
                                    data?.profile_pic_url ||
                                    "https://admin.goatt.golf/img_assets/boy.jpeg",
                                  username: data?.username,
                                  nick_name: data?.username,
                                });
                              });

                              setSelectedMembers(memberArr);
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      height: md
                        ? "calc(100vh - 150px)"
                        : "calc(100vh - 260px)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: BaseColor.grey,
                        fontFamily: FontFamily.Medium,
                        fontSize: "20px",
                      }}
                    >
                      No Tee Times Scheduled
                    </Typography>
                  </Grid>
                )}
                {bottomLoader && (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size={30} color="primary" />
                  </div>
                )}
              </Grid>
            )}
          </Grid>

          <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
        </Grid>
      )}

      {isCalendarOpen && (
        <CModal
          visible={calendarModal}
          onClose={() => {
            setCalendarModal(false);
          }}
        >
          <Grid
            container
            padding={isMobile ? "20px" : "40px"}
            style={{
              position: "relative",
              width: mobileScreen ? "90vw" : "440px",
              maxHeight: "80vh",
              overflowY: "auto",
            }}
          >
            <CloseIcon
              onClick={() => {
                setCalendarModal(false);
              }}
              className={classes.closeContainer}
            />
            <Grid
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ width: "100%" }}
            >
              {/* <Calendar
                onChange={setSelectedMonth}
                value={selectedMonth}
                onClickDay={(date) => {
                  handleDayClick(date);
                }}
                tileDisabled={isDateDisabled}
                navigationLabel={({ date }) => (
                  <span className={`${classes.customNavigationLabelText}`}>
                    {date.toLocaleDateString("en-US", {
                      year: "numeric",
                    }) +
                      "  " +
                      date.toLocaleDateString("en-US", {
                        month: "short",
                      })}
                  </span>
                )}
                tileClassName={getTileClassName}
                prev2Label={null}
                next2Label={null}
                className={`${classes.calendarText} ${classes.calendarWithoutBorder} ${classes.customNavigationLabel}`} // Apply the styles
              /> */}
              <img
                src={selectedCalendarData?.club_image}
                alt="contact us"
                draggable={false}
                style={{
                  objectFit: "contain",
                  width: md || isMobile || isTablet ? "100%" : "90%",
                  height: "80%",
                  borderRadius: 10,
                  userSelect: "none",
                }}
              />
            </Grid>
            {!_.isUndefined(selectedCalendarData) && (
              <>
                <hr className={classes.divider} />
                <Grid
                  container
                  style={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "16px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: BaseColor.textColor,
                      }}
                    >
                      Date
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: BaseColor.textColor,
                        textAlign: "end",
                      }}
                    >
                      {`${formatedselectedCalendarTime}, `}
                      {outputString}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  style={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "16px",
                    marginBottom: 15,
                    marginTop: 15,
                  }}
                >
                  <Typography
                    style={{
                      fontFamily: FontFamily.SemiBold,
                      fontSize: "14px",
                      color: BaseColor.textColor,
                    }}
                  >
                    {selectedCalendarData?.self_reservation === 1
                      ? "Reserved by"
                      : "Hosting Member"}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="userImage"
                      src={
                        selectedCalendarData?.profile_pic_url ||
                        "https://i.ibb.co/D4kB907/Goat-01-1.png"
                      }
                      style={{
                        height: "32px",
                        width: "32px",
                        borderRadius: "12px",
                      }}
                      draggable={false}
                    />
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: BaseColor.textColor,
                        paddingLeft: "10px",
                      }}
                    >
                      {selectedCalendarData?.self_reservation === 1
                        ? "You"
                        : selectedCalendarData?.host_name}
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  container
                  style={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "16px",
                    marginBottom:
                      _.isEmpty(
                        selectedCalendarData?.reservation_member_data
                      ) && "20px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: BaseColor.textColor,
                      }}
                    >
                      Member
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      display: "flex",
                      flexWrap: "wrap",
                    }}
                  >
                    {selectedCalendarData?.reservation_member_data?.map(
                      (value, index) => (
                        <Grid key={index} item>
                          <div className={classes.memberContainer}>
                            <Grid onClick={() => {}}>
                              <div
                                style={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Badge
                                  key={value.id}
                                  badgeContent={
                                    value?.guest_cnt == 0
                                      ? null
                                      : value?.guest_cnt
                                  }
                                  color="primary"
                                  overlap="circular"
                                  anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                  }}
                                >
                                  <img
                                    src={
                                      value.profile_pic_url ||
                                      "https://admin.goatt.golf/img_assets/boy.jpeg"
                                    }
                                    alt="memberUserImage"
                                    style={{
                                      height: 40,
                                      width: 40,
                                      borderRadius: 20,
                                      marginBottom: 10,
                                    }}
                                    draggable={false}
                                  />
                                </Badge>
                                <div
                                  style={{
                                    background: value?.isSelected
                                      ? "#042B4860"
                                      : "none",
                                    height: "65px",
                                    width: "65px",
                                    borderRadius: "50%",
                                    position: "absolute",
                                    bottom: "3px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  {value?.isSelected && (
                                    <IoCheckmarkOutline
                                      color={BaseColor.white}
                                      size={40}
                                    />
                                  )}
                                </div>
                              </div>
                              <Typography
                                style={{
                                  fontSize: "12px",
                                  fontFamily: FontFamily.Bold,
                                  color: BaseColor.textColor,
                                  textAlign: "center",
                                  wordBreak: "break-word",
                                }}
                              >
                                {value?.username}
                              </Typography>
                            </Grid>
                          </div>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    paddingBottom: 10,
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "16px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: BaseColor.textColor,
                      }}
                    >
                      Club name
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: BaseColor.textColor,
                        textAlign: "end",
                      }}
                    >
                      {selectedCalendarData?.club_name}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  style={{
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "16px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: BaseColor.textColor,
                      }}
                    >
                      Location
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: BaseColor.textColor,
                        textAlign: "end",
                      }}
                    >
                      {selectedCalendarData?.club_location}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: "16px",
                    marginTop: "10px",
                  }}
                >
                  <Grid item>
                    <Typography
                      style={{
                        fontFamily: FontFamily.SemiBold,
                        fontSize: "14px",
                        color: BaseColor.textColor,
                      }}
                    >
                      Chat
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#EDF1F2",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "45px",
                          width: "45px",
                          cursor: !isMobile && !isTablet && "pointer",
                        }}
                        onClick={() => {
                          const convType =
                            selectedCalendarData?.reservation_member_data
                              ?.length >= 2
                              ? "groups"
                              : "personal";
                          if (convType === "personal") {
                            dispatch(setCTab("personal"));
                          } else {
                            dispatch(setCTab("groups"));
                          }
                          setTimeout(() => {
                            navigate(
                              `/chat?c_id=${selectedCalendarData?.conversation_id}&conv_type=${convType}`
                            );
                          }, 500);
                        }}
                      >
                        <IoChatbubbleEllipsesOutline
                          color={BaseColor.primary}
                          style={{
                            height: "25px",
                            width: "25px",
                            cursor: !isMobile && !isTablet && "pointer",
                          }}
                          draggable={false}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </CModal>
      )}
      <ReservationModel
        reservationModal={showReservationModel}
        setReservationModal={setShowReservationModel}
        editData2={showReservationModel?.data}
        getReservationListData={() => {
          getReservationList(
            !_.isNull(selectedMonth)
              ? selectedMonth.toISOString().slice(0, 7)
              : ""
          );
        }}
        isHome
        selectedMembers={selectedMembers}
        setSelectedMembers={setSelectedMembers}
      />
    </>
  );
};

export default ReservationsList;
