import _, { isObject, isString } from "lodash";
import { Setting } from "./Setting";
import { store } from "../Redux/store/configureStore";
import authActions from ".././Redux/reducers/auth/actions";
import { isMobile } from "react-device-detect";

function getUserToken() {
  const {
    auth: { accessToken },
  } = store.getState();
  return `Bearer ${accessToken}`;
}

export function getApiData(
  endpoint,
  method,
  data,
  headers,
  skipBaseUrl = false
) {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    const authState = store?.getState() || {};
    const token = getUserToken() || "";
    const { uuid } = "" || "";
    const authHeaders = {
      "Content-Type": "application/json",
      authorization: token ? `${token}` : "",
    };

    return new Promise((resolve, reject) => {
      let query = "";
      let qs = "";
      for (const key in data) {
        query += `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}&`;
      }
      const params = {};
      params.method = method.toLowerCase() === "get" ? "get" : "post";
      params.headers = !_.isEmpty(headers) ? headers : authHeaders;

      if (params.method === "post") {
        if (
          params.headers &&
          params.headers["Content-Type"] &&
          params.headers["Content-Type"] === "application/json"
        ) {
          params.body = JSON.stringify(data);
        } else {
          params.body = query;
        }
      } else {
        qs = !_.isEmpty(query) ? `?${query}` : "";
      }

      if (
        params.method === "post" &&
        params.headers &&
        params.headers["Content-Type"] &&
        params.headers["Content-Type"] === "application/json"
      ) {
      } else {
        let str = "";
        if (data && Object.keys(data).length > 0) {
          Object.keys(data).map((dk) => {
            str += `${dk}:${data[dk]}\n`;
          });
        }
      }

      const url = skipBaseUrl ? endpoint : Setting.api + endpoint + qs;
      fetch(url, params)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.code == 401) {
            store.dispatch(authActions.setAccessToken(""));
            store.dispatch(authActions.setUserData({}));
            store.dispatch(authActions.setIsAdmin(false));
            store.dispatch(authActions.setIsTokenSent(false));
            store.dispatch(authActions.setSignupData({}));
            localStorage.clear();
            window.location.replace(isMobile ? "/login" : "/");
            resolve(responseJson);
          } else if (
            isObject(responseJson) &&
            isString(responseJson.message) &&
            responseJson.message === "Unauthorized" &&
            endpoint !== "delete-token"
          ) {
            // store.dispatch(AuthAction.setAccessToken(''));
            // store.dispatch(AuthAction.setUserData({}));
            // navigation.navigate('RedirectLS');
            resolve(responseJson);
          } else {
            resolve(responseJson);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export function getAPIProgressData(
  endpoint,
  method,
  data,
  headers = false,
  onProgress = null
) {
  const isOnline = window.navigator.onLine;
  if (isOnline) {
    return new Promise(async (resolve, reject) => {
      const url = Setting.api + endpoint;
      const oReq = new XMLHttpRequest();
      oReq.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded * 100) / event.total;
          if (onProgress) {
            onProgress(progress);
          }
        }
      });

      var FormData = require("form-data");
      var form = new FormData();
      if (data && Object.keys(data).length > 0) {
        Object.keys(data).map((k) => form.append(k, data[k]));
      }

      const hData = {
        "Content-Type": "multipart/form-data",
      };

      if (headers) {
        hData.Authorization = getUserToken();
      }

      let options = {
        method: method.toLowerCase() === "get" ? "get" : "post",
        headers: hData,
        body: form,
      };

      delete options.headers["Content-Type"];

      fetch(url, {
        method: method.toLowerCase() === "get" ? "get" : "post",
        headers: hData,
        body: form,
      })
        .then(function (res) {
          if (res.status === 401) {
            // Unauthorized, perform necessary actions
            store.dispatch(authActions.setAccessToken(""));
            store.dispatch(authActions.setUserData({}));
            localStorage.clear();
            window.location.replace(isMobile ? "/login" : "/");
            resolve({ code: 401, message: "Unauthorized" });
          } else {
            resolve(res.json());
          }
        })
        .then(function (result) {})
        .catch((err) => {
          reject(err);
        });
    });
  }
}
