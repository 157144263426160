const prod = process.env.NODE_ENV === "production";

const baseUrl = prod
  ? "https://admin.goatt.golf/v1/" // live
  : "https://admittedly-more-lark.ngrok-free.app/golf-backend/v1/"; // yash local

// "https://tolerant-locally-moth.ngrok-free.app/golf-backend/v1/"; // raj bhai local

export const Setting = {
  baseUrl,
  api: baseUrl,
  googleMapApiKey: "AIzaSyA4UjSUdgQLNBkiLV2Re5vpwrEs70AxFgI",
  socketUrl: "https://chat.goatt.golf/",

  endpoints: {
    login: "user/login",
    signupOtp: "user/send-otp",
    verifyOtp: "user/confirm-email",
    createPassword: "user/add-password",
    verifyForgotPasswordOtp: "user/verify-forgot-otp",
    forgotPassword: "user/set-password",
    contactUs: "user/add-contact",
    getCmsPages: "user/get-page",
    getPhoneCode: "user/phone-code",
    getFaqList: "user/faq-list",
    signup: "user/signup",
    addClub: "user/add-club",
    commonData: "user/get-common-data",
    clubSearch: "user/search-club",
    getEventList: "event/event-schedule-list",
    socialLogin: "user-social-connect/social-connect",
    getMapLocation: "event/get-map-location",
    golfOtherDetails: "user/members-other-details",
    getStateList: "user/state-list",
    logOutApi: "user/logout",
    addAndRemoveBookmark: "event/bookmark-event",
    eventList: "event/user-event-list",
    getEventDetails: "event/get-event-detail",
    reservationList: "reservation/reservation-schedule-list",
    userList: "user/get-user-list",
    createReservation: "reservation/add-reservation",
    bookEvent: "event/book-event",
    clubDetails: "user/club-details",
    getReviewList: "reservation/user-feedback-list",
    getReviewModelList: "reservation/get-pending-review",
    submitFeedback: "reservation/create-feedback",
    cancelFeedback: "reservation/decline-feedback",
    changePassword: "user/change-password",
    userProfile: "user/update-profile",
    deleteAccount: "user/delete-user-account",
    eventPay: "payment/get-event-stripe-session",
    verifyUserList: "user/verify-user-list",
    chatMessage: "user/setting-list",
    userToken: "user/notification-token",
    notificationList: "user/notification-list",
    removeNotificationList: "user/remove-all-notification",
    rejectReservation: "reservation/reject-reservation",
    createBankAccount: "payment/create-bank-account",
    defaultBank: "payment/change-default-bank",
    bankList: "payment/get-bank-list",
    withdraw: "payment/withdraw",
    transactionList: "payment/user-transaction-list",
    walletBalance: "payment/user-wallet-balance",
    collectPayment: "reservation/initiate-payment",
    confirmReservation: "reservation/confirm-reservation",
    changeDoNotDisturb: "user/update-user-dnd-status",
    sendNotification: "user/send-notification",
    initialPaymentDetails: "payment/initial-plan-list",
    planDetails: "payment/plan-list",
    initialPaymentLink: "payment/create-one-time-link",
    subscriptionList: "payment/user-subscription-list",
  },
};
