import { Checkbox, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import CInput from "../../Components/CInput";
import { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import CButton from "../../Components/CButton";
import CHeader from "../../Components/Header";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { isEmpty } from "lodash";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";

const AddBankMobile = () => {
  //styles
  const classes = useStyles();

  // form state
  const [holderName, setHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [swiftCode, setSwiftCode] = useState("");

  // error text
  const [holderNameErrorText, setHoldernameErrorText] = useState("");
  const [accountNumberErrorText, setAccountNumberErrorText] = useState("");
  const [swiftCodeErrorText, setSwiftCodeErrorText] = useState("");
  const [checked, setChecked] = useState(false);

  // navigation
  const navigate = useNavigate();

  //loader
  const [btnLoader, setBtnLoader] = useState(false);

  // validation
  const accountValidation = () => {
    let isValid = true;
    if (holderName.trim() === "") {
      setHoldernameErrorText("Account holder name is required");
      isValid = false;
    }
    if (accountNumber === "") {
      setAccountNumberErrorText("Bank account number is required");
      isValid = false;
    }
    if (swiftCode === "") {
      setSwiftCodeErrorText("Routing number is required");
      isValid = false;
    }
    if (swiftCode.length > 11) {
      setSwiftCodeErrorText("Enter valid Routing number");
      isValid = false;
    }
    if (isValid) {
      setHoldernameErrorText("");
      setAccountNumberErrorText("");
      setSwiftCodeErrorText("");
      getAddBank();
    }
  };

  // add bank
  const getAddBank = async () => {
    setBtnLoader(true);
    const endPoints = Setting.endpoints.createBankAccount;
    const data = {
      account_holder_name: holderName,
      account_number: accountNumber,
      swift_code: swiftCode,
      set_default_bank: checked ? 1 : 0,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        setBtnLoader(false);
        setHolderName("");
        setAccountNumber("");
        setSwiftCode("");
        setHoldernameErrorText("");
        setAccountNumberErrorText("");
        setSwiftCodeErrorText("");
        setChecked(false);
        toast(resp?.message, {
          type: "success",
        });
        navigate(-1);
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setBtnLoader(false);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Grid container style={{ height: "100vh" }}>
      <CHeader
        title={"Add New Bank"}
        backButtonClick={() => {
          navigate(-1);
        }}
      />
      <Grid
        container
        style={{ height: "calc(100vh - 90px)", overflowY: "auto" }}
      >
        <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
        <Grid item xs={11} sm={11} md={10} lg={10}>
          <Grid container>
            <Grid item xs={12} mb={"20px"}>
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid
                  container
                  flexDirection={"column"}
                  justifyContent={"space-between"}
                  height={"83vh"}
                >
                  <Grid>
                    <CInput
                      label={
                        <Typography
                          style={{
                            fontFamily: FontFamily.Medium,
                            color: BaseColor.blackColor,
                            fontSize: 16,
                          }}
                        >
                          Account Holder Name
                          <span style={{ color: "red", marginLeft: "2px" }}>
                            *
                          </span>
                        </Typography>
                      }
                      placeholder="Account Holder Name"
                      fullWidth
                      onChange={(val) => {
                        setHolderName(val);
                        setHoldernameErrorText("");
                      }}
                      value={holderName}
                      errorMsg={holderNameErrorText}
                      error={!isEmpty(holderNameErrorText)}
                    />
                    <CInput
                      label={
                        <Typography
                          style={{
                            fontFamily: FontFamily.Medium,
                            color: BaseColor.blackColor,
                            fontSize: 16,
                          }}
                        >
                          Bank Account Number
                          <span style={{ color: "red", marginLeft: "2px" }}>
                            *
                          </span>
                        </Typography>
                      }
                      placeholder="Bank Account Number"
                      fullWidth
                      onChange={(val) => {
                        if (/^\d*$/.test(val) && val.length < 13) {
                          setAccountNumber(val);
                          setAccountNumberErrorText("");
                        }
                      }}
                      value={accountNumber}
                      errorMsg={accountNumberErrorText}
                      error={!isEmpty(accountNumberErrorText)}
                    />
                    <CInput
                      label={
                        <Typography
                          style={{
                            fontFamily: FontFamily.Medium,
                            color: BaseColor.blackColor,
                            fontSize: 16,
                          }}
                        >
                          Routing Number
                          <span style={{ color: "red", marginLeft: "2px" }}>
                            *
                          </span>
                        </Typography>
                      }
                      placeholder="Routing Number"
                      fullWidth
                      onChange={(val) => {
                        if (val.length < 12) {
                          setSwiftCode(val);
                          setSwiftCodeErrorText("");
                        }
                      }}
                      value={swiftCode}
                      errorMsg={swiftCodeErrorText}
                      error={!isEmpty(swiftCodeErrorText)}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "1.5vh",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        style={{ marginRight: 5 }}
                        icon={<span className={classes.icon} />}
                        checked={checked}
                        onChange={handleChange}
                        checkedIcon={<span className={classes.checkedIcon} />}
                      />
                      <label
                        for={"default"}
                        style={{
                          fontFamily: FontFamily.Medium,
                          color: BaseColor.grey,
                          cursor: "pointer",
                          fontSize: 14,
                          marginLeft: "5px",
                        }}
                      >
                        Set as default
                      </label>
                    </div>
                  </Grid>
                  <Grid>
                    <CButton
                      onClick={() => {
                        accountValidation();
                      }}
                      loading={btnLoader}
                    >
                      Proceed
                    </CButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={0.5} sm={0.5} md={1} lg={1}></Grid>
      </Grid>
    </Grid>
  );
};

export default AddBankMobile;
