import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import {
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Grid,
  useMediaQuery,
  IconButton,
  Divider,
} from "@mui/material";
import { CiLocationOn } from "react-icons/ci";
import CInput from "../../Components/CInput";
import CSelect from "../../CSelect";
import { RiDeleteBin6Line } from "react-icons/ri";
import CloseIcon from "@mui/icons-material/Close";
import theme, { FontFamily } from "../../Config/theme";
import BaseColor from "../../Config/colors";
import styles from "./styles";
import { isMobile, isTablet } from "react-device-detect";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";
import _ from "lodash";
import { useSelector } from "react-redux";
import CAutoComplete from "../../Components/CAutoComplete";
import CStepper from "../../Components/CStepper";
import PlacesAutocomplete from "../../Components/PlaceAutoComplete";

const ClubMembership = forwardRef((props, ref) => {
  const classes = styles();

  const scrollRef = useRef();

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const { email, activeStep, setActiveStep, setClubLoader } = props;
  const signUpData = useSelector((state) => state.auth.signUpData);
  const userData = useSelector((state) => state.auth.userData);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedClub, setSelectedClub] = useState({});
  const steps = ["Personal Details", "Golf Clubs", "Golf Other Details"];
  const [error, setError] = useState(false);
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [selectedClubs, setSelectedClubs] = useState([]);
  const [clubForms, setClubForms] = useState([
    {
      type: "home",
      label: "Home Club",
      additionalLabel: "Additional 1",
      formData: {},
      errors: {},
    },
    {
      type: "secondary",
      label: "Secondary Club",
      additionalLabel: "Additional 2",
      formData: {},
      errors: {},
    },
  ]);

  useEffect(() => {
    commonData();
  }, []);

  const fetchSearchResults = async (val) => {
    let endPoints = Setting.endpoints.clubSearch;

    const params = {
      search: val,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        setSearchResults(resp?.data);
        return resp?.data;
      } else {
        setSearchResults([]);
        return false;
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
      });
    }
  };

  const handleFieldChange = (index, updatedField) => {
    const updatedClubForms = [...clubForms];
    const clubForm = updatedClubForms[index];
    const formData = { ...clubForm.formData, ...updatedField };
    const errors = { ...clubForm.errors };
    // Clear the error if the field has a value
    Object.keys(updatedField).forEach((key) => {
      if (updatedField[key]) {
        errors[key] = "";
      }
    });

    updatedClubForms[index] = { ...clubForm, formData, errors };
    setClubForms(updatedClubForms);
  };

  // Assuming clubForms is the state and you're updating a specific form's data
  const updateForm = (index, updatedData) => {
    setClubForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[index] = {
        ...updatedForms[index],
        formData: {
          ...updatedForms[index].formData,
          ...updatedData,
        },
      };
      return updatedForms;
    });
  };

  // const handleSelect = (value, index) => {
  //   const updatedClubForm1 = {
  //     ...clubForms[index],
  //     formData: {
  //       ...clubForms[index]?.formData,
  //       lat: value?.lat,
  //       lng: value?.lng,
  //       homeClubAddress: value?.address,
  //       homeClubState: value?.state,
  //       id: value.id,
  //     },
  //   };
  //   const updatedClubForms1 = [...clubForms];
  //   updatedClubForms1[index] = updatedClubForm1;
  //   setClubForms(updatedClubForms1);
  // };

  useImperativeHandle(ref, () => ({
    async validateForm() {
      let isValid = true;
      let homeClubFilled = false;

      const updatedForms =
        _.isArray(clubForms) &&
        clubForms.length > 0 &&
        clubForms.map((clubForm, ind) => {
          const formData = clubForm?.formData;
          const errors = clubForm?.errors;

          if (clubForm?.type === "home") {
            // Validation logic for home club
            errors.homeClubName = !formData?.homeClubName
              ? "Name is required"
              : "";

            if (!_.isEmpty(errors.homeClubName)) {
              scrollRef?.current?.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }

            errors.homeClubAddress =
              !formData.homeClubAddress ||
              formData.homeClubAddress.trim() === ""
                ? "Address is required"
                : "";

            if (!_.isEmpty(errors.homeClubAddress)) {
              scrollRef?.current?.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }

            errors.homeClubType = !formData.homeClubType
              ? "Club type is required"
              : "";

            if (!_.isEmpty(errors.homeClubType)) {
              scrollRef?.current?.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }

            // Validation for Number of holes
            errors.homeClubHoles = !formData.homeClubHoles
              ? "Number of holes is required"
              : isNaN(formData.homeClubHoles)
              ? "Number of holes must be a valid number"
              : "";

            if (!_.isEmpty(errors.homeClubHoles)) {
              scrollRef?.current?.scrollTo({
                top: 150,
                behavior: "smooth",
              });
            }

            // Validation for Number of courses
            errors.homeClubCourses = !formData.homeClubCourses
              ? "Number of courses is required"
              : "";

            if (!_.isEmpty(errors.homeClubCourses)) {
              scrollRef?.current?.scrollTo({
                top: 150,
                behavior: "smooth",
              });
            }

            errors.homeClubState = !formData.homeClubState
              ? "State is required"
              : "";

            if (!_.isEmpty(errors.homeClubState)) {
              scrollRef?.current?.scrollTo({
                top: 250,
                behavior: "smooth",
              });
            }

            errors.homeClubGuestFees = !formData.homeClubGuestFees
              ? "Guest fees are required"
              : isNaN(formData.homeClubGuestFees)
              ? "Guest fees must be a valid number"
              : "";

            if (!_.isEmpty(errors.homeClubGuestFees)) {
              scrollRef?.current?.scrollTo({
                top: 250,
                behavior: "smooth",
              });
            }

            errors.homeClubUnaccompanied = !formData.homeClubUnaccompanied
              ? "Unaccompanied play field is required"
              : "";

            if (!_.isEmpty(errors.homeClubUnaccompanied)) {
              scrollRef?.current?.scrollTo({
                top: 300,
                behavior: "smooth",
              });
            }

            errors.homeClubDescription =
              !formData.homeClubDescription ||
              formData.homeClubDescription.trim() === ""
                ? "Description is required"
                : "";

            if (!_.isEmpty(errors.homeClubDescription)) {
              scrollRef?.current?.scrollTo({
                top: 400,
                behavior: "smooth",
              });
            }

            // Validate Course website
            errors.homeClubWebsite = !formData.homeClubWebsite
              ? "Course website is required"
              : !isValidURL(formData.homeClubWebsite)
              ? "Please enter a valid course website"
              : "";

            if (!_.isEmpty(errors.homeClubWebsite)) {
              scrollRef?.current?.scrollTo({
                top: 500,
                behavior: "smooth",
              });
            }

            // Function to check if a string is a valid URL
            function isValidURL(url) {
              // Regular expression for URL validation
              // This regex checks for a standard http/https URL format
              var pattern = new RegExp(
                "^(https?:\\/\\/)?" + // protocol
                  "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                  "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                  "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                  "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                  "(\\#[-a-z\\d_]*)?$",
                "i"
              ); // fragment locator

              return !!pattern.test(url);
            }

            // Check if home club is filled
            homeClubFilled = Object.values(errors).every(
              (error) => error === ""
            );
            isValid = isValid && homeClubFilled; // Update overall isValid based on home club validation
          } else if (
            clubForm?.type === "secondary" ||
            clubForm?.type === "additional"
          ) {
            // Validation logic for secondary or additional clubs
            if (Object.values(formData).some((value) => value !== "")) {
              errors.homeClubName = !formData?.homeClubName
                ? "Name is required"
                : "";

              if (!_.isEmpty(errors.homeClubName)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 800
                      : clubForm?.label === "Additional Club 1"
                      ? 1200
                      : clubForm?.label === "Additional Club 2"
                      ? 1600
                      : clubForm?.label === "Additional Club 3"
                      ? 2000
                      : "",
                  behavior: "smooth",
                });
              }

              errors.homeClubAddress =
                !formData.homeClubAddress ||
                formData.homeClubAddress.trim() === ""
                  ? "Address is required"
                  : "";

              if (!_.isEmpty(errors.homeClubAddress)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 800
                      : clubForm?.label === "Additional Club 1"
                      ? 1200
                      : clubForm?.label === "Additional Club 2"
                      ? 1600
                      : clubForm?.label === "Additional Club 3"
                      ? 2000
                      : "",
                  behavior: "smooth",
                });
              }

              errors.homeClubType = !formData.homeClubType
                ? "Club type is required"
                : "";

              if (!_.isEmpty(errors.homeClubType)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 800
                      : clubForm?.label === "Additional Club 1"
                      ? 1200
                      : clubForm?.label === "Additional Club 2"
                      ? 1600
                      : clubForm?.label === "Additional Club 3"
                      ? 2000
                      : "",
                  behavior: "smooth",
                });
              }

              // Validation for Number of holes
              errors.homeClubHoles = !formData.homeClubHoles
                ? "Number of holes is required"
                : isNaN(formData.homeClubHoles)
                ? "Number of holes must be a valid number"
                : "";

              if (!_.isEmpty(errors.homeClubHoles)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 1000
                      : clubForm?.label === "Additional Club 1"
                      ? 1400
                      : clubForm?.label === "Additional Club 2"
                      ? 2000
                      : clubForm?.label === "Additional Club 3"
                      ? 2200
                      : "",
                  behavior: "smooth",
                });
              }

              // Validation for Number of courses
              errors.homeClubCourses = !formData.homeClubCourses
                ? "Number of courses is required"
                : "";

              if (!_.isEmpty(errors.homeClubCourses)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 1000
                      : clubForm?.label === "Additional Club 1"
                      ? 1400
                      : clubForm?.label === "Additional Club 2"
                      ? 1800
                      : clubForm?.label === "Additional Club 3"
                      ? 2200
                      : "",
                  behavior: "smooth",
                });
              }

              errors.homeClubState = !formData.homeClubState
                ? "State is required"
                : "";

              if (!_.isEmpty(errors.homeClubState)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 1200
                      : clubForm?.label === "Additional Club 1"
                      ? 1600
                      : clubForm?.label === "Additional Club 2"
                      ? 2000
                      : clubForm?.label === "Additional Club 3"
                      ? 2400
                      : "",
                  behavior: "smooth",
                });
              }

              // Validation for Guest fees
              errors.homeClubGuestFees = !formData.homeClubGuestFees
                ? "Guest fees are required"
                : isNaN(formData.homeClubGuestFees)
                ? "Guest fees must be a valid number"
                : "";

              if (!_.isEmpty(errors.homeClubGuestFees)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 1200
                      : clubForm?.label === "Additional Club 1"
                      ? 1600
                      : clubForm?.label === "Additional Club 2"
                      ? 2000
                      : clubForm?.label === "Additional Club 3"
                      ? 2400
                      : "",
                  behavior: "smooth",
                });
              }

              errors.homeClubUnaccompanied = !formData.homeClubUnaccompanied
                ? "Unaccompanied play field is required"
                : "";

              if (!_.isEmpty(errors.homeClubUnaccompanied)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 1300
                      : clubForm?.label === "Additional Club 1"
                      ? 1700
                      : clubForm?.label === "Additional Club 2"
                      ? 2100
                      : clubForm?.label === "Additional Club 3"
                      ? 2500
                      : "",
                  behavior: "smooth",
                });
              }

              errors.homeClubDescription =
                !formData.homeClubDescription ||
                formData.homeClubDescription.trim() === ""
                  ? "Description is required"
                  : "";
              if (!_.isEmpty(errors.homeClubDescription)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 1500
                      : clubForm?.label === "Additional Club 1"
                      ? 1900
                      : clubForm?.label === "Additional Club 2"
                      ? 2300
                      : clubForm?.label === "Additional Club 3"
                      ? 2700
                      : "",
                  behavior: "smooth",
                });
              }

              // Validate Course website
              errors.homeClubWebsite = !formData.homeClubWebsite
                ? "Course website is required"
                : !isValidURL(formData.homeClubWebsite)
                ? "Please enter a valid course website"
                : "";

              if (!_.isEmpty(errors.homeClubWebsite)) {
                scrollRef?.current?.scrollTo({
                  top:
                    clubForm?.label === "Secondary Club"
                      ? 1500
                      : clubForm?.label === "Additional Club 1"
                      ? 1900
                      : clubForm?.label === "Additional Club 2"
                      ? 2300
                      : clubForm?.label === "Additional Club 3"
                      ? 3000
                      : "",
                  behavior: "smooth",
                });
              }

              // Function to check if a string is a valid URL
              function isValidURL(url) {
                // Regular expression for URL validation
                // This regex checks for a standard http/https URL format
                var pattern = new RegExp(
                  "^(https?:\\/\\/)?" + // protocol
                    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                    "(\\#[-a-z\\d_]*)?$",
                  "i"
                ); // fragment locator

                return !!pattern.test(url);
              }

              // Check if secondary or additional club is filled and update isValid accordingly
              isValid =
                isValid && Object.values(errors).every((error) => error === "");
            } else {
              // Reset errors for secondary or additional clubs if they are not filled
              errors.homeClubName = "";
              errors.homeClubAddress = "";
              errors.homeClubType = "";
              errors.homeClubHoles = "";
              errors.homeClubCourses = "";
              errors.homeClubState = "";
              errors.homeClubGuestFees = "";
              errors.homeClubUnaccompanied = "";
              errors.homeClubDescription = "";
              errors.homeClubWebsite = "";
            }
          }

          return { ...clubForm, errors };
        });

      setClubForms(updatedForms);
      return isValid;
    },

    async getAddClub() {
      setClubLoader(true);
      let endPoints = Setting.endpoints.addClub;

      const paramsArray =
        _.isArray(clubForms) &&
        clubForms.map((clubForm, index) => {
          const formData = clubForm.formData;
          const formObj = {
            [`clubs[${index}][name]`]:
              formData?.homeClubName || formData?.homeClubNameObj?.name || "",
            ...(formData?.id !== null &&
              formData?.id !== undefined && {
                [`clubs[${index}][id]`]: formData.id || "",
              }),
            [`clubs[${index}][type]`]: formData.homeClubType || "",
            [`clubs[${index}][holes]`]: Number(formData.homeClubHoles) || "",
            [`clubs[${index}][courses]`]:
              Number(formData.homeClubCourses) || "",
            [`clubs[${index}][state]`]: formData.homeClubState || "",
            [`clubs[${index}][guest_fees]`]:
              Number(formData.homeClubGuestFees) || "",
            [`clubs[${index}][about_club]`]: formData.homeClubDescription || "",
            [`clubs[${index}][unaccompanied_pay]`]:
              formData.homeClubUnaccompanied
                ? formData.homeClubUnaccompanied == "yes"
                  ? 1
                  : 0
                : "",
            [`clubs[${index}][address]`]: formData.homeClubAddress || "",
            [`clubs[${index}][course_web_link]`]:
              formData.homeClubWebsite || "",
            ...(formData?.lat && { [`clubs[${index}][lat]`]: formData.lat }),
            ...(formData?.lng && { [`clubs[${index}][lng]`]: formData.lng }),
          };
          return formObj;
        });

      const filteredParamsArray = paramsArray.filter((params) =>
        Object.values(params).some((value) => value !== "")
      );

      const filteredParamsWithEmail = filteredParamsArray.map((params) => ({
        ...params,
        email: email || signUpData?.email,
      }));

      const params = Object.assign({}, ...filteredParamsWithEmail);
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.success) {
          setActiveStep(activeStep + 1);
        } else {
          toast.error(resp?.message, {
            position: "top-right",
          });
        }
        setClubLoader(false);
      } catch (error) {
        toast.error(error?.message, {
          position: "top-right",
        });
        setClubLoader(false);
      }
    },

    async commonData() {
      let endPoints = Setting.endpoints.commonData;
      const params = {
        email: email || signUpData.email || userData?.user_data?.email,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params);
        if (resp?.success) {
          if (!_.isEmpty(resp?.data)) {
            if (resp.data.club_data.length >= 2) {
              const updatedClubForms = resp?.data?.club_data?.map(
                (clubData, index) => {
                  if (index < resp.data.club_data.length) {
                    const newLabel =
                      index === 0
                        ? "Home Club"
                        : index === 1
                        ? "Secondary Club"
                        : `Additional Club ${index - 1}`;

                    const newType =
                      index === 0
                        ? "home"
                        : index === 1
                        ? "secondary"
                        : "additional";

                    const updatedFormData = {
                      ...clubData.formData,
                      homeClubNameObj: clubData,
                      homeClubName: clubData?.name,
                      homeClubType:
                        clubData?.type === "private"
                          ? "private"
                          : "semi-private",
                      homeClubAddress: clubData?.address,
                      homeClubHoles: clubData?.holes?.toString(),
                      homeClubCourses: clubData?.courses?.toString(),
                      homeClubState: clubData?.state,
                      homeClubGuestFees: clubData?.guest_fees,
                      homeClubUnaccompanied:
                        clubData?.unaccompanied_pay == 0 ? "no" : "yes",
                      homeClubDescription: clubData?.about_club,
                      homeClubWebsite: clubData?.course_web_link,
                      lat: !_.isEmpty(clubData) && clubData?.lat,
                      lng: !_.isEmpty(clubData) && clubData?.lng,
                      id: !_.isEmpty(clubData) && clubData?.id,
                      isDisabled: !_.isEmpty(clubData) && true,
                      isDisabledAdd: !_.isEmpty(clubData) && true,
                    };

                    return {
                      ...clubData,
                      label: newLabel,
                      type: newType,
                      errors: {},
                      formData: updatedFormData,
                    };
                  }
                }
              );

              setClubForms(updatedClubForms);
            } else {
              const updatedClubForms = clubForms?.map((clubForm, index) => {
                if (index < resp.data.club_data.length) {
                  const clubData = resp.data.club_data[index];

                  const updatedFormData = {
                    ...clubForm.formData,
                    homeClubNameObj: clubData,
                    homeClubName: clubData?.name,
                    homeClubType:
                      clubData?.type === "private" ? "private" : "semi-private",
                    homeClubAddress: clubData?.address,
                    homeClubHoles: clubData?.holes?.toString(),
                    homeClubCourses: clubData?.courses?.toString(),
                    homeClubState: clubData?.state,
                    homeClubGuestFees: clubData?.guest_fees,
                    homeClubUnaccompanied:
                      clubData?.unaccompanied_pay == 0 ? "no" : "yes",
                    homeClubDescription: clubData?.about_club,
                    homeClubWebsite: clubData?.course_web_link,
                    lat: !_.isEmpty(clubData) && clubData?.lat,
                    lng: !_.isEmpty(clubData) && clubData?.lng,
                    id: !_.isEmpty(clubData) && clubData?.id,
                    isDisabled: !_.isEmpty(clubData) && true,
                    isDisabledAdd: !_.isEmpty(clubData) && true,
                  };

                  return {
                    ...clubForm,
                    formData: updatedFormData,
                  };
                } else {
                  return clubForm;
                }
              });

              setClubForms(updatedClubForms);
            }
          }
        } else {
          toast.error(resp?.message, {
            position: "top-right",
          });
        }
      } catch (error) {
        toast.error(error?.message, {
          position: "top-right",
        });
      }
    },
  }));

  async function commonData() {
    let endPoints = Setting.endpoints.commonData;
    const params = {
      email: email || signUpData.email || userData?.user_data?.email,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          if (resp?.data?.club_data?.length >= 2) {
            const updatedClubForms = resp?.data?.club_data?.map(
              (clubData, index) => {
                if (index < resp?.data?.club_data?.length) {
                  const newLabel =
                    index === 0
                      ? "Home Club"
                      : index === 1
                      ? "Secondary Club"
                      : `Additional Club ${index - 1}`;

                  const newType =
                    index === 0
                      ? "home"
                      : index === 1
                      ? "secondary"
                      : "additional";

                  const updatedFormData = {
                    ...clubData.formData,
                    homeClubNameObj: clubData,
                    homeClubName: clubData?.name,
                    homeClubType:
                      clubData?.type === "private" ? "private" : "semi-private",
                    homeClubAddress: clubData?.address,
                    homeClubHoles: clubData?.holes?.toString(),
                    homeClubCourses: clubData?.courses?.toString(),
                    homeClubState: clubData?.state,
                    homeClubGuestFees: clubData?.guest_fees,
                    homeClubUnaccompanied:
                      clubData?.unaccompanied_pay == 0 ? "no" : "yes",
                    homeClubDescription: clubData?.about_club,
                    homeClubWebsite: clubData?.course_web_link,
                    lat: !_.isEmpty(clubData) && clubData?.lat,
                    lng: !_.isEmpty(clubData) && clubData?.lng,
                    id: !_.isEmpty(clubData) && clubData?.id,
                    isDisabled: !_.isEmpty(clubData) && true,
                    isDisabledAdd: !_.isEmpty(clubData) && true,
                  };

                  return {
                    ...clubData,
                    label: newLabel,
                    type: newType,
                    errors: {},
                    formData: updatedFormData,
                  };
                }
              }
            );

            setClubForms(updatedClubForms);
          } else {
            const updatedClubForms = clubForms?.map((clubForm, index) => {
              if (index < resp?.data?.club_data?.length) {
                const clubData = resp?.data?.club_data[index];

                const updatedFormData = {
                  ...clubForm.formData,
                  homeClubNameObj: clubData,
                  homeClubName: clubData?.name,
                  homeClubType:
                    clubData?.type === "private" ? "private" : "semi-private",
                  homeClubAddress: clubData?.address,
                  homeClubHoles: clubData?.holes?.toString(),
                  homeClubCourses: clubData?.courses?.toString(),
                  homeClubState: clubData?.state,
                  homeClubGuestFees: clubData?.guest_fees,
                  homeClubUnaccompanied:
                    clubData?.unaccompanied_pay == 0 ? "no" : "yes",
                  homeClubDescription: clubData?.about_club,
                  homeClubWebsite: clubData?.course_web_link,
                  lat: !_.isEmpty(clubData) && clubData?.lat,
                  lng: !_.isEmpty(clubData) && clubData?.lng,
                  id: !_.isEmpty(clubData) && clubData?.id,
                  isDisabled: !_.isEmpty(clubData) && true,
                  isDisabledAdd: !_.isEmpty(clubData) && true,
                };

                return {
                  ...clubForm,
                  formData: updatedFormData,
                };
              } else {
                return clubForm;
              }
            });

            setClubForms(updatedClubForms);
          }
        }
      } else {
        toast.error(resp?.message, {
          position: "top-right",
        });
      }
    } catch (error) {
      toast.error(error?.message, {
        position: "top-right",
      });
    }
  }

  const removeClubForm = (index) => {
    const updatedForms = [...clubForms];
    updatedForms.splice(index, 1);
    setClubForms(updatedForms);
  };

  const addClubForm = () => {
    const newLabel = `Additional Club ${clubForms.length - 1}`;
    const newAdditionalLabel = `Additional ${clubForms.length + 1}`;
    const newForm = {
      type: "additional",
      label: newLabel,
      additionalLabel: newAdditionalLabel,
      formData: {},
      errors: {},
    };
    setClubForms([...clubForms, newForm]);
  };

  const renderClubForm = useCallback(
    (clubForm, index) => {
      const formData = clubForm?.formData;
      const homeClubNameObj = formData?.homeClubNameObj || {};

      const errors = clubForm?.errors;
      const isSecondaryClub = clubForm?.type === "secondary";
      const isAdditionalClub = clubForm?.type === "additional";
      const isRequired = () => {
        if (isSecondaryClub || isAdditionalClub) {
          return Object.values(formData).some((value) => value !== "");
        }

        return true;
      };

      return (
        <Grid item xs={12} mt={index > 0 ? 2 : 0}>
          <Grid item xs={12}>
            <Grid container xs={12}>
              <Grid item xs={12} md={6}>
                <Typography
                  style={{
                    fontFamily: FontFamily.Bold,
                    fontSize: 20,
                    color: BaseColor.blackColor,
                  }}
                >
                  {clubForm?.label}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} container justifyContent="flex-end">
                {isAdditionalClub && (
                  <Grid
                    item
                    style={{ backgroundColor: "#EDF1F2", borderRadius: 30 }}
                  >
                    <IconButton onClick={() => removeClubForm(index)}>
                      <RiDeleteBin6Line size={18} color="red" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                row
                value={formData?.homeClubType || ""}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontFamily: FontFamily.Medium,
                    color: BaseColor.textColor,
                    fontSize: md ? 14 : 18,
                  },
                  "& .css-10ks82k-MuiButtonBase-root-MuiRadio-root": {
                    color: BaseColor.primary,
                  },
                }}
                onChange={(e) => {
                  handleFieldChange(index, { homeClubType: e.target.value });
                }}
              >
                <FormControlLabel
                  value="private"
                  control={
                    <Radio
                      // disabled={formData?.isDisabled ? true : false}
                      style={{
                        paddingLeft: "0px",
                      }}
                    />
                  }
                  label="Private"
                />
                <FormControlLabel
                  value="semi-private"
                  control={
                    <Radio
                    //  disabled={formData?.isDisabled ? true : false}
                    />
                  }
                  label="Semi-private"
                />

                {/* Clear Button */}
                {formData?.homeClubType && (
                  <Button
                    onClick={() =>
                      handleFieldChange(index, { homeClubType: "" })
                    }
                    sx={{
                      minWidth: "30px",
                      height: "30px",
                      marginLeft: "8px",
                      marginTop: "6px",

                      fontSize: "16px",
                      padding: "0px",
                      color: BaseColor.errorRed,
                    }}
                  >
                    <CloseIcon />
                  </Button>
                )}
              </RadioGroup>

              {isRequired() && errors?.homeClubType && (
                <Typography
                  style={{
                    fontSize: 14,
                    color: BaseColor.errorRed,
                    fontFamily: FontFamily.Medium,
                  }}
                  variant="caption"
                  color="error"
                >
                  {errors?.homeClubType}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              display={"flex"}
              justifyContent={"space-between"}
              // alignItems={"center"}
            >
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: FontFamily.SemiBold,
                    fontSize: 16,
                    marginTop: 2,
                  }}
                >
                  {`Name of your ${clubForm?.label}`}
                </Typography>

                <CAutoComplete
                  isEdit={formData?.isDisabled}
                  id={`${clubForm?.label}`}
                  index={index}
                  valueObj={homeClubNameObj}
                  homeClubNameErr={errors?.homeClubName}
                  // handleSelect={(e, ind) => {
                  //   handleFieldChange(ind, {
                  //     homeClubName: e.target.value,
                  //   });
                  // }}
                  selectedClubs={selectedClubs}
                  handleFieldChange={(value, ind) => {
                    if (!_.isEmpty(value)) {
                      setSelectedClub(value);
                      // Update selected clubs list
                      setSelectedClubs((prevSelectedClubs) => {
                        const updatedSelectedClubs = [...prevSelectedClubs];
                        updatedSelectedClubs[index] = value;
                        return updatedSelectedClubs;
                      });
                      handleFieldChange(ind, {
                        homeClubNameObj: value,
                        homeClubType: !_.isNull(value?.type)
                          ? value?.type === "private"
                            ? "private"
                            : "semi-private"
                          : formData?.homeClubType,
                        homeClubName: value?.name,
                        homeClubAddress: value?.address,
                        homeClubState: value?.state,
                        homeClubGuestFees: value?.guest_fees,
                        homeClubCourses:
                          value?.courses && value?.courses.toString(),
                        homeClubHoles: value?.holes && value?.holes.toString(),
                        homeClubUnaccompanied: !_.isNull(value?.type)
                          ? value?.unaccompanied_pay == 0
                            ? "no"
                            : "yes"
                          : formData?.homeClubUnaccompanied,
                        homeClubDescription: value?.about_club,
                        homeClubWebsite: value?.course_web_link,
                        lat: value.lat,
                        lng: value.lng,
                        id: value.id,
                        isDisabled: _.isEmpty(value?.about_club) ? false : true,
                        isDisabledAdd: value?.address ? true : false,
                      });
                    } else {
                      setSelectedClub(value);
                      // Clear the selected club from the selected clubs list
                      const clearedSelectedClubs = [...selectedClubs];
                      clearedSelectedClubs[index] = null;
                      setSelectedClubs(clearedSelectedClubs);
                      handleFieldChange(ind, {
                        homeClubNameObj: value,
                        homeClubType: null,
                        homeClubName: null,
                        homeClubAddress: null,
                        homeClubState: null,
                        homeClubGuestFees: null,
                        homeClubCourses: null,
                        homeClubHoles: null,
                        homeClubUnaccompanied: null,
                        homeClubDescription: null,
                        homeClubWebsite: null,
                        lat: null,
                        lng: null,
                        id: null,
                        isDisabled: false,
                        isDisabledAdd: false,
                      });
                    }
                  }}
                  value={formData?.homeClubName}
                  onChange={(e, ind) => {
                    handleFieldChange(ind, {
                      homeClubName: e.target.value,
                    });
                  }}
                  onCustomInputChange={(bool) => {
                    setIsCustomInput(bool);
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                {isCustomInput && _.isEmpty(formData.homeClubAddress) ? (
                  <PlacesAutocomplete
                    onChange={(e) => {
                      const { location, lat, lng, state } = e;
                      updateForm(index, {
                        homeClubAddress: location,
                        lat,
                        lng,
                        homeClubState: state,
                        id: "",
                      });
                    }}
                    value={formData?.homeClubAddress || ""}
                    endIcon
                    disabled={formData?.isDisabledAdd ? true : false}
                    error={isRequired() && errors?.homeClubAddress}
                    errorMsg={isRequired() && errors?.homeClubAddress}
                  />
                ) : (
                  <CInput
                    label="Address"
                    value={formData?.homeClubAddress || ""}
                    onChange={(e) => {
                      handleFieldChange(index, { homeClubAddress: e });
                    }}
                    addressBool
                    placeholder="Address"
                    fullWidth
                    disabled={
                      formData?.isDisabledAdd || !formData.homeClubName
                        ? true
                        : false
                    }
                    error={isRequired() && errors?.homeClubAddress}
                    errorMsg={isRequired() && errors?.homeClubAddress}
                    endIcon={<CiLocationOn color={BaseColor.textColor} />}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} mb={!sm && "20px"}>
            <Grid container display={"flex"} justifyContent={"space-between"}>
              <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8}>
                <CInput
                  label="Holes"
                  value={formData?.homeClubHoles || ""}
                  onChange={(e) => {
                    const numericValue = e.replace(/[^0-9]/g, "");

                    if (
                      numericValue === "" ||
                      (parseInt(numericValue) >= 0 &&
                        parseInt(numericValue) <= 100)
                    ) {
                      handleFieldChange(index, { homeClubHoles: numericValue });
                    }
                  }}
                  placeholder="Holes"
                  fullWidth
                  // disabled={formData?.isDisabled ? true : false}
                  error={isRequired() && errors?.homeClubHoles}
                  errorMsg={isRequired() && errors?.homeClubHoles}
                />
              </Grid>
              <Grid item xs={5.8} sm={5.8} md={5.8} lg={5.8}>
                <CSelect
                  label="Courses"
                  value={formData?.homeClubCourses || ""}
                  data={[
                    { id: 1, name: "1" },
                    { id: 2, name: "2" },
                    { id: 3, name: "3" },
                    { id: 4, name: "4" },
                    { id: 5, name: "5" },
                    { id: 6, name: "6" },
                    { id: 7, name: "7" },
                    { id: 8, name: "8" },
                    { id: 9, name: "9" },
                    { id: 10, name: "10" },
                  ]}
                  // disabled={formData?.isDisabled ? true : false}
                  placeholder="Select Option"
                  onSelect={(val) =>
                    handleFieldChange(index, { homeClubCourses: val })
                  }
                  fullWidth
                  error={isRequired() && errors?.homeClubCourses}
                  errorMsg={isRequired() && errors?.homeClubCourses}
                />
              </Grid>
            </Grid>

            <Grid display={"flex"} justifyContent={"space-between"} container>
              <Grid item xs={5.8} lg={5.8}>
                <CInput
                  label="State"
                  value={formData?.homeClubState || ""}
                  onChange={(e) => {
                    const newValue = e.replace(/[^A-Za-z]/g, "");
                    handleFieldChange(index, { homeClubState: newValue });
                  }}
                  placeholder="State"
                  fullWidth
                  disabled={formData?.isDisabledAdd ? true : false}
                  error={isRequired() && errors?.homeClubState}
                  errorMsg={isRequired() && errors?.homeClubState}
                />
              </Grid>
              <Grid item xs={5.8} lg={5.8}>
                <CInput
                  label="Guest Fees"
                  value={`$${formData?.homeClubGuestFees || ""}`}
                  onChange={(e) => {
                    let numericValue = e.replace(/[^0-9]/g, ""); // Allow only numeric input
                    if (numericValue > 1000) {
                      setError(true);
                    } else {
                      setError(false);
                    }
                    handleFieldChange(index, {
                      homeClubGuestFees: numericValue,
                    });
                  }}
                  maxLength={5}
                  // disabled={formData?.isDisabled ? true : false}
                  placeholder="$"
                  fullWidth
                  error={error || (isRequired() && errors?.homeClubGuestFees)}
                  errorMsg={
                    error
                      ? "Value should not exceed $1000"
                      : isRequired() && errors?.homeClubGuestFees
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container display={"flex"} justifyContent={"space-between"}>
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8}>
                <Typography
                  sx={{
                    color: "black",
                    fontFamily: FontFamily.SemiBold,
                    fontSize: 16,
                    marginTop: 2,
                  }}
                >
                  Does your course allow unaccompanied play?
                </Typography>
                <RadioGroup
                  row
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontFamily: FontFamily.Medium,
                      color: BaseColor.textColor,
                      fontSize: md ? 14 : 18,
                    },
                    "& .css-10ks82k-MuiButtonBase-root-MuiRadio-root": {
                      color: BaseColor.primary,
                    },
                  }}
                  value={formData?.homeClubUnaccompanied || ""}
                  onChange={(e) => {
                    handleFieldChange(index, {
                      homeClubUnaccompanied: e.target.value,
                    });
                  }}
                >
                  <FormControlLabel
                    value="yes"
                    control={
                      <Radio
                        // disabled={formData?.isDisabled ? true : false}
                        style={{ paddingLeft: "0px" }}
                      />
                    }
                    label="Yes"
                  />
                  <FormControlLabel
                    value="no"
                    control={
                      <Radio
                      // disabled={formData?.isDisabled ? true : false}
                      />
                    }
                    label="No"
                  />
                </RadioGroup>
                {isRequired() && errors?.homeClubUnaccompanied && (
                  <Typography
                    variant="caption"
                    color="error"
                    style={{
                      fontSize: 14,
                      color: BaseColor.errorRed,
                      fontFamily: FontFamily.Medium,
                    }}
                    className={classes.errorMsgTxt}
                  >
                    {errors?.homeClubUnaccompanied}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={5.8} md={5.8} lg={5.8} mb={md && "20px"}>
                <CInput
                  endIcon
                  label="Tell us about your golf club."
                  value={formData?.homeClubDescription || ""}
                  onChange={(e) =>
                    handleFieldChange(index, { homeClubDescription: e })
                  }
                  // disabled={formData?.isDisabled ? true : false}
                  placeholder="Brief description"
                  multiline
                  rows={4}
                  maxLength={300}
                  fullWidth
                  error={isRequired() && errors?.homeClubDescription}
                  errorMsg={isRequired() && errors?.homeClubDescription}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={5.8} lg={5.8}>
            <CInput
              label="Course website"
              value={formData?.homeClubWebsite || ""}
              onChange={(e) => {
                handleFieldChange(index, { homeClubWebsite: e });
              }}
              placeholder="Course website"
              fullWidth
              error={isRequired() && errors?.homeClubWebsite}
              errorMsg={isRequired() && errors?.homeClubWebsite}
            />
          </Grid>

          {index < clubForms.length - 1 && (
            <Divider style={{ marginTop: "30px" }} />
          )}
        </Grid>
      );
    },
    [clubForms, selectedClub]
  );

  return (
    <Grid
      container
      height={isMobile || isTablet ? "calc(100% - 130px)" : "88%"}
      style={{
        overflowY: "auto",
        paddingTop: !md && "20px",
        paddingBottom: md && "8vh",
      }}
      className={classes.scrollBar}
      ref={scrollRef}
    >
      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
      <Grid item xs={11} sm={11} md={10} lg={10.5}>
        {md && <CStepper activeStep={1} steps={steps} />}

        {md ? (
          <div
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: "15px",
              marginBottom: "10px",
            }}
          >
            <Grid
              item
              xs={5.7}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  color: BaseColor.blackColor,
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: md ? 18 : 22,
                }}
              >
                Club Membership
              </Typography>
            </Grid>
            {md && (
              <Grid
                item
                xs={5.7}
                alignItems={"flex-end"}
                justifyContent={"flex-end"}
                display={"flex"}
              >
                {clubForms?.length <= 4 && (
                  <Button variant="containedSecondary" onClick={addClubForm}>
                    + Add Additional Club
                  </Button>
                )}
              </Grid>
            )}
          </div>
        ) : (
          <Grid item xs={12} mt={"20px"}>
            <Typography
              style={{
                color: BaseColor.blackColor,
                fontFamily: FontFamily.GilbertQualifiDemo,
                fontSize: 22,
              }}
            >
              Club Membership
            </Typography>
            <hr
              style={{
                borderTop: "1px solid white",
                marginBottom: 16,
                marginTop: 16,
              }}
            />
          </Grid>
        )}
        {_.isArray(clubForms) &&
          clubForms.length > 0 &&
          clubForms?.map((clubForm, index) => {
            return (
              <Grid key={index} item xs={12}>
                {renderClubForm(clubForm, index)}
              </Grid>
            );
          })}

        {!md && (
          <Grid item xs={12} mt={4} style={{ paddingBottom: "30px" }}>
            {clubForms?.length <= 4 && (
              <Button variant="containedSecondary" onClick={addClubForm}>
                + Add Additional Club
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item xs={0.5} sm={0.5} md={0.5} lg={0.5}></Grid>
    </Grid>
  );
});

export default ClubMembership;
