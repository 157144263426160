import React, { useState, useRef, useEffect } from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import CloseIcon from "@mui/icons-material/Close";
import Files from "../../Config/Files";
import EmojiPicker from "emoji-picker-react";
import styles from "./styles";
import { socket } from "../../Context/ContextSocket";
import { useSelector } from "react-redux";
import _ from "lodash";
import { toast } from "react-toastify";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
export default function SendMessageInput(props) {
  const {
    setSendedMessage,
    handlePageNo = () => {},
    particularUser,
    handleOpenParticularchat,
    handleIsTyping = () => {},
    handleIsStopTyping,
    habdlegetChatList = () => {},
    onBlur = () => {},
    onFocus = () => {},
    isDnd = false,
  } = props;

  // style
  const classes = styles();

  // Ref for camera
  const videoRef = useRef(null);

  // Ref for emoji picker
  const emojiPickerRef = useRef(null);

  // Ref for file input
  const fileInputRef = useRef(null);

  // Ref for typing timeout
  const typingTimeoutRef = useRef(null);

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [isTypingData, setIsTypingData] = useState(null);
  const [base64, setBase64] = useState(null);
  const [text, setText] = useState("");
  const [files, setFiles] = useState([]);

  const { userData, activeMenu } = useSelector((state) => state.auth);

  const checkPermissions = async () => {
    if (!navigator.permissions) {
      console.warn("Permissions API is not supported in this browser.");
      return;
    }

    try {
      const permission = await navigator.permissions.query({ name: "camera" });
      if (permission.state === "denied") {
        alert(
          "Camera access is denied. Please update your browser settings to allow camera access."
        );
      }
    } catch (err) {
      console.error("Error querying permissions:", err);
    }
  };

  const openCamera = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      console.error("getUserMedia is not supported in this browser.");
      alert("Camera access is not supported in this browser.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      } else {
        console.error("videoRef.current is not available");
      }
    } catch (error) {
      console.error("Error accessing camera:", error);
      alert(`Error accessing camera: ${error.message}`);
      handleCameraError(error);
    }
  };

  const handleCameraError = (error) => {
    switch (error.name) {
      case "NotAllowedError":
        alert(
          "Permission denied. Please allow camera access in your browser settings."
        );
        break;
      case "NotFoundError":
        alert("No camera found on the device.");
        break;
      case "NotReadableError":
        alert("Camera is already in use by another application.");
        break;
      case "OverconstrainedError":
        alert("No camera devices meet the specified constraints.");
        break;
      case "SecurityError":
        alert("Security error: HTTPS is required.");
        break;
      default:
        alert("An unknown error occurred.");
        break;
    }
  };

  useEffect(() => {
    checkPermissions();
  }, []);
  useEffect(() => {
    receiveTyping();
    receiveStopTyping();
  }, []);

  const handleEmojiClick = (emojiData, event) => {
    setText((prevText) => prevText + emojiData.emoji);
  };

  const handleClickOutside = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target)
    ) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    if (showEmojiPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showEmojiPicker]);

  const handleAttachmentClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);

    // Convert each new file to base64
    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        // Do whatever you want with the base64String, such as sending it to the server
        setBase64(base64String);
      };
      reader.readAsDataURL(file);
    });
    // Convert each new file to base64
    newFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        // Do whatever you want with the base64String, such as sending it to the server
        setBase64(base64String);
      };
      reader.readAsDataURL(file);
    });
  };

  const removeFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const fileTypesMap = {
    "image/png": "image",
    "image/jpeg": "image",
    "image/gif": "image",
    "image/bmp": "image",
    "image/svg+xml": "image",
    "video/mp4": "video",
    "video/mpeg": "video",
    "application/pdf": "pdf",
    "application/msword": "word",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      "doc",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      "excel",
  };

  function getBackendFileType(file) {
    const mimeType = file.type;
    return fileTypesMap[mimeType] || "unknown";
  }

  const handleSendMessages = () => {
    const message = {
      text,
      media: files,
    };

    if (!message.text.trim() && message.media.length === 0) {
      console.warn("Cannot send empty message.");
      return;
    }

    setSendedMessage(message);

    setText("");
    setFiles([]);
    let fileType = !_.isEmpty(message.media)
      ? getBackendFileType(message.media[0])
      : "text";

    const requestdata = {
      file_type: fileType,
      s_id: userData?.id,
      file_data: base64,
      c_id: particularUser.conversation_id,
      msg: message.text,
    };

    socket.emit("send_message", requestdata, (response) => {
      if (response.status === false) {
        toast(response?.message, {
          type: "error",
        });
      } else {
        if (!isDnd) {
          sendNotification();
        }
      }

      if (activeMenu === "archiveList") {
        props.handleOpenParticularchat(particularUser, 1, false, activeMenu);
      } else {
        props.handleOpenParticularchat(particularUser, 1, false, "send");
      }
      handlePageNo();
      habdlegetChatList();
    });
  };

  const isTyping = () => {
    const data = {
      s_id: userData?.id,
      c_id: particularUser.conversation_id,
    };
    socket.emit("is_typing", data, () => {});
  };

  const receiveTyping = () => {
    socket.on("receive_typing", (req, res) => {
      setIsTypingData(req.data);
      handleIsTyping(req.data);
    });
  };

  const stopTyping = () => {
    const data = {
      s_id: userData?.id,
      c_id: particularUser.conversation_id,
    };
    socket.emit("is_stop_typing", data, () => {});
  };
  const receiveStopTyping = () => {
    socket.on("stop_typing", (req, res) => {
      setIsTypingData({});
      handleIsTyping(null);
    });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSendMessages();
    }
  };

  const sendNotification = async () => {
    let endPoints = Setting.endpoints.sendNotification;

    const data = {
      member_id: particularUser?.receiver_id,
      message: text,
      title: `${userData?.nick_name} sent you a chat message`,
      club_id: userData?.club_data[0]?.id || "",
    };
    console.log("userData", userData);

    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
      } else {
      }
    } catch (error) {
      toast("Something went wrong", { type: "error" });
    }
  };

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
        className={classes.inputMessage}
      >
        <IconButton
          sx={{ p: "10px" }}
          aria-label="menu"
          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
        >
          <SentimentSatisfiedAltIcon />
        </IconButton>
        <div
          style={{
            flex: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1, width: "100%" }}
            placeholder="Type message..."
            inputProps={{ "aria-label": "type message" }}
            value={text}
            onChange={(e) => {
              setText(e.target.value);
              isTyping();
              if (!_.isNull(typingTimeoutRef.current)) {
                clearTimeout(typingTimeoutRef.current);
                typingTimeoutRef.current = null;
              }
              typingTimeoutRef.current = setTimeout(stopTyping, 1500);
            }}
            onKeyDown={handleKeyDown}
            onBlur={onBlur}
            onFocus={onFocus}
          />

          <div style={{ marginTop: "8px" }}>
            {files.map((file, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "#f5f5f5",
                  padding: "4px 8px",
                  borderRadius: "4px",
                  marginBottom: "4px",
                }}
              >
                <span style={{ marginRight: "8px" }}>{file.name}</span>
                <IconButton
                  aria-label="remove"
                  size="small"
                  onClick={() => removeFile(index)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
        {/* <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="attachment"
          onClick={handleAttachmentClick}
        >
          <img src={Files.pngIcons.attachment} alt="attachment" />
        </IconButton> */}
        {/* <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}

          // multiple
        />
        <IconButton
          color="primary"
          sx={{ p: "10px" }}
          aria-label="camera"
          onClick={openCamera}
        >
          <img src={Files.pngIcons.cameraIcon} alt="camera" />
        </IconButton> */}
        {/* <video ref={videoRef} style={{ display: "none" }} autoPlay muted /> */}

        {showEmojiPicker && (
          <div
            ref={emojiPickerRef}
            style={{
              position: "absolute",
              bottom: "60px",
              zIndex: 1,
            }}
          >
            <EmojiPicker
              onEmojiClick={handleEmojiClick}
              autoFocusSearch={false}
              searchDisabled
              categories={[
                "suggested",
                "custom",
                "smileys_people",
                "animals_nature",
                "food_drink",
                "travel_places",
                "activities",
                "objects",
                "symbols",
              ]}
            />
          </div>
        )}
      </Paper>
      <img
        src={Files.Images.sendMessage}
        onClick={() => handleSendMessages()}
        width="55px"
        draggable={false}
      />
    </>
  );
}
