import {
  Avatar,
  Badge,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { toast } from "react-toastify";
import PollIcon from "@mui/icons-material/Poll";
import { CiSearch } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";
import _, { isArray, isEmpty } from "lodash";
import { FaAngleDown, FaAngleUp, FaArrowLeft } from "react-icons/fa6";
import { useSelector } from "react-redux";
import moment from "moment/moment";
import { useDispatch } from "react-redux";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import styles from "./chatSidebar.styles";
import BaseColor from "../../../Config/colors";
import theme, { FontFamily } from "../../../Config/theme";
import CInput from "../../../Components/CInput";
import Files from "../../../Config/Files";
import StyledBadge from "../../../Components/StyledBadge/StyledBadge";
import {
  archiveContactList,
  blockContactList,
  chatContactList,
  chatGroupList,
} from "../../../Config/staticData";
import { socket } from "../../../Context/ContextSocket";
import authActions from "../../../Redux/reducers/auth/actions";
import { store } from "../../../Redux/store/configureStore";
import { motion } from "framer-motion";

const ChatSidebar = forwardRef((props, ref) => {
  const {
    setPageLoader,
    listType,
    setListType,
    setOpenChat,
    onlineUsersList,
    setParticularUser,
    handleOpenParticularchat,
    updateList = () => null,
    style,
  } = props;

  // Set data in Redux
  const dispatch = useDispatch();
  const { setSelectedChatData, setCTab, setActiveMenu, setIsUnread } =
    authActions;

  // responsive design
  const classes = styles();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [chatListData, setChatListData] = useState([]);

  // tab
  const [selectedTab, setSelectedTab] = useState("personal");

  // loader
  const [chatListLoader, setChatListLoader] = useState(false);

  const { userData, selectedChatData, cTab, activeMenu } = useSelector(
    (state) => state.auth
  );
  const [selectedTabSubData, setSelectedTabSubData] = useState("");

  // --------- Popover Start ------------
  const [anchorEl, setAnchorEl] = useState(null);
  const [popId, setPopId] = useState(null);
  const [open, setOpen] = useState(false);
  const [openMenuPop, setOpenMenuPop] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [activeTypingId, setActiveTypingId] = useState("");
  const [selectedItem, setSelectedItem] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const isChangeTab = searchParams.get("changeTab");

  useEffect(() => {
    setSelectedTab(cTab);
    getChatList(cTab, "initial", {}, true);
    receiveTyping(); // this will get receive typing emit
    receiveStopTyping(); // this will get stop typing emit
    receiveMessage(); // this will get receive message emit
  }, []);

  // this function for access functions using reference
  useImperativeHandle(ref, () => ({
    callChat() {
      getChatList(cTab); // to get latest chat list
    },
    blockList() {
      handleShowBlockList(cTab); // to get latest blocked user list
    },
    archiveList() {
      handleShowArchiveList(cTab); // to get latest archive user list
    },
    callChatList() {
      getChatList(cTab);
    },
    changeTab() {
      setSelectedTab(cTab);
    },
  }));

  useEffect(() => {
    if (isChangeTab === "true") {
      setSelectedTab("groups");
      getChatList("groups", "", {}, true);
      dispatch(setCTab("groups"));
      dispatch(setSelectedChatData({}));
      setParticularUser({});
      const params = {};
      setSearchParams(createSearchParams(params), { replace: true });
      if (isMobile || md) {
        setOpenChat({ open: false, data: {} });
      }
    }
  }, [isChangeTab]);

  useEffect(() => {
    if (searchParams.has("c_id") && !_.isEmpty(chatListData)) {
      const selectedChat = chatListData?.find(
        (chat) => chat?.conversation_id === searchParams.get("c_id")
      );
      dispatch(setSelectedChatData(selectedChat));
      setParticularUser(selectedChat);
      handleOpenParticularchat(1);
      setSearchParams({}, { replace: true });
      setOpenChat({ open: false, data: {} });
      dispatch(setCTab("personal"));
    }
  }, [chatListData]);

  useEffect(() => {
    if (searchParams.has("c_id") && !_.isEmpty(chatListData)) {
      if (
        searchParams.has("conv_type") &&
        searchParams.get("conv_type") === "personal"
      ) {
        const selectedChat = chatListData?.find(
          (chat) => chat?.conversation_id === searchParams.get("c_id")
        );
        dispatch(setSelectedChatData(selectedChat));
        setParticularUser(selectedChat);
        handleOpenParticularchat(1);
        setSearchParams({}, { replace: true });
        setOpenChat({ open: false, data: {} });
        dispatch(setCTab("personal"));
      } else {
        if (
          searchParams.has("conv_type") &&
          searchParams.get("conv_type") === "groups"
        ) {
          console.log("selectedChat======>>>>>", searchParams.get("c_id"));

          dispatch(setCTab("groups"));
          const selectedChat = chatListData?.find(
            (chat) => chat?.conversation_id === searchParams.get("c_id")
          );
          dispatch(setSelectedChatData(selectedChat));
          setParticularUser(selectedChat);
          handleOpenParticularchat(1);
          setSearchParams({}, { replace: true });
          setOpenChat({ open: false, data: {} });
        }
      }
    }
  }, [chatListData]);

  useEffect(() => {
    if (searchParams.has("single") && !_.isEmpty(chatListData)) {
      setSelectedChatData({});
      setSearchParams({}, { replace: true });
      setOpenChat({ open: false, data: {} });
      dispatch(setCTab("personal"));
    }
  }, [chatListData]);

  // this function for get chat list
  function getChatList(cType = "", type = "", nData, loader) {
    if (loader) {
      setChatListLoader(true);
    }
    const requestdata = {
      user_id: userData.id,
      type: "",
      chat_type: cType === "" || cType === "personal" ? "s" : "g",
      page: 1,
    };

    socket.emit("user_connection", requestdata, (response) => {
      console.log("response123", response);
      if (response.status) {
        if (
          isArray(response?.data?.conversations) &&
          response?.data?.conversations.length > 0
        ) {
          setChatListData(response?.data?.conversations);
        } else {
          setChatListData([]);
        }
        if (type === "update" && !isEmpty(nData)) {
          handleOpenParticularchat(nData);
        }
        setChatListLoader(false);
        const hasUnread = response?.data?.conversations?.some(
          (conversation) => conversation?.unread > 0
        );
        if (!hasUnread) {
          dispatch(setIsUnread(hasUnread));
        } else {
          dispatch(setIsUnread(hasUnread));
        }
      } else {
        setChatListData([]);
        setChatListLoader(false);
      }
      setPageLoader(false);
    });
  }

  // this function to show block list
  function handleShowBlockList(selectedTab, loader) {
    if (loader) {
      setChatListLoader(true);
    }
    const requestdata = {
      user_id: userData.id,
      type: selectedTab === "personal" ? "s" : "g",
      page: 1,
    };

    socket.emit("block_list", requestdata, (response) => {
      if (response.status) {
        if (
          isArray(response?.data?.conversations) &&
          response?.data?.conversations.length > 0
        ) {
          setChatListData(response?.data?.conversations);
          setChatListLoader(false);
        } else {
          setChatListData([]);
          setChatListLoader(false);
        }
      } else {
        setChatListData([]);
        setChatListLoader(false);
      }
      setChatListLoader(false);
    });
  }

  // this function to show archive list
  function handleShowArchiveList(selectedTab, loader) {
    if (loader) {
      setChatListLoader(true);
    }
    let type = "";
    if (selectedTab === "personal") {
      type = "s";
    } else {
      type = "g";
    }

    const requestdata = {
      user_id: userData.id,
      type: type,
      page: 1,
    };

    socket.emit("archive_list", requestdata, (response) => {
      if (response.status) {
        if (
          isArray(response?.data?.conversations) &&
          response?.data?.conversations.length > 0
        ) {
          setChatListData(response?.data?.conversations);
          setChatListLoader(false);
        } else {
          setChatListData([]);
          setChatListLoader(false);
        }
      } else {
        setChatListData([]);
        setChatListLoader(false);
      }
      setChatListLoader(false);
    });
  }

  // this function for delete conversation
  function handleDeleteConversation(concat) {
    const requestdata = {
      user_id: userData.id,
      conversation_id: concat.conversation_id,
    };
    socket.emit("delete_chat", requestdata, (response) => {
      if (response.status) {
        getChatList(cTab);
        dispatch(setSelectedChatData({}));
        setParticularUser("");
      } else {
        toast(response?.message, {
          type: "error",
        });
      }
    });
  }

  // this function for get typing emit
  const receiveTyping = () => {
    socket.on("receive_typing", (req) => {
      setActiveTypingId(req?.data?.c_id);
    });
  };

  // this function for get stop typing emit
  const receiveStopTyping = () => {
    socket.on("stop_typing", () => {
      setActiveTypingId("");
    });
  };

  // this function for get receive message emit
  const receiveMessage = () => {
    socket.on("receive_message", (data) => {
      const {
        auth: { activeMenu: aMenu, selectedChatData, cTab },
      } = store.getState();
      if (aMenu === "archiveList") {
        let newData = {};
        if (selectedChatData?.conversation_id === data?.data?.c_id) {
          newData = {
            conversation_id: data?.data.c_id,
          };

          const nData = {
            c_id: data?.data.c_id,
            s_id: userData.id,
          };
          socket.emit("read_all_messages", nData, (response) => {});
        }
        handleShowArchiveList(cTab === "personal" ? "personal" : "groups");
        handleOpenParticularchat(newData);
      } else if (aMenu === "blockList") {
        handleShowBlockList(cTab === "personal" ? "personal" : "groups");
      } else if (aMenu === "archiveList") {
        handleShowArchiveList(cTab === "personal" ? "personal" : "groups");
      } else {
        let newData = {};
        if (selectedChatData?.conversation_id === data?.data?.c_id) {
          newData = {
            conversation_id: data?.data.c_id,
          };

          const nData = {
            c_id: data?.data.c_id,
            s_id: userData.id,
          };
          socket.emit("read_all_messages", nData, (response) => {});
        }

        if (cTab === "personal") {
          getChatList("personal", "update", newData);
        } else if (cTab === "groups") {
          getChatList("groups", "update", newData);
        }
      }
    });
  };

  const handlePopClick = (event, id, contact) => {
    if (id === "menupop") {
      setAnchorEl(event.currentTarget);
      setOpenMenuPop(true);
    } else {
      setPopId(id);
      setAnchorEl(event.currentTarget);
      setOpen(true);
      setSelectedItem(contact);
    }
  };
  const handlePopClose = () => {
    setPopId(null);
    setAnchorEl(null);
    setOpenMenuPop(false);
    setOpen(false);
    setSelectedItem({});
  };

  // const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // ---------- Popover End -----------
  const [contactList, setContactList] = useState(chatContactList);

  const handleTabChange = (tab) => {
    if (tab === "personal") {
      setContactList(chatContactList);
    } else if (tab === "groups") {
      setContactList(chatGroupList);
    } else if (tab === "blockList") {
      dispatch(setActiveMenu(tab));
      setContactList(blockContactList);
    } else if (tab === "archiveList") {
      dispatch(setActiveMenu(tab));
      setContactList(archiveContactList);
    }
    setOpenMenuPop(false);
  };

  function handleRemoveFromBlockList(concat) {
    const requestdata = {
      conversation_id: concat.conversation_id,
      block_user_id: concat.receiver_id,
      block_by_user_id: userData.id,
    };

    socket.emit("unblock_chat", requestdata, (response) => {
      if (response.status === false) {
      } else {
        updateList("unblock");
        dispatch(setSelectedChatData({}));
        setOpenChat({ open: false, data: {} });
        setParticularUser("");
      }
    });
  }

  // this function for unarchive user
  function unarchiveUser(concat) {
    const requestdata = {
      user_id: userData.id,
      conversation_id: concat?.conversation_id,
    };

    socket.emit("unarchive_chat", requestdata, (response) => {
      if (response.status === false) {
      } else {
        updateList("unarchive");
        dispatch(setSelectedChatData({}));
        setOpenChat({ open: false, data: {} });
        setParticularUser("");
      }
    });
  }

  function handleUnblockUnarchive(concat) {
    if (activeMenu === "blockList") {
      handleRemoveFromBlockList(concat);
    } else {
      unarchiveUser(concat, selectedTab);
    }
  }

  useEffect(() => {
    handleTabChange(listType);
  }, [listType]);

  function dateToFromNowDaily(myDate) {
    const time = moment(myDate).calendar(null, {
      sameDay: `hh:mm:A`,
      lastDay: "[Yesterday]",
      nextWeek: "MM-DD-YYYY",
      lastWeek: "[last week]",
      sameElse: "MM-DD-YYYY",
    });
    return time;
  }

  // this function for filter conversations
  const filteredData = chatListData?.filter((item) => {
    if (!_.isEmpty(item?.username)) {
      return item?.username
        ?.toLowerCase()
        ?.includes(searchValue?.toLowerCase());
    } else {
      return item?.conv_name
        ?.toLowerCase()
        ?.includes(searchValue?.toLowerCase());
    }
  });

  return (
    <div className={!props.mobile && classes.mainConatiner} style={style}>
      <Grid
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        padding={"0px 20px"}
        width={md ? "100%" : "29vw"}
      >
        <CInput
          value={searchValue}
          onChange={(e) => {
            setSearchValue(e);
          }}
          placeholder="Search User"
          fullWidth
          startIcon={<CiSearch />}
          search
          endIcon={
            !_.isEmpty(searchValue) && (
              <IoCloseOutline
                onClick={() => {
                  setSearchValue("");
                }}
                style={{
                  cursor: !isMobile && !isTablet && "pointer",
                }}
              />
            )
          }
        />
        <div className={classes.menuCss}>
          <IconButton
            aria-describedby={"menu-popover"}
            aria-label="menu"
            onClick={(e) => handlePopClick(e, "menupop")}
          >
            <img
              alt="menu-icon"
              height={"25px"}
              src={Files.pngIcons.menuIcon}
              draggable={false}
            />
          </IconButton>
          <Popover
            id={"menu-popover"}
            open={openMenuPop}
            anchorEl={anchorEl}
            onClose={handlePopClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Grid
              style={{
                backgroundColor: BaseColor.white,
                padding: "12px 16px",
                borderRadius: 10,
              }}
            >
              <ListItemButton
                style={{
                  marginLeft: -8,
                  fontSize: 16,
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.blackColor,
                  gap: "5px",
                }}
                onClick={() => {
                  setListType("blockList");
                  setSelectedTabSubData("blockList");
                  setSelectedTab("personal");
                  dispatch(setCTab("personal"));
                  dispatch(setActiveMenu("blockList"));
                  setParticularUser({});
                  dispatch(setSelectedChatData({}));
                  handleShowBlockList("personal", true);
                }}
              >
                <img
                  alt="archive-icon"
                  width={"20px"}
                  src={Files.pngIcons.blockIcon}
                  draggable={false}
                />{" "}
                Block List
              </ListItemButton>
              <div
                style={{
                  borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
                }}
              />
              <ListItemButton
                style={{
                  marginLeft: -8,
                  fontSize: 16,
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.blackColor,
                  gap: "5px",
                }}
                onClick={() => {
                  setListType("archiveList");
                  setSelectedTabSubData("archiveList");
                  setSelectedTab("personal");
                  dispatch(setCTab("personal"));
                  dispatch(setActiveMenu("archiveList"));
                  handleShowArchiveList("personal", true);
                  setParticularUser({});
                  dispatch(setSelectedChatData({}));
                }}
              >
                <img
                  alt="archive-icon"
                  width={"20px"}
                  src={Files.pngIcons.archiveIcon}
                  draggable={false}
                />{" "}
                Archived
              </ListItemButton>
              <div
                style={{
                  borderBottom: `1px solid ${BaseColor.popOverSeperate}`,
                }}
              />
            </Grid>
          </Popover>
        </div>
      </Grid>
      {(activeMenu === "blockList" || activeMenu === "archiveList") && (
        <Grid
          padding={"14px 0px 0px 20px;"}
          display={"flex"}
          alignItems={"center"}
        >
          <FaArrowLeft
            onClick={() => {
              setListType("personal");
              setSelectedTabSubData("common");
              dispatch(setActiveMenu(""));
              getChatList(cTab || "", "", {}, true);
            }}
            style={{ cursor: !isMobile && !isTablet && "pointer" }}
          />
          <Grid display={"flex"} width={"100%"} justifyContent={"center"}>
            <Typography
              justifySelf={"center"}
              fontSize={"24px"}
              fontFamily={FontFamily.GilbertQualifiDemo}
              color={"#212121"}
            >
              {activeMenu === "blockList" ? "Block List" : "Archived"}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={5} display={"flex"} alignItems={"center"}>
        <div className={md ? classes.mobileSwitchDiv : classes.webSwitchDiv}>
          <button
            style={{
              border: "none",
              backgroundColor:
                selectedTab === "personal"
                  ? BaseColor.primary
                  : md
                  ? BaseColor.offWhiteBg
                  : BaseColor.white,
              color:
                selectedTab === "personal"
                  ? BaseColor.white
                  : BaseColor.blackColor,
              width: !md && "100%",
              fontFamily: FontFamily.Medium,
            }}
            onClick={() => {
              setSelectedTab("personal");
              dispatch(setCTab("personal"));
              if (activeMenu === "archiveList") {
                handleShowArchiveList("personal", true);
              } else if (activeMenu === "blockList") {
                handleShowBlockList("personal", true);
              } else {
                getChatList("personal", "", {}, true);
              }

              if (!md) {
                dispatch(setSelectedChatData({}));
                setOpenChat({ open: false, data: {} });
                setParticularUser({});
              }
            }}
            className={classes.switchTab}
          >
            <Typography
              color={
                selectedTab === "personal"
                  ? BaseColor.white
                  : BaseColor.blackColor
              }
              fontFamily={FontFamily.Medium}
            >
              Personal
            </Typography>
          </button>
          <button
            style={{
              border: "none",
              backgroundColor:
                selectedTab === "groups"
                  ? BaseColor.primary
                  : md
                  ? BaseColor.offWhiteBg
                  : BaseColor.white,
              color:
                selectedTab === "groups"
                  ? BaseColor.white
                  : BaseColor.blackColor,
              width: !md && "100%",
              fontFamily: FontFamily.Medium,
            }}
            onClick={() => {
              setSelectedTab("groups");
              dispatch(setCTab("groups"));
              if (activeMenu === "archiveList") {
                handleShowArchiveList("groups", true);
              } else if (activeMenu === "blockList") {
                handleShowBlockList("groups", true);
              } else {
                getChatList("groups", "", {}, true);
              }

              if (!md) {
                dispatch(setSelectedChatData({}));
                setOpenChat({ open: false, data: {} });
                setParticularUser({});
              }
            }}
            className={classes.switchTab}
          >
            <Typography
              color={
                selectedTab === "groups"
                  ? BaseColor.white
                  : BaseColor.blackColor
              }
              fontFamily={FontFamily.Medium}
            >
              Groups
            </Typography>
          </button>
        </div>
      </Grid>
      {chatListLoader ? (
        <Grid
          className="custom-scrollbar-horizontal"
          height={"calc(100vh - 320px)"}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={40} color="primary" />
        </Grid>
      ) : !isEmpty(filteredData) ? (
        <Grid
          className="custom-scrollbar-horizontal"
          height={
            md
              ? activeMenu === "archiveList"
                ? "calc(100vh - 370px)"
                : activeMenu === "blockList"
                ? "calc(100vh - 276px)"
                : "calc(100vh - 340px)"
              : activeMenu === "archiveList" || activeMenu === "blockList"
              ? "calc(100vh - 310px)"
              : "calc(100vh - 260px)"
          }
          style={{ overflowY: "auto" }}
        >
          <List sx={{ width: "100%", bgcolor: !md && "#F5F5F5" }}>
            {filteredData &&
              filteredData.map((contact) => {
                const isOnline = onlineUsersList?.includes(
                  Number(contact?.receiver_id)
                );
                const lastMessage = moment(contact?.last_message?.updatedAt);

                return (
                  // <motion.div
                  //   initial={{ opacity: 0, y: 40 }} // Start with the element lower and transparent
                  //   animate={{ opacity: 1, y: 0 }} // Animate to fully opaque and original position
                  //   exit={{ opacity: 0, y: 40 }} // Fade out and move down
                  //   transition={{ duration: 0.3 }}
                  // >
                  <ListItem
                    key={contact.id}
                    alignItems="flex-start"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: md
                        ? "transparent"
                        : selectedChatData?.id === contact?.id
                        ? BaseColor.primary
                        : "transparent",
                    }}
                    onClick={(event) => {
                      if (!event.target.closest(".button_component")) {
                        setOpenChat({ open: true, data: contact });
                        const nData = {
                          c_id: contact.conversation_id,
                          s_id: userData.id,
                        };
                        socket.emit(
                          "read_all_messages",
                          nData,
                          (response) => {}
                        );
                        if (
                          activeMenu === "blockList" ||
                          activeMenu === "archiveList"
                        ) {
                          handleOpenParticularchat(
                            contact,
                            1,
                            false,
                            activeMenu,
                            true
                          );
                        } else {
                          handleOpenParticularchat(
                            contact,
                            1,
                            false,
                            "send",
                            true
                          );
                        }
                        setParticularUser(contact);
                        // if (!md) {
                        dispatch(setSelectedChatData(contact));
                        // }
                      }
                    }}
                  >
                    <ListItemAvatar
                      sx={{
                        margin:
                          listType === "personal"
                            ? "20px 15px 15px 0"
                            : "10px 15px 15px 0",
                      }}
                    >
                      {isOnline ? (
                        <StyledBadge
                          overlap="circular"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                        >
                          <Avatar
                            alt={contact.name}
                            sx={{ width: 50, height: 50 }}
                            src={
                              !isEmpty(contact?.photo)
                                ? contact.photo
                                : cTab === "groups"
                                ? Files.Images.groupAvatar
                                : "/img"
                            }
                          />
                        </StyledBadge>
                      ) : (
                        <Avatar
                          alt={contact.name}
                          sx={{ width: 50, height: 50 }}
                          src={
                            !isEmpty(contact?.photo)
                              ? contact.photo
                              : cTab === "groups"
                              ? Files.Images.groupAvatar
                              : "/img"
                          }
                        />
                      )}
                    </ListItemAvatar>

                    <ListItemText
                      primary={
                        <div className={classes.ContactNameContainer}>
                          {cTab === "groups" ? (
                            <Tooltip
                              enterTouchDelay={0}
                              placement="top-start"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontSize: 17,
                                    backgroundColor: BaseColor.white,
                                    color: BaseColor.red,
                                    fontFamily: FontFamily.Medium,
                                    boxShadow: " 0 0 10px 5px rgb(0 0 0 / 10%)",
                                  },
                                },
                              }}
                              title={
                                <Typography
                                  fontSize={14}
                                  component={"span"}
                                  color={"black"}
                                  fontFamily={FontFamily.SemiBold}
                                >
                                  {!isEmpty(contact.username)
                                    ? contact.username
                                    : !isEmpty(contact?.members)
                                    ? contact?.members?.join(", ")
                                    : contact?.conv_name}
                                </Typography>
                              }
                            >
                              <Typography
                                fontSize={md ? "14px" : "20px"}
                                component={"span"}
                                color={
                                  md
                                    ? BaseColor.blackColor
                                    : selectedChatData?.id === contact?.id
                                    ? BaseColor.whiteColor
                                    : BaseColor.blackColor
                                }
                                fontFamily={FontFamily.SemiBold}
                                width={"70%"}
                                sx={{
                                  textWrap: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  userSelect: "none",
                                }}
                              >
                                {!isEmpty(contact.username)
                                  ? contact.username
                                  : !isEmpty(contact?.members)
                                  ? contact?.members?.join(", ")
                                  : contact?.conv_name}
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              fontSize={md ? "14px" : "20px"}
                              component={"span"}
                              color={
                                md
                                  ? BaseColor.blackColor
                                  : selectedChatData?.id === contact?.id
                                  ? BaseColor.whiteColor
                                  : BaseColor.blackColor
                              }
                              fontFamily={FontFamily.SemiBold}
                              width={"70%"}
                              sx={{
                                textWrap: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                userSelect: "none",
                              }}
                            >
                              {!isEmpty(contact.username)
                                ? contact.username
                                : !isEmpty(contact?.members)
                                ? contact?.members?.join(", ")
                                : contact?.conv_name}
                            </Typography>
                          )}

                          {activeMenu === "blockList" ||
                          activeMenu === "archiveList" ? (
                            <Button
                              className="button_component"
                              color="success"
                              style={{
                                fontFamily: FontFamily.Bold,
                                fontSize: "12px",
                              }}
                              size="small"
                              onClick={() => handleUnblockUnarchive(contact)}
                            >
                              {activeMenu === "blockList"
                                ? "Unblock"
                                : "Unarchive"}
                            </Button>
                          ) : (
                            <span
                              style={{ display: "flex", flexWrap: "nowrap" }}
                              className="button_component"
                            >
                              {!_.isEmpty(contact?.last_message) && (
                                <Typography
                                  fontSize={"16px"}
                                  component={"span"}
                                  style={{
                                    textWrap: "nowrap",
                                    userSelect: "none",
                                  }}
                                  color={
                                    md
                                      ? "#616161"
                                      : selectedChatData?.id === contact?.id
                                      ? BaseColor.whiteColor
                                      : "#616161"
                                  }
                                  fontFamily={FontFamily.Regular}
                                >
                                  {dateToFromNowDaily(lastMessage)}
                                </Typography>
                              )}

                              <button
                                aria-describedby={id}
                                className={classes.ContactArrowBtn}
                                onClick={(e) =>
                                  handlePopClick(e, contact.id, contact)
                                }
                                style={{
                                  cursor: !isMobile && !isTablet && "pointer",
                                }}
                              >
                                {anchorEl && popId === contact.id ? (
                                  <FaAngleUp />
                                ) : (
                                  <FaAngleDown />
                                )}
                              </button>
                              <Popover
                                className="button_component"
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handlePopClose}
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "right",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "right",
                                }}
                                PaperProps={{
                                  sx: {
                                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                                  },
                                }}
                              >
                                <Grid
                                  style={{
                                    backgroundColor: BaseColor.white,
                                    padding: "12px 16px",
                                    borderRadius: 10,
                                  }}
                                >
                                  {" "}
                                  <ListItemButton
                                    style={{
                                      marginLeft: -8,
                                      fontSize: 16,
                                      fontFamily: FontFamily.Medium,
                                      color: BaseColor.red,
                                      gap: "5px",
                                    }}
                                    onClick={() => {
                                      handleDeleteConversation(selectedItem);
                                      dispatch(setSelectedChatData({}));
                                      setOpen(false);
                                    }}
                                  >
                                    <img
                                      src={Files.pngIcons.deleteIcon}
                                      draggable={false}
                                      alt="delete-conversation"
                                    />{" "}
                                    Delete Conversation
                                  </ListItemButton>
                                </Grid>
                              </Popover>
                            </span>
                          )}
                        </div>
                      }
                      secondary={
                        <React.Fragment>
                          <div className={classes.ContactClubName}>
                            {listType === "personal" && (
                              <>
                                {" "}
                                {/* <img
                                  alt="club_logo"
                                  src={Files.Images.chatClubPic}
                                /> */}
                                <span
                                  style={{
                                    textWrap: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {/* {contact.club_name} */}
                                </span>
                              </>
                            )}
                          </div>
                          {/* <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "-5px",
                                marginBottom: "5px",
                              }}
                            >
                              <img
                                src={
                                  selectedChatData?.id === contact?.id
                                    ? Files.svgIcons.mapFillIcon
                                    : Files.svgIcons.mapIcon
                                }
                                style={{
                                  width: "15px",
                                  height: "15px",
                                  marginRight: "5px",
                                }}
                              />
                              <Typography
                                fontSize={md ? "12px" : "15px"}
                                style={{
                                  fontFamily: FontFamily.Medium,
                                  color: md
                                    ? BaseColor.grey
                                    : selectedChatData?.id === contact?.id
                                    ? BaseColor.whiteColor
                                    : BaseColor.grey,
                                }}
                              >
                                Hello
                              </Typography>
                            </div> */}

                          <div className={classes.ContactChatContainer}>
                            <span className={classes.ContactChatData}>
                              {contact.status && (
                                <img
                                  alt="Icon"
                                  width={"18px"}
                                  draggable={false}
                                  src={
                                    contact.status === "sent"
                                      ? Files.pngIcons.singleTickIcon
                                      : contact.status === "delivered"
                                      ? Files.pngIcons.doubleTickIcon
                                      : contact.status === "read"
                                      ? Files.pngIcons.greenDoubleTickIcon
                                      : ""
                                  }
                                />
                              )}
                              {contact.chat_type &&
                                contact.chat_type !== "text" && (
                                  <img
                                    alt="Icon"
                                    width={"18px"}
                                    draggable={false}
                                    src={
                                      contact.chat_type === "image"
                                        ? Files.pngIcons.imageIcon
                                        : contact.chat_type === "vedio"
                                        ? Files.pngIcons.vedioIcon
                                        : contact.chat_type === "link"
                                        ? Files.pngIcons.linkIcon
                                        : ""
                                    }
                                  />
                                )}
                              {activeTypingId == contact?.conversation_id ? (
                                <span
                                  style={{
                                    color: md
                                      ? BaseColor.primary
                                      : selectedChatData?.id === contact?.id
                                      ? BaseColor.white
                                      : BaseColor.primary,
                                    fontSize: 14,
                                    fontFamily: FontFamily.Bold,
                                    marginLeft: 5, // Adjust spacing as needed
                                  }}
                                >
                                  is typing...
                                </span>
                              ) : (
                                <span
                                  className={classes.ContactChatText}
                                  style={{
                                    marginLeft: "4px",
                                    display: "flex",
                                    verticalAlign: "center",
                                    userSelect: "none",
                                    width: sm
                                      ? "78%"
                                      : md
                                      ? "85%"
                                      : lg
                                      ? "70%"
                                      : "80%",
                                    color: md
                                      ? "#616161"
                                      : selectedChatData?.id === contact?.id
                                      ? BaseColor.whiteColor
                                      : "#616161",
                                  }}
                                >
                                  {contact.last_message.text === "poll"
                                    ? "Tee Time"
                                    : contact.last_message.text}
                                  {contact.last_message.text === "poll" && (
                                    <PollIcon
                                      style={{
                                        verticalAlign: "center",
                                        alignSelf: "center",
                                        marginLeft: "2px",
                                        fontSize: 18,
                                        color: md
                                          ? "#616161"
                                          : selectedChatData?.id === contact?.id
                                          ? BaseColor.whiteColor
                                          : "#616161",
                                      }}
                                    />
                                  )}
                                </span>
                              )}
                            </span>
                            {Boolean(contact?.unread) && contact.unread > 0 && (
                              <Badge
                                style={{ marginTop: "5px" }}
                                badgeContent={contact.unread}
                                color="success"
                              />
                            )}
                          </div>
                        </React.Fragment>
                      }
                    />
                  </ListItem>
                  // </motion.div>
                );
              })}
          </List>
        </Grid>
      ) : (
        <Grid
          height={"calc(100vh - 320px)"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          {props.mobile ? (
            <>
              {" "}
              <img
                src={Files.Images.emptyChatBg}
                alt="logo"
                draggable={false}
              />
              <Typography className={classes.emptyText}>Empty</Typography>
              <Typography className={classes.emptyText2}>
                You have no chats yet.
              </Typography>
            </>
          ) : (
            <>
              <Typography className={classes.emptyText}>Empty</Typography>
              <Typography className={classes.emptyText2}>
                {`You have no ${
                  listType === "personal" ? "Contact" : "Group"
                } yet.`}
              </Typography>{" "}
            </>
          )}
        </Grid>
      )}
    </div>
  );
});

export default ChatSidebar;
