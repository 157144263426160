import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "./globleStyle.scss";
import _ from "lodash";
import LoginStack from "./Navigation/loginStack";
import HomeStack from "./Navigation/homeStack";
import { initializeApp } from "firebase/app";
import {
  askForPermissionToReceiveNotifications,
  onMessageListener,
} from "./push-notification";
import authActions from "./Redux/reducers/auth/actions";

const firebaseConfig = {
  apiKey: "AIzaSyAOKQiBwn1MveIwjrItsUi0cFlCC4_CcuU",
  authDomain: "golf-goatt-cc03f.firebaseapp.com",
  projectId: "golf-goatt-cc03f",
  storageBucket: "golf-goatt-cc03f.appspot.com",
  messagingSenderId: "311086033613",
  appId: "1:311086033613:web:5649fa64005e6b0ee39784",
  measurementId: "G-MF935SLTTB",
};

function App() {
  const { userData } = useSelector((state) => state.auth);
  const isLogin =
    _.isObject(userData) && !_.isEmpty(userData) && !userData?.signup_pending;

  const uuid = useSelector((state) => state.auth.useruuid);

  initializeApp(firebaseConfig);
  const { setFocus } = authActions;
  const dispatch = useDispatch();

  useEffect(() => {
    const handleFocus = () => dispatch(setFocus(true));
    const handleBlur = () => dispatch(setFocus(false));
    window.addEventListener("focus", handleFocus);
    window.addEventListener("blur", handleBlur);
    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("focus", handleFocus);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  useEffect(() => {
    if (_.isEmpty(uuid)) {
      setTimeout(() => {
        askForPermissionToReceiveNotifications();
      }, 2000);
    }
    onMessageListener();
  }, [uuid]);

  return (
    <BrowserRouter>{isLogin ? <HomeStack /> : <LoginStack />}</BrowserRouter>
  );
}

export default App;
