import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import BaseColor from "../../Config/colors";
import CHeader from "../../Components/Header";
import Files from "../../Config/Files";
import { IoIosArrowRoundBack } from "react-icons/io";
import { FontFamily } from "../../Config/theme";
import { useTheme } from "@emotion/react";
import CModal from "../../Components/CModal";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CInput from "../../Components/CInput";
import CButton from "../../Components/CButton";
import useStyles from "./styles";
import _, { isEmpty } from "lodash";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { toast } from "react-toastify";

import { useSelector } from "react-redux";
import { motion } from "framer-motion";

const Payment = () => {
  // styles and media queries
  const classes = useStyles();
  const theme = useTheme();
  const mobileScreen = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  // navigation
  const navigate = useNavigate();

  // Redux
  const { userData } = useSelector((state) => state.auth);

  const [amount, setAmount] = useState("");
  const [openWithdrawModal, setOpenWithdrawtModal] = useState(false);
  const [openNewBankModal, setOpenNewBankModal] = useState(false);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [holderName, setHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [swiftCode, setSwiftCode] = useState("");
  const [withdrawErrorText, setWithdrawErrorText] = useState("");
  const [bankSelectErrorText, setBankSelectErrorText] = useState("");
  const [holderNameErrorText, setHoldernameErrorText] = useState("");
  const [accountNumberErrorText, setAccountNumberErrorText] = useState("");
  const [swiftCodeErrorText, setSwiftCodeErrorText] = useState("");
  const [checked, setChecked] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [transactionPagination, setTransactionPagination] = useState({});
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [perPage] = useState(10); // You can adjust perPage as needed

  const transactionsListRef = useRef(null);
  const [bankData, setBankData] = useState([]);
  const [loader, setLoader] = useState(false);

  //loader
  const [btnLoader, setBtnLoader] = useState(false);
  const [defaultLoader, setDefaultLoader] = useState("");
  const [amountLoader, setAmountLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [walletLoader, setWalletLoader] = useState(false);

  const [selectedBankId, setSelectedBankId] = useState("");

  useEffect(() => {
    getTransactionsList();
    getWalletBalance();
  }, []);

  // wallet balance api call
  const getWalletBalance = async () => {
    setWalletLoader(true);
    const endPoints = Setting.endpoints.walletBalance;

    try {
      const resp = await getAPIProgressData(endPoints, "POST", {}, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data)) {
          setAmount(`$${resp?.data?.balance}`);
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setWalletLoader(false);
    } catch (error) {
      setWalletLoader(false);
    }
  };

  // transaction list api call
  const getTransactionsList = async (page = 1, loader = false) => {
    if (loader) {
      setPaginationLoader(true);
    } else {
      setPageLoader(true);
    }
    const endPoints = Setting.endpoints.transactionList;
    const data = {
      page: page,
      perPage: perPage,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        setTransactionPagination(resp?.pagination);
        setTransactions((prevTransactions) => {
          // Assuming `page` starts from 1 and `perPage` is set correctly
          if (page === 1) {
            // For the first page, simply replace existing transactions with new data
            return [...resp.data];
          } else {
            // For subsequent pages, append new data to existing transactions
            return [...prevTransactions, ...resp.data];
          }
        });
        setTimeout(() => {
          setPaginationLoader(false);
        }, 1000);
        setPageLoader(false);
        // Update pagination info if needed
        if (resp.pagination) {
          setTransactionPagination(resp.pagination);
        }
      } else {
        setPaginationLoader(false);
        setPageLoader(false);
        toast(resp?.message, {
          type: "error",
        });
      }
    } catch (error) {
      setPaginationLoader(false);
      setPageLoader(false);
      toast(error.message, {
        type: "error",
      });
    }
  };

  // Function to handle reaching bottom of scroll
  const onReachBottom = () => {
    if (transactionsListRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        transactionsListRef.current;
      const scroll = scrollTop + clientHeight + 10;

      if (
        scroll >= scrollHeight &&
        transactionPagination?.isMore &&
        !paginationLoader
      ) {
        const nextPage = pageNo + 1;
        getTransactionsList(nextPage, true);
        setPageNo(nextPage);
      }
    }
  };

  // validation for withdraw processing
  const withdrawValidation = () => {
    let isValid = true;
    if (withdrawAmount === "") {
      setWithdrawErrorText("Withdraw amount is required");
      isValid = false;
    }
    if (Number(withdrawAmount) <= 0) {
      setWithdrawErrorText("Enter a valid amount");
      isValid = false;
    }
    if (!selectedBankId) {
      setBankSelectErrorText("Please select a bank card");
      isValid = false;
    }
    if (isValid) {
      setBankSelectErrorText("");
      setWithdrawErrorText("");
      getWithdrawAmount();
    }
  };

  //withdraw amount from wallet
  const getWithdrawAmount = async () => {
    setAmountLoader(true);
    const endPoints = Setting.endpoints.withdraw;
    const data = {
      amount: withdrawAmount,
      bank_id: selectedBankId,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        setOpenWithdrawtModal(false);
        setWithdrawAmount("");
        setSelectedBankId("");
        setWithdrawErrorText("");
        setBankSelectErrorText("");
        toast(resp?.message, {
          type: "success",
        });
        getTransactionsList();
        getWalletBalance();
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setAmountLoader(false);
    } catch (error) {
      setAmountLoader(false);
    }
  };

  // validation for account
  const accountValidation = () => {
    let isValid = true;
    if (holderName.trim() === "") {
      setHoldernameErrorText("Account holder name is required");
      isValid = false;
    }
    if (accountNumber === "") {
      setAccountNumberErrorText("Bank account number is required");
      isValid = false;
    }
    if (swiftCode === "") {
      setSwiftCodeErrorText("Routing number is required");
      isValid = false;
    }
    if (isValid) {
      setHoldernameErrorText("");
      setAccountNumberErrorText("");
      setSwiftCodeErrorText("");
      getAddBank();
    }
  };

  // add bank
  const getAddBank = async () => {
    setBtnLoader(true);
    const endPoints = Setting.endpoints.createBankAccount;
    const data = {
      account_holder_name: holderName,
      account_number: accountNumber,
      swift_code: swiftCode,
      set_default_bank: checked ? 1 : 0,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        setOpenWithdrawtModal(true);
        setLoader(true);
        getBankList();
        setBtnLoader(false);
        setOpenNewBankModal(false);
        setWithdrawAmount("");
        setHolderName("");
        setAccountNumber("");
        setSwiftCode("");
        setWithdrawErrorText("");
        setBankSelectErrorText("");
        setHoldernameErrorText("");
        setAccountNumberErrorText("");
        setSwiftCodeErrorText("");
        setChecked(false);
        toast(resp?.message, {
          type: "success",
        });
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setBtnLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setBtnLoader(false);
    }
  };

  // get bank list api call
  const getBankList = async () => {
    const endPoints = Setting.endpoints.bankList;

    try {
      const resp = await getAPIProgressData(endPoints, "POST", {}, true);
      if (resp?.success) {
        if (!_.isEmpty(resp?.data) && _.isArray(resp?.data)) {
          setBankData(resp?.data);
          setSelectedBankId(
            resp?.data?.find((card) => card.is_default_bank)?.bank_id
          );
        }
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setLoader(false);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setLoader(false);
    }
  };

  // get bank card data
  const getDefaultBankCard = async (card) => {
    setDefaultLoader(card.bank_id);
    const endPoints = Setting.endpoints.defaultBank;
    const data = {
      bank_id: card?.bank_id,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", data, true);
      if (resp?.success) {
        toast(resp?.message, {
          type: "success",
        });
        getBankList();
      } else {
        toast(resp?.message, {
          type: "error",
        });
      }
      setDefaultLoader(null);
    } catch (error) {
      toast(error.message, {
        type: "error",
      });
      setDefaultLoader(null);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <Grid container style={{ backgroundColor: BaseColor.white }}>
      <CHeader title={"Profile"} />
      <Grid item xs={0.5} sm={0.5} md={1} lg={1} />
      {mobileScreen ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              background: `url(${Files.Images.mobilePaymentbg})`,
              padding: "20px",
              height: "100vh",
              width: "100%",
              backgroundSize: "cover",
            }}
          >
            <IoIosArrowRoundBack
              onClick={() => {
                navigate("/");
              }}
              style={{
                fontSize: 40,
                color: BaseColor.whiteColor,
                cursor: "pointer",
                position: "fixed",
                left: "14px",
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "0.5vh",
              }}
            >
              <Typography
                style={{
                  color: BaseColor.whiteColor,
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: 24,
                  width: "100%",
                }}
              >
                My wallet
              </Typography>
            </div>

            <div
              style={{
                textAlign: "center",
                marginBottom: "80vh",
              }}
            >
              <Typography
                style={{
                  color: BaseColor.whiteColor,
                  fontSize: 14,
                }}
              >
                Current Balance
              </Typography>
              <Typography
                style={{
                  color: BaseColor.whiteColor,
                  fontSize: isTablet ? 28 : 36,
                  fontFamily: FontFamily.Bold,
                }}
              >
                {walletLoader ? (
                  <CircularProgress size={20} color={"secondary"} />
                ) : (
                  amount
                )}
              </Typography>
            </div>
          </div>
          <Grid
            mt={-3}
            style={{
              background: "white",
              borderRadius: "20px 20px 0px 0px ",
              width: "100%",
              padding: "20px",
              marginTop: "-78vh",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: "50px",
                background: BaseColor.primary,
                marginBottom: "2vh",
              }}
            >
              <Button
                onClick={() => {
                  setOpenWithdrawtModal(true);
                  setLoader(true);
                  getBankList();
                }}
                variant="text"
                style={{
                  borderRadius: "50px",
                  color: BaseColor.whiteColor,
                  height: isTablet ? "50%" : null,
                  padding: isTablet ? "10px 30px" : "0px 30px",
                  fontSize: isTablet ? "18px" : "",
                }}
              >
                <img
                  src={Files.pngIcons.withdraw}
                  alt="withdraw"
                  style={{ marginRight: "14px" }}
                  draggable={false}
                />
                Withdraw
              </Button>
            </div>
            <Divider />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "2vh",
              }}
            >
              <Typography
                style={{
                  color: BaseColor.primary,
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: 18,
                  marginBottom: "3vh",
                }}
              >
                Latest Transaction
              </Typography>
              {/* <Typography
                style={{
                  fontFamily: FontFamily.Regular,
                  color: BaseColor.green,
                  cursor: "pointer",
                  fontSize: "13px",
                }}
              >
                See All
              </Typography> */}
            </div>
            <div
              ref={transactionsListRef}
              onScroll={onReachBottom}
              style={{ overflowY: "scroll", height: "40vh" }}
              className={classes.scrollBar}
            >
              {pageLoader ? (
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "calc(30vh)",
                  }}
                >
                  <CircularProgress size={30} color="primary" />
                </div>
              ) : (
                <>
                  {_.isEmpty(transactions) ? (
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        height: "calc(30vh)",
                      }}
                    >
                      <Typography
                        style={{
                          paddingTop: "15px",
                          fontFamily: FontFamily.Medium,
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        No transactions
                      </Typography>
                    </div>
                  ) : (
                    <>
                      {transactions?.map((item, index) => {
                        const formatTimestamp = (timestamp) => {
                          const dateObj = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
                          const now = new Date(); // Current date and time
                          const today = new Date(
                            now.getFullYear(),
                            now.getMonth(),
                            now.getDate()
                          ); // Midnight of today
                          const yesterday = new Date(today);
                          yesterday.setDate(today.getDate() - 1); // Midnight of yesterday

                          if (isSameDay(dateObj, today)) {
                            return `Today - ${formatAMPM(dateObj)}`;
                          } else if (isSameDay(dateObj, yesterday)) {
                            return `Yesterday - ${formatAMPM(dateObj)}`;
                          } else {
                            return `${padZero(dateObj.getDate())}-${padZero(
                              dateObj.getMonth() + 1
                            )}-${dateObj.getFullYear()} ${formatAMPM(dateObj)}`;
                          }
                        };

                        const isSameDay = (date1, date2) => {
                          return (
                            date1.getDate() === date2.getDate() &&
                            date1.getMonth() === date2.getMonth() &&
                            date1.getFullYear() === date2.getFullYear()
                          );
                        };

                        const formatAMPM = (dateObj) => {
                          return dateObj.toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          });
                        };

                        const padZero = (num) => {
                          return num < 10 ? `0${num}` : num;
                        };
                        return (
                          <div key={item.id}>
                            <Grid
                              container
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginBottom: "2vh",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  justifyContent: "space-between",
                                }}
                              >
                                {/* Club name */}
                                <Typography
                                  style={{
                                    fontSize: 14,
                                    fontFamily: FontFamily.SemiBold,
                                    color: "#212121",
                                    whiteSpace: "nowrap",
                                    width: "78%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {item.club_name}
                                </Typography>
                                {/* Amount */}

                                <Typography
                                  style={{
                                    color:
                                      item.sender_user_id == userData?.id
                                        ? BaseColor.green
                                        : BaseColor.red,
                                    fontSize: 14,
                                    fontFamily: FontFamily.SemiBold,
                                    textAlign: "center",
                                  }}
                                >
                                  {item.sender_user_id == userData?.id
                                    ? "-"
                                    : "+"}
                                  {`$${item.amount}`}
                                </Typography>
                              </div>

                              {/* Customer name */}
                              <Typography
                                style={{
                                  fontSize: 12,
                                  fontFamily: FontFamily.Medium,
                                  color: BaseColor.grey,
                                  width: "100%",
                                  marginTop: "6px",
                                }}
                              >
                                {item.sender_user_id == userData?.id
                                  ? "You"
                                  : item.customer_name}
                              </Typography>

                              {/* Date */}
                              <Typography
                                style={{
                                  fontSize: 12,
                                  fontFamily: FontFamily.Medium,
                                  color: BaseColor.grey,
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  marginTop: "6px",
                                }}
                              >
                                {formatTimestamp(item?.created_at)}
                              </Typography>
                            </Grid>
                          </div>
                        );
                      })}
                      {paginationLoader && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: 20,
                          }}
                        >
                          <CircularProgress size={20} color="primary" />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Grid>
        </>
      ) : (
        <Grid xs={12} sm={11} md={10}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: `url(${Files.Images.paymentbg})`,
              padding: "20px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <IoIosArrowRoundBack
                onClick={() => {
                  navigate("/");
                }}
                style={{
                  fontSize: 40,
                  color: BaseColor.whiteColor,
                  cursor: "pointer",
                }}
              />
              <Typography
                style={{
                  color: BaseColor.whiteColor,
                  fontFamily: FontFamily.GilbertQualifiDemo,
                  fontSize: isTablet ? 20 : 24,
                  marginLeft: "10px",
                }}
              >
                My Wallet
              </Typography>
            </div>

            <div style={{ textAlign: "center" }}>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.4 }}
                style={{
                  color: BaseColor.whiteColor,
                  fontSize: isTablet ? 28 : 36,
                  fontFamily: FontFamily.Bold,
                }}
              >
                {walletLoader ? (
                  <CircularProgress size={20} color={"secondary"} />
                ) : (
                  amount
                )}
              </motion.p>
              <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.4 }}
                style={{
                  color: BaseColor.whiteColor,
                  fontSize: isTablet && 13,
                }}
              >
                Current Balance
              </motion.p>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                borderRadius: "50px",
                background: "#FFFFFF1A",
              }}
            >
              <Button
                onClick={() => {
                  setOpenWithdrawtModal(true);
                  setLoader(true);
                  getBankList();
                }}
                variant="text"
                style={{
                  borderRadius: "50px",
                  color: BaseColor.whiteColor,
                  height: isTablet ? "50%" : null,
                  padding: isTablet ? "10px 30px" : "0px 30px",
                  fontSize: isTablet ? "18px" : "",
                }}
              >
                <img
                  src={Files.pngIcons.withdraw}
                  alt="withdraw"
                  style={{ marginRight: "14px" }}
                  draggable={false}
                />
                Withdraw
              </Button>
            </div>
          </div>

          <Grid mt={3}>
            <Typography
              style={{
                color: BaseColor.primary,
                fontFamily: FontFamily.GilbertQualifiDemo,
                fontSize: isTablet ? 20 : 24,
                marginBottom: "4vh",
              }}
            >
              Latest Transaction
            </Typography>
            {!_.isEmpty(transactions) && (
              <Grid
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "3vh 0vw",
                }}
              >
                <Typography
                  style={{
                    color: BaseColor.primary,
                    fontFamily: FontFamily.GilbertQualifiDemo,
                    fontSize: isTablet ? 18 : 20,
                    width: "50%",
                    textAlign: "start",
                    overflowWrap: "break-word",
                  }}
                >
                  Club name
                </Typography>

                <Typography
                  style={{
                    color: BaseColor.primary,
                    fontFamily: FontFamily.GilbertQualifiDemo,
                    fontSize: isTablet ? 18 : 20,
                    width: "15%",
                    textAlign: "center",
                  }}
                >
                  Date Time
                </Typography>
                <Typography
                  style={{
                    color: BaseColor.primary,
                    fontFamily: FontFamily.GilbertQualifiDemo,
                    fontSize: isTablet ? 18 : 20,
                    width: "15%",
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Customer Name
                </Typography>
                <Typography
                  style={{
                    color: BaseColor.primary,
                    fontFamily: FontFamily.GilbertQualifiDemo,
                    fontSize: isTablet ? 18 : 20,
                    width: "15%",
                    textAlign: "center",
                  }}
                >
                  Amount
                </Typography>
              </Grid>
            )}

            <div
              ref={transactionsListRef}
              onScroll={onReachBottom}
              style={{ height: "60vh", overflowY: "scroll" }}
              className={classes.scrollBar}
            >
              {pageLoader ? (
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    height: "calc(50vh)",
                  }}
                >
                  <CircularProgress size={40} color="primary" />
                </div>
              ) : (
                <>
                  {_.isEmpty(transactions) ? (
                    <div
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                        height: "calc(50vh)",
                      }}
                    >
                      <Typography
                        style={{
                          paddingTop: "15px",
                          fontFamily: FontFamily.Medium,
                          fontSize: "20px",
                          textAlign: "center",
                        }}
                      >
                        No transactions
                      </Typography>
                    </div>
                  ) : (
                    <>
                      {transactions?.map((item, index) => {
                        const formatTimestamp = (timestamp) => {
                          const dateObj = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
                          const now = new Date(); // Current date and time
                          const today = new Date(
                            now.getFullYear(),
                            now.getMonth(),
                            now.getDate()
                          ); // Midnight of today
                          const yesterday = new Date(today);
                          yesterday.setDate(today.getDate() - 1); // Midnight of yesterday

                          if (isSameDay(dateObj, today)) {
                            return `Today - ${formatAMPM(dateObj)}`;
                          } else if (isSameDay(dateObj, yesterday)) {
                            return `Yesterday - ${formatAMPM(dateObj)}`;
                          } else {
                            return `${padZero(dateObj.getDate())}-${padZero(
                              dateObj.getMonth() + 1
                            )}-${dateObj.getFullYear()} ${formatAMPM(dateObj)}`;
                          }
                        };

                        const isSameDay = (date1, date2) => {
                          return (
                            date1.getDate() === date2.getDate() &&
                            date1.getMonth() === date2.getMonth() &&
                            date1.getFullYear() === date2.getFullYear()
                          );
                        };

                        const formatAMPM = (dateObj) => {
                          return dateObj.toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          });
                        };

                        const padZero = (num) => {
                          return num < 10 ? `0${num}` : num;
                        };
                        return (
                          <motion.div
                            key={item.id}
                            initial={{ opacity: 0, y: 50 }} // Start with the element lower and transparent
                            animate={{ opacity: 1, y: 0 }} // Animate to fully opaque and original position
                            exit={{ opacity: 0, y: 50 }} // Fade out and move down
                            transition={{ duration: 0.4 }} // Duration in seconds
                          >
                            <Grid
                              container
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                margin: "2vh 0vw",
                              }}
                            >
                              {/* Club name */}
                              <Typography
                                style={{
                                  fontSize: 16,
                                  fontFamily: FontFamily.SemiBold,
                                  color: "#212121",
                                  width: "50%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {item.club_name}
                              </Typography>

                              {/* Date */}
                              <Typography
                                style={{
                                  fontSize: 14,
                                  fontFamily: FontFamily.Medium,
                                  color: BaseColor.grey,
                                  textAlign: "center",
                                  width: "15%",
                                }}
                              >
                                {formatTimestamp(item?.created_at)}
                              </Typography>

                              {/* Customer name */}
                              <Typography
                                style={{
                                  fontSize: 14,
                                  fontFamily: FontFamily.Medium,
                                  color: BaseColor.grey,
                                  width: "15%",
                                  textAlign: "center",
                                }}
                              >
                                {item.sender_user_id == userData?.id
                                  ? "You"
                                  : item.customer_name}
                              </Typography>

                              {/* Amount */}

                              <Typography
                                style={{
                                  color:
                                    item.sender_user_id == userData?.id
                                      ? BaseColor.red
                                      : BaseColor.green,
                                  fontSize: 14,
                                  fontFamily: FontFamily.SemiBold,
                                  width: "15%",
                                  textAlign: "center",
                                }}
                              >
                                {item.sender_user_id == userData?.id
                                  ? "-"
                                  : "+"}
                                {`$${item.amount}`}
                              </Typography>
                            </Grid>

                            {index !== transactions.length && (
                              <Divider style={{ backgroundColor: "#D8E0E3" }} />
                            )}
                          </motion.div>
                        );
                      })}
                      {paginationLoader && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop: 20,
                          }}
                        >
                          <CircularProgress size={30} color="primary" />
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </Grid>
        </Grid>
      )}

      {/* WithDraw Modal */}

      <CModal
        visible={openWithdrawModal}
        onClose={() => {
          setOpenWithdrawtModal(false);
          setWithdrawAmount("");
          setWithdrawErrorText("");
          setBankSelectErrorText("");
        }}
      >
        <Grid
          style={{
            margin: 10,
            padding: 30,
            position: "relative",
            width: mobileScreen ? "90vw" : "450px",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CloseIcon
              onClick={() => {
                setOpenWithdrawtModal(false);
                setWithdrawAmount("");
                setBankSelectErrorText("");
                setWithdrawErrorText("");
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            <Typography
              style={{
                fontSize: "24px",
                fontFamily: "GilbertQualifiDemo",
                textAlign: "center",
                color: "black",
                flex: 1,
              }}
            >
              Withdraw
            </Typography>
          </div>

          <div style={{ textAlign: "center", marginTop: "25px" }}>
            <Typography
              style={{ fontSize: 14, fontFamily: FontFamily.Regular }}
            >
              Current Balance
            </Typography>
            <Typography
              style={{
                fontSize: "26px",
                fontFamily: FontFamily.Bold,
                color: BaseColor.blackk,
              }}
            >
              {walletLoader ? (
                <CircularProgress size={20} color={"secondary"} />
              ) : (
                amount
              )}
            </Typography>
          </div>
          <CInput
            required
            label={"Withdraw"}
            placeholder="Withdraw"
            fullWidth
            onChange={(val) => {
              const numericValue = val.replace(/[^\d.]/g, "");

              if (numericValue.length > 5) {
                return;
              }
              setWithdrawAmount(numericValue);
              setWithdrawErrorText("");
            }}
            value={`$${withdrawAmount}`}
            errorMsg={withdrawErrorText}
            error={!isEmpty(withdrawErrorText)}
          />

          <div>
            <Typography
              style={{
                fontSize: 16,
                fontFamily: FontFamily.Medium,
                color: BaseColor.blackColor,
                margin: "20px 0px 8px 0px",
              }}
            >
              Select Card
              <span style={{ color: "red", marginLeft: "2px" }}>*</span>
            </Typography>

            {loader ? (
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  marginTop: 15,
                }}
              >
                <CircularProgress size={20} color="primary" />
              </div>
            ) : (
              <>
                {!_.isEmpty(bankData) ? (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedBankId}
                    sx={{ width: "100%", gap: "10px" }}
                    name="payment_option"
                    onChange={(e) => {
                      setSelectedBankId(e.target.value);
                      setBankSelectErrorText("");
                    }}
                  >
                    {bankData?.map((card) => (
                      <FormControlLabel
                        key={card?.bank_id}
                        value={card?.bank_id}
                        sx={{
                          width: "100%",
                          ".MuiTypography-root": {
                            width: "90%",
                            border: "1px solid #7B8B97",
                            padding: "7px 15px",
                            borderRadius: "20px",
                            fontSize: "14px",
                          },
                          display: "flex",
                        }}
                        control={<Radio />}
                        label={
                          <Typography
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {`**** **** ${card.account_number}`}
                            {bankData.length > 1 && (
                              <span
                                style={{
                                  color: card.is_default_bank
                                    ? BaseColor.primary
                                    : BaseColor.grey,
                                  fontSize: "12px",
                                  cursor: "pointer",
                                  marginTop:
                                    defaultLoader === card.bank_id && 2,
                                }}
                                onClick={() => getDefaultBankCard(card)}
                              >
                                {defaultLoader === card.bank_id ? (
                                  <CircularProgress size={10} color="primary" />
                                ) : (
                                  <>
                                    {card?.is_default_bank
                                      ? "Default"
                                      : "Set as default"}
                                  </>
                                )}
                              </span>
                            )}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                ) : (
                  <Typography
                    style={{
                      paddingTop: "15px",
                      fontFamily: FontFamily.Medium,
                      fontSize: "14px",
                      textAlign: "center",
                    }}
                  >
                    No bank data available
                  </Typography>
                )}
              </>
            )}
            {bankSelectErrorText && (
              <div className={classes.errorMsgContainer}>
                <span className={classes.errorMsgText}>
                  {bankSelectErrorText}
                </span>
              </div>
            )}
            <CButton
              style={{
                marginTop: 20,
              }}
              variant="outlined"
              fullWidth
              onClick={() => {
                setOpenWithdrawtModal(false);
                if (!sm) {
                  setOpenNewBankModal(true);
                } else {
                  navigate("/add-bank");
                }
              }}
            >
              + Add New Bank
            </CButton>
            <CButton
              style={{
                marginTop: "15px",
              }}
              fullWidth
              onClick={() => {
                withdrawValidation();
              }}
              loading={amountLoader}
              disabled={isEmpty(bankData)}
            >
              Proceed to Withdraw
            </CButton>
          </div>
        </Grid>
      </CModal>

      {/* End */}

      {/* Add New Bank Modal */}

      <CModal
        visible={openNewBankModal}
        onClose={() => {
          setOpenNewBankModal(false);
          setWithdrawAmount("");
          setHolderName("");
          setAccountNumber("");
          setSwiftCode("");
          setWithdrawErrorText("");
          setBankSelectErrorText("");
          setHoldernameErrorText("");
          setAccountNumberErrorText("");
          setSwiftCodeErrorText("");
        }}
      >
        <Grid
          style={{
            margin: 10,
            padding: 30,
            position: "relative",
            width: mobileScreen ? "80vw" : "450px",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <CloseIcon
              onClick={() => {
                setOpenNewBankModal(false);
                setWithdrawAmount("");
                setHolderName("");
                setAccountNumber("");
                setSwiftCode("");
                setWithdrawErrorText("");
                setBankSelectErrorText("");
                setHoldernameErrorText("");
                setAccountNumberErrorText("");
                setSwiftCodeErrorText("");
              }}
              style={{
                position: "absolute",
                top: 10,
                right: 15,
                fontSize: 30,
                cursor: "pointer",
                color: BaseColor.primary,
                zIndex: 1,
              }}
            />
            <Typography
              style={{
                fontSize: "24px",
                fontFamily: "GilbertQualifiDemo",
                textAlign: "center",
                color: "black",
                flex: 1,
                marginBottom: "2vh",
              }}
            >
              Add New Bank
            </Typography>
          </div>

          <CInput
            label={
              <Typography
                style={{
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.blackColor,
                  fontSize: 16,
                }}
              >
                Account Holder Name
                <span style={{ color: "red", marginLeft: "2px" }}>*</span>
              </Typography>
            }
            placeholder="Account Holder Name"
            fullWidth
            onChange={(val) => {
              setHolderName(val);
              setHoldernameErrorText("");
            }}
            value={holderName}
            errorMsg={holderNameErrorText}
            error={!isEmpty(holderNameErrorText)}
          />
          <CInput
            label={
              <Typography
                style={{
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.blackColor,
                  fontSize: 16,
                }}
              >
                Bank Account Number
                <span style={{ color: "red", marginLeft: "2px" }}>*</span>
              </Typography>
            }
            placeholder="Bank Account Number"
            fullWidth
            onChange={(val) => {
              if (/^\d*$/.test(val) && val.length < 13) {
                setAccountNumber(val);
                setAccountNumberErrorText("");
              }
            }}
            value={accountNumber}
            errorMsg={accountNumberErrorText}
            error={!isEmpty(accountNumberErrorText)}
          />
          <CInput
            label={
              <Typography
                style={{
                  fontFamily: FontFamily.Medium,
                  color: BaseColor.blackColor,
                  fontSize: 16,
                }}
              >
                Routing Number
                <span style={{ color: "red", marginLeft: "2px" }}>*</span>
              </Typography>
            }
            placeholder="Routing Number"
            fullWidth
            onChange={(val) => {
              if (val.length < 12) {
                setSwiftCode(val);
                setSwiftCodeErrorText("");
              }
            }}
            value={swiftCode}
            errorMsg={swiftCodeErrorText}
            error={!isEmpty(swiftCodeErrorText)}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1.5vh",
              alignItems: "center",
            }}
          >
            <Checkbox
              style={{ marginRight: 5 }}
              icon={<span className={classes.icon} />}
              checked={checked}
              onChange={handleChange}
              checkedIcon={<span className={classes.checkedIcon} />}
            />
            <label
              for={"default"}
              style={{
                fontFamily: FontFamily.Medium,
                color: BaseColor.grey,
                cursor: "pointer",
                fontSize: 14,
                marginLeft: "5px",
              }}
            >
              Set as default
            </label>
          </div>

          <CButton
            btnStyle={{ marginTop: "8vh" }}
            onClick={() => {
              accountValidation();
            }}
            loading={btnLoader}
          >
            Proceed
          </CButton>
        </Grid>
      </CModal>

      {/* End */}
    </Grid>
  );
};

export default Payment;
